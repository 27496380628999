import { Specific } from "../../base-specific";

export interface IActionParamDef {
    pattern: string;
    label: string;
    tooltip: string;
}
export interface IActionParams {
    input: IActionParamDef[];
    output: IActionParamDef[];
}

export interface IAction extends Specific {
    actionMethod: string;
    actionConfig: object;
    actionParmDef: IActionParams|null;
}

export class Action extends Specific implements IAction  {
    readonly _proto?: string = 'Action';
    readonly btmlPart: number = 4;

    actionMethod: string = null;
    actionConfig: object = null;
    actionParmDef = null;

    constructor(data: IAction) {
        super(data);

        this.actionMethod = data.actionMethod;
        this.actionConfig = data.actionConfig;
        this.actionParmDef = data.actionParmDef;

        // Implement update logic here
    }
}
