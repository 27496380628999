import { Injectable } from '@angular/core';

import { Layer0Service } from './api/services/layer-0.service';
import { Layer1Service } from './api/services/layer-1.service';
import { Layer2Service } from './api/services/layer-2.service';
import { Layer3Service } from './api/services/layer-3.service';
import { Layer4Service } from './api/services/layer-4.service';
import { Layer5Service } from './api/services/layer-5.service';
import { Layer6Service } from './api/services/layer-6.service';
import { Layer7Service } from './api/services/layer-7.service';
import { Layer8Service } from './api/services/layer-8.service';
import { UsermanagerService } from './lnapi/services';
import { ApiService as LnApiService } from './lnapi/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    // Cluster Services
    public agram: Layer7Service,
    public bitcoin: Layer1Service,
    public find: Layer8Service,
    public ln: Layer2Service,
    public restrict: Layer3Service,
    public ipfs: Layer4Service,
    public verify: Layer5Service,
    public jwt: Layer6Service,
    public btml: Layer0Service,
    // LN Services
    public lnapi: LnApiService,
    public lnDefault: LnApiService,
    public lnUserManager: UsermanagerService,
  ) { }

}
