import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import { Plugins } from '@capacitor/core';
import { Balance } from 'src/app/services/api/models';
import { environment } from 'src/environments/environment';
import { UserStorageModels } from 'src/app/services/user-storage-models';
import BaseModal from '../base-modal';
import { PaymentTypes, TokenTypes } from '../../misc-module/components/blockchain-wallets/blockchain-wallets.component';
import { firstValueFrom } from 'rxjs';
const { Share } = Plugins;

export interface IFormData {
  bitCuantity: number;
  btml: string;
  description: string;
  moneyOptions: { tokenType: TokenTypes, payType: PaymentTypes };
  wallet: UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets;
}

@Component({
  selector: 'app-receive-payment-modal',
  templateUrl: './receive-payment-modal.component.html',
  styleUrls: ['./receive-payment-modal.component.scss'],
})
export class ReceivePaymentModalComponent extends BaseModal<IFormData> implements OnInit, OnDestroy {
  protected formIdentifier: string = 'receive';

  @Input() set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, autoSubmit?: boolean, [other: string]: any }) {
    console.log(value);
    if (!value.formData && !value.bitpass) { // Params from other form
      this.moneyOptions = value.moneyOptions;
      this.dataInfo = { tokenType: value.moneyOptions.tokenType, payType: value.moneyOptions.payType, wallet: value.wallet };
      this.bitCuantity = value.bitCuantity;
    } else if (value.bitpass) { // Params from product page
      // this.bitpass = value.bitpass;
      this.noDataInfo = true;
    } else if (value.formData) { // Params from deferred modal
      this.moneyOptions = value.formData.moneyOptions;
      this.dataInfo = { tokenType: value.formData.moneyOptions.tokenType, payType: value.formData.moneyOptions.payType, wallet: value.formData.wallet };
      this.bitCuantity = value.formData.bitCuantity;
      this.description = value.formData.description;
    }
  }
  dataInfo: { tokenType: TokenTypes, payType: PaymentTypes, wallet: UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets }
  noDataInfo = true;
  page = 1;
  bitCuantity: number;
  invalidCuantity = false;
  decimals: number;
  placeholder = '0';
  description: string;
  paymentStarted: boolean = false;
  paymentReceived: boolean = false;
  timer: any;
  qrData = '';
  addressURL = '';
  loadingSecondPage: boolean = false;
  wallet: any;
  moneyOptions: { tokenType: TokenTypes, payType: PaymentTypes };

  bitpass: Bitpass<any>;

  constructor(public core: CoreProvider) {
    super(core)
  }

  protected onCommit(data: IFormData): Promise<any> {
    return new Promise(async (ok, ko) => {
      try {
        this.loadingSecondPage = true;

        if (this.dataInfo.payType=='lightning') {
          const res = await firstValueFrom(this.core.api.lnDefault.apiPaymentsCreateApiV1PaymentsPost(
            this.formDataStore.wallet.chain,
            (this.formDataStore.wallet as UserStorageModels.LightningWallets).credentials.adminkey,
            {body: {
              amount: this.formDataStore.bitCuantity * Math.pow(10, 8), // BTC to sats
              memo: this.formDataStore.description,
              out: false,
            }}
          )) as { "payment_hash": string; "payment_request": string; "checking_id": string; "lnurl_response": null; };
          this.qrData = res.payment_request;
          this.page = 2;

          this.timer = setInterval(async () => {
            await this.getBalanceChain(this.wallet.chain, res.payment_hash);
            if (this.paymentReceived) {
              this.page = 3;
              clearInterval(this.timer);
            }
          }, 1000);
        } else {
          this.core.api.bitcoin.segwitAddress({
            chain: this.formDataStore.wallet.chain,
            type: 'segwit',
            body: {
              ypub: (this.formDataStore.wallet as UserStorageModels.BlockchainWallets).pubkey
            },
          }).subscribe((res) => {
            if (res) {
              this.generateBitcoinAddressURL(res.address);
              if (this.bitCuantity) {
                this.qrData = res.address + '?amount=' + this.formDataStore.bitCuantity;
              } else this.qrData = res.address;

              this.page = 2;

              this.wallet.received.push({
                address: res.address,
                label: this.description,
                date: '' + Date.now()
              });
              this.core.auth.saveUserStorage();
              ok(res);

              setTimeout(() => {
                this.timer = setInterval(async () => {
                  await this.getBalanceChain(this.wallet.chain);
                  if (this.paymentStarted || this.paymentReceived) {
                    this.page = 3;
                  }
                  if (this.paymentReceived) {
                    clearInterval(this.timer);
                  }
                }, 10000)
              }, 15000);
            } else {
              console.error('Payment Error, no response');
            }
          });
        }
      } catch (error) {
        console.error(error);
        ko(error);
      }
    })
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  onSubmit() {
    this.readFormToStore();
    this.onCommit(this.formDataStore);
  }


  async getBalanceChain(chain: string, lnHash?: string) {
    try {
      if (lnHash) {
        const res = await firstValueFrom(this.core.api.lnDefault.apiPaymentApiV1PaymentsPaymentHashGet(
          chain,
          (this.formDataStore.wallet as UserStorageModels.LightningWallets).credentials.adminkey,
          {payment_hash: lnHash})
        );
        this.paymentReceived = res.paid;
      } else {
        const res = await firstValueFrom(this.core.api.find.balanceChain({
          chain: chain,
          address: this.qrData.split('?')[0],
        }));
        if (res) {
          this.paymentReceived = !res.unconfirmed;
          this.paymentStarted = !!res.unconfirmed;
          console.log(res, this.paymentReceived, this.paymentStarted);
        }
      }

    } catch (err) {
      console.error('Error fetching transation balance', err);
    }
  }

  public async successToast() {
    await (
      await this.core.toastCtrl.create({
        message: 'Texto copiado en el portapapeles',
        duration: 2000,
        color: 'success',
      })
    ).present();
  }

  changeCuantity(cuantity) {
    this.bitCuantity = cuantity;
  }

  share() {
    Share.share({
      title: 'Compartir QR',
      text: this.qrData,
    });
  }

  navigateToWallet() {
    clearInterval(this.timer);
    this.core.modalCtrl.dismiss();
    this.core.navCtrl.navigateRoot('/wallet');
  }

  generateBitcoinAddressURL(address: string) {
    var chain = this.bitpass.__data[0].val.specific.specific.specific.specific.specific.chain;
    this.addressURL = `${this.core.relayEndpoint}electrs/${chain}/address/${address}`;
  }

  removeInterval() {
    clearInterval(this.timer)
  }

  setBitpass(bp: Bitpass<any>) {
    this.bitpass = bp;
  }

  setDecimals(dec: number) {
    this.decimals = dec;
  }

  setWallet(wallet) {
    this.wallet = wallet;
  }

  setMoneyOptions(options) {
    this.moneyOptions = options;
  }

  deferredModal = false;
  deferModal() {
    this.deferredModal = true;
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bitCuantity: 0,
      btml: '',
      description: '',
      moneyOptions: { tokenType: '' as TokenTypes, payType: '' as PaymentTypes },
      wallet: {} as UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets,
    };
    dataStore.btml = this.bitpass.__selfBtml;
    dataStore.description = this.description;
    dataStore.moneyOptions = this.moneyOptions;
    dataStore.bitCuantity = this.bitCuantity;
    dataStore.wallet = this.wallet;
    this.formDataStore = dataStore;
  }
}
