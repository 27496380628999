import { Injectable, EventEmitter } from '@angular/core';
import { CoreProvider } from './core';
import { environment } from 'src/environments/environment';
import * as crypto from 'crypto';
import { Base64 } from 'js-base64';
import {
  Bitpass,
  BitpassIndexConceptCertificateOwnership,
  BitpassResourceConceptCertificateOwnership,
  BitpassResourceIdentity,
  GenericBitpass,
  IBitpass,
} from '../lib/bitpass-types/models/bitpass';
import { BitpassData } from '../lib/bitpass-types/models/bitpass-data';
import { ParentSpecific, Specific } from '../lib/bitpass-types/models/specific/base-specific';
import { createTaskFromTpl } from '../pages/main-module/pages/product/task-form/task_bitpass_tpl';
import { firstValueFrom } from 'rxjs';
import { Product } from '../lib/bitpass-types/models/specific/resource/product/product';
import { Resource } from '../lib/bitpass-types/models/specific/resource/resource';

interface IConstructor<T> {
  new (...args: any[]): T;

  // Or enforce default constructor
  // new (): T;
}

@Injectable({ providedIn: 'root' })
export class BitpassServiceProvider {
  public get baseBitpassProduct() {
    const currentAppBtml = this.core.personalization.loadedAppBtml ?? '9000000000';
    if (this.core.auth.user) {
      return this.core.auth.userStorage.apps.list?.[this.core.auth.userStorage.apps.appUsed]?.appBtml ?? currentAppBtml;
    }
    return currentAppBtml;
  }
  public static readonly btmlCoinBitPasses = [
    'app://bp/1111100001',
    'app://bp/1111100002',
    'app://bp/1111100003',
    'app://bp/1111100004',
  ];


  visitedBP = [];
  core: CoreProvider = null;

  dataMemberList: any = null;
  dataMembers: any = null;
  dataMembersEmmiter = new EventEmitter<any>();
  dataProducts: any = null;
  dataProductsEmmiter = new EventEmitter<any>();

  get members() {
    return this.dataMemberList && this.dataMemberList.Data
      ? this.dataMemberList.Data
      : this.dataMemberList;
  }

  constructor() {}

  initCore(core: CoreProvider) {
    this.core = core;
  }

  fetchBitpass<S extends ParentSpecific<S> | Specific>(
    btml,
    sCtor: IConstructor<Bitpass<S>>,
    preventExtendedValidation = false,
  ) {
    return new Promise<{ product: IBitpass<S>; record: any }>((cbOk, cbKo) => {
      this.core.data.requests.keyLastTen(btml).subscribe({next: (res: any) => {
        let winner = null;
        res.records.forEach((rec) => {
          if (rec.timestamp <= res.timestamp) {
            if (!winner) winner = rec;
            if (winner.timestamp < rec.timestamp) {
              winner = rec;
            }
          }
        });

        if (winner) {
          console.debug(`BTML[${btml}] resolved CID => ${winner.value}`);
          this.fetchBitpassFromCid(winner.value, sCtor, btml, preventExtendedValidation).then(ok => {
            ok.record = winner;
            cbOk(ok);
          }).catch(cbKo);
        } else
          cbKo({ code: 404, desc: 'Bitpass not found (records)', err: null });
      }, error: (err) =>
        cbKo({ code: 500, desc: 'Network problem fetching records', err })
      });
    });
  }
  fetchBitpassFromCid<S extends ParentSpecific<S> | Specific>(
    cid: string,
    sCtor: IConstructor<Bitpass<S>>,
    bpBtml=null,
    preventExtendedValidation = false,
  ) {
    return new Promise<{ product: IBitpass<S>; record: any }>((cbOk, cbKo) => {
      this.core.data.requests.getIpfs(cid).subscribe({next: (res: IBitpass<S>) => {
        // Legacy upgrade workaround
        if (!res.data_cid && (res as any).product_cid)
          res.data_cid = (res as any).product_cid;
        this.core.data.requests.getIpfsFile(res.data_cid).subscribe({next: async (ipfsRes: Blob) => {
          let prod: BitpassData<S>[];
          if (res.cyphered) {
            try {
              const res = await firstValueFrom(this.core.api.restrict.decypherMessage$Response({body:{
                cypherMessage: await ipfsRes.text(),
                privateKey: this.core.auth.userStorage.identities.privateID.privkeyPGP,
              }}));
              if (!res.ok) {
                console.error('Error decyphering bitpassData', res);
                throw new Error('Error decyphering bitpassData');
              }
              prod = JSON.parse(await res.body.text());
            } catch(err) {
              console.error('Error decyphering bitpassData', err);
              cbKo('Error decyphering bitpassData');
              return;
            }
          } else {
            // Directly fetch from blob
            prod = JSON.parse(await ipfsRes.text());
          }

          res.__data = this.launchProductUpgrades(prod);
          res.__signatureValidation = null;
          res.__selfCid = cid;
          res.__selfBtml = bpBtml;
          const instance = new sCtor(res);

          this.fetchIdentity(res.author, bpBtml, res)
            .then((identity) => {
              this.validateSign(
                instance,
                identity.__data[0].val.specific.specific.publicKey,
                () => {
                  if (!bpBtml || preventExtendedValidation) {
                    instance.__signatureValidation = 'partial';
                  } else { // Extended validation
                    this.fetchBpProperty(bpBtml).then(bpProp => {
                      if (bpProp.__data[0].val.specific.specific.specific.specific.bpBtml != bpBtml) {
                        console.warn('Signature extended validation failed, indexing problem, come to invalid ownership bp', `${bpProp.__data[0].val.specific.specific.specific.specific.bpBtml} should be ${bpBtml}`);
                        instance.__signatureValidation = false;
                      } else if (bpProp.__data[0].val.specific.specific.specific.specific.allowedIdentity != res.author.substring(0, 10)) {
                        console.warn('Signature extended validation failed, allowed author mismatch', `${res.author.substring(0, 10)} should be ${bpProp.__data[0].val.specific.specific.specific.specific.allowedIdentity}`);
                        instance.__signatureValidation = false;
                      } else {
                        instance.__signatureValidation = true;
                      }
                    }).catch(err => {
                      console.warn('Signature extended validation (bp prop) failed due to', err);
                      instance.__signatureValidation = false;
                    });
                  }
                },
                (reason) => {
                  instance.__signatureValidation = false;
                  console.warn(`Signature Fail[${cid}]`, reason, instance);
                }
              );
            })
            .catch((err) => {
              instance.__signatureValidation = false;
              console.warn(
                `Signature Fail[${cid}] - Cannot fetch identity`,
                err,
                instance
              );
            });
          cbOk({ product: instance, record: null });
        }, error: (err) => {
          cbKo({
            code: 500,
            desc: 'Error fetching bitpass product cid',
            err,
          });
        }});
      }, error: (err) => {
        cbKo({ code: 500, desc: 'Error fetching bitpass cid', err });
      }});
    });
  }

  fetchIdentity(author: string, currentBtml = null, ref = null) {
    return new Promise<BitpassResourceIdentity>(async (cbOk, cbKo) => {
      const authorBtml = author.substring(0, 10);
      if (currentBtml == authorBtml) cbOk(ref);
      else {
        this.fetchBitpass(authorBtml, BitpassResourceIdentity, true)
          .then((res) => {
            cbOk(res.product);
          })
          .catch((err) => cbKo(err));
      }
    });
  }

  fetchBpProperty(btml: string|number) {
    return new Promise<BitpassResourceConceptCertificateOwnership>(async (cbOk, cbKo) => {
      this.fetchBitpass(environment.prop_index_btml, BitpassIndexConceptCertificateOwnership, true).then((res) => {
        const bpProp = res.product.__data[0].val.specific.specific.specific.specific.bpKeyIndex[btml];
        if (!bpProp) cbKo(new Error('btml not found at ownership bp index'));
        else this.fetchBitpass(bpProp, BitpassResourceConceptCertificateOwnership, true).then((res) => {
          cbOk(res.product);
        }).catch((err) => cbKo(err));
      }).catch((err) => cbKo(err));
    });
  }

  //---------//

  launchProductUpgrades<S extends ParentSpecific<S> | Specific>(oldProduct: BitpassData<S>[]): BitpassData<S>[] {
    if (oldProduct[0].val.schemaVersion == undefined) {
      oldProduct[0].val.schemaVersion = 1;
      if (!Array.isArray(oldProduct[0].val.media)) oldProduct[0].val.media = [];
      if (oldProduct[0].val.icon == undefined)
        oldProduct[0].val.icon =
          'ipfs://QmabPJF6VF6pZRWyBLSriK1K5v2YZ3h3Qx3r6Tbf5tkagn';
      if (oldProduct[0].val.summary == undefined)
        oldProduct[0].val.summary = {
          title: 'Summary',
          cards: [],
        };
    }
    if (oldProduct[0].val.schemaVersion == 1) {
      oldProduct[0].val.schemaVersion = 2;

      oldProduct = oldProduct.slice(0, 1);
    }
    if (oldProduct[0].val.schemaVersion == 2) {
      oldProduct[0].val.schemaVersion = 3;

      oldProduct[0].val.specific = new Resource<Product<any>>({
        _proto: 'Resource',
        btmlPart: 1,
        fileName: '',
        specific: new Product<any>({
          _proto: 'Product',
          btmlPart: 1,
          productDescription: '',
          productName: '',
          specific: null,
        }),
      }) as unknown as S;

      if ('desc' in oldProduct[0].val) delete (oldProduct[0].val as any).desc;
    }
    if (oldProduct[0].val.schemaVersion == 3) {
      oldProduct[0].val.schemaVersion = 4;

      // Check background
      if (!oldProduct[0].val.background) oldProduct[0].val.background = ''; // TODO: ask for ipfs:// preloaded resource hash

      // Check new format for btmlCard and textCard
      for (let card of oldProduct[0].val.summary.cards) {
        if (card.cardElements) {
          for (let cardElement of card.cardElements) {
            if (cardElement['btml'] !== undefined && cardElement['btmlCard'] == undefined) {
              cardElement['btmlCard'] = { btml: cardElement['btml'], editable: false }
            } if (cardElement['value'] !== undefined && cardElement['textCard'] == undefined) {
              cardElement['textCard'] = { value: cardElement['value'], type: 'string', required: false }
            }
          }
        }
      }

    }
    // Current version schemaVersion => 4

    return oldProduct;
  }

  recursivelyCleanTmpProperties(obj: object) {
    for (let prop in obj) {
      if (prop.startsWith('__')) {
        delete obj[prop];
      } else if (Array.isArray(typeof obj[prop])) {
        for (let childObj of obj[prop]) {
          this.recursivelyCleanTmpProperties(childObj);
        }
      } else if (typeof obj[prop] == 'object') {
        this.recursivelyCleanTmpProperties(obj[prop]);
      }
    }
  }

  saveProduct<S extends ParentSpecific<S> | Specific>(
    bitpassData: BitpassData<S>[],
    btmlType = '11000',
    btmlRevision = null,
    loading = null
  ) {
    return new Promise(async (cbOk, cbKo) => {
      const userIdentity = this.core.auth.userStorage.identities;
      if (!userIdentity || !userIdentity.privateID.privkeyPGP || !userIdentity.publicID.bp_id) {
        cbKo(new Error('No user identity present'));
        return;
      }

      // Fetch distribution identities public keys
      const distributionPubkeys = [];
      for (let distId of (bitpassData[0].val.distributionIdentities || [])) {
        try {
          const distIdBtml = distId.substring(0, 10);
          const idBP = await this.fetchBitpass(distIdBtml, BitpassResourceIdentity, false);
          distributionPubkeys.push(idBP.product.__data[0].val.specific.specific.publicKey);
        } catch(err) {console.error('Error fetching distributionIdentities bitpasses', err)}
      }
      if (distributionPubkeys.length != bitpassData[0].val.distributionIdentities.length) {
        cbKo(new Error('Cannot fetch all distribution identities'));
        return;
      }

      const newRevision = await this.getNextBtmlRevision(btmlType, btmlRevision);
      // 0.0. Actualizamos timestamp producto (meta, provoca un cid distinto y previene errores)
      bitpassData[0].val['updated'] = Date.now();

      // Clean product from __ starting properties
      this.recursivelyCleanTmpProperties(bitpassData);

      let savingBitpassDataContent = this.core.fileFromJson(bitpassData);
      if (distributionPubkeys.length) {
        try {
          savingBitpassDataContent = this.core.fileFromString((await firstValueFrom(this.core.api.restrict.cypherMessage({body:{
            publicKeys: distributionPubkeys,
            message: JSON.stringify(bitpassData)
          }}))).cypherMessage);
        } catch (err) {
          console.error('Error cyphering bitpassData', err);
          cbKo('Error cyphering bitpassData');
          return;
        }
      }

      // 1. Pineamos Producto
      this.core.data.requests
        .putIpfs(savingBitpassDataContent)
        .subscribe({
          next: (pin: any) => {
            const bpDataCid = pin.cid;
            const bitPass: Bitpass<S> = new GenericBitpass({
              pkpass_cid: null,
              data_cid: bpDataCid,
              cyphered: !!distributionPubkeys.length,
              author: userIdentity.publicID.bp_id,
              signature: '',
              nodeInfo: {
                blockchain: 'bitcoin tesnet',
                address: 'tb1qvlz5tu2z48sjx5tn43wxcgvrkm2wxyjhzw8l4g',
              },
              __data: bitpassData,
            });

            // 2. Generamos pkpass desde datos Producto
            this.fillPkpassFromBitpass(
              bitPass,
              newRevision.split('v')[1],
              (files: File[]) => {
                this.generatePkpass(
                  files,
                  async (pkpass: Blob) => {
                    let pkPassFile = new File([pkpass], 'file.pkpass');

                    if (distributionPubkeys.length) {
                      try {
                        pkPassFile = this.core.fileFromString((await firstValueFrom(this.core.api.restrict.cypherMessage({body:{
                          publicKeys: distributionPubkeys,
                          filepath: (await firstValueFrom(this.core.api.jwt.uploadFile({body:{file: pkpass}}))).filename,
                        }}))).cypherMessage);
                      } catch(err) {
                        console.error('Error cyphering pkpass', err);
                        cbKo('Error cyphering pkpass');
                        return;
                      }
                    }

                    // 3. Pineamos pkpass
                    this.core.data.requests
                      .putIpfs(pkPassFile)
                      .subscribe({
                        next: (pin: any) => {
                          bitPass.pkpass_cid = pin.cid;

                          // 4. Firmamos bitpass
                          this.signSignable(
                            bitPass,
                            userIdentity.privateID.privkeyPGP,
                            (sign) => {
                              const pushingBP = this.signablePlainObject(bitPass);
                              pushingBP.signature = sign;

                              // 5. Pineamos bitpass
                              this.core.data.requests
                                .putIpfs(this.core.fileFromJson(pushingBP))
                                .subscribe({
                                  next: async (pin: any) => {
                                    const bitPassCid = pin.cid;

                                    // 6. Inmutabilizamos
                                    try {
                                      this.core.data.requests
                                        .postRecord({
                                          key: newRevision,
                                          value: bitPassCid,
                                          timestamp: 0,
                                        })
                                        .subscribe({
                                          next: (res) => {
                                            if (newRevision.split('v')[0]=='0') {
                                              console.log('Requesting ownership for '+newRevision);
                                              this.createBpOwnershipReq(newRevision.split('v')[1], userIdentity.publicID.bp_id);
                                            }
                                            cbOk?.(newRevision);
                                          },
                                          error: (err) => cbKo?.(err)
                                        });
                                    } catch (err) {
                                      cbKo?.(err);
                                    }
                                  },
                                  error: (err) => cbKo?.(err)
                                });
                            },
                            (err) => cbKo?.(err),
                            loading
                          );
                        },
                        error: (err) => cbKo?.(err)
                      });
                  },
                  (err) => cbKo?.(err)
                );
              },
              (err) => cbKo?.(err)
            );
          },
          error: (err) => cbKo?.(err)
        });
    });
  }

  createBpOwnershipReq(btmlBitpass:string|number, btmlIdentity) {
    this.core.api.agram.createOwnership({worker: 'general_worker_server', body: {
      btmlBitpass: btmlBitpass as string,
      btmlIdentity: btmlIdentity.slice(0, 10),
    }}).subscribe(res => {
      if (res) {
        console.log('Ownership created!');
      } else {
        console.error('Ownership cannot be created!');
      }
    }, err => {
      console.error(err);
    });
  }

  createBpTransferReq(btmlBitpass:string|number, btmlNewOwnerIdentity) {
    const bitpassData = createTaskFromTpl('Request to transfer BP ownership', [{
      label: 'Ownership Transfer',
      actionSpec: '1400000016',
      params: {input:[`${btmlBitpass}`, btmlNewOwnerIdentity], output: ['var://contract_btml']}
    }], []);
    console.log(bitpassData)
    this.core.bps.saveProduct(
      bitpassData,
      '70000'
    ).then((newRevision: string) => {
      console.log('Tarea guardada correctamente, revisión '+newRevision);

      this.core.data.requests.addTaskToWorker(newRevision.split('v')[1]).subscribe(res => {
        console.log('Notificado al worker correctamente? ', res);

      }, error => {
        console.error(error);
      });

    }).catch(err => {
      console.error(err);
    });
  }

  getNextBtmlRevision(
    btmlType: string = '11000',
    actualRevision: string = null
  ): Promise<string> {
    return new Promise<string>((cbOk, cbKo) => {
      if (!actualRevision) {
        this.core.data.requests.nextBtmlKey(btmlType).subscribe({
          next: (res: any) => {
            cbOk(res.nextBtml);
          },
          error: (err) => cbKo(err)
        });
      } else {
        const split = actualRevision.split('v');
        const nextRevision = parseInt(split[0]) + 1;
        cbOk(`${nextRevision}v${split[1]}`);
      }
    });
  }

  async fillPkpassFromBitpass(
    bitpass: GenericBitpass,
    btml: string,
    cbOk: Function = null,
    cbKo: Function = null
  ) {
    try {
      const nextDayDate = new Date();
      nextDayDate.setDate(nextDayDate.getDate() + 1);

      const isTestnet = this.core.auth.chainList?.['Money_Chain']?.find(itm => itm.chain=='mainnet')?.inmutabilization_address ? '' : 'testnet/';
      const relayAddress = isTestnet ? this.core.auth.chainList?.['Money_Chain']?.find(itm => itm.chain=='testnet')?.inmutabilization_address : this.core.auth.chainList?.['Money_Chain']?.find(itm => itm.chain=='mainnet')?.inmutabilization_address;

      const files: File[] = [];
      const pkpassJson = {
        formatVersion: 1,
        organizationName: 'Detailorg Consulting', // TODO: Try to change this and check sign
        logoText: bitpass.__data[0].val.title,
        description: 'NFT',
        foregroundColor: bitpass.__data[0].val['pass.json'].foregroundColor,
        backgroundColor: bitpass.__data[0].val['pass.json'].backgroundColor,
        generic: {
          headerFields: bitpass.__data[0].val['pass.json'].headerFields,
          primaryFields: bitpass.__data[0].val['pass.json'].primaryFields,
          secondaryFields: bitpass.__data[0].val['pass.json'].secondaryFields,
          auxiliaryFields: bitpass.__data[0].val['pass.json'].auxiliaryFields,
          backFields: [
            ...(relayAddress ? [
              {
                key: 'title_back',
                label: 'Instrucciones de Verificación',
                value: ' ',
              },
              {
                key: 'address_btc',
                label: 'Inmutabilización en Bitcoin',
                value:
                  'Esta es la dirección de Bitcoin que inmutabiliza el producto: ' +
                  relayAddress +
                  ' puedes verla a través de un explorador público como: https://blockstream.info/'+isTestnet+'address/' +
                  relayAddress,
              },
            ]:[]),
            {
              key: 'sidechain_block',
              label: 'Bloque de la Sidechain',
              value:
                'Ve a cualquier transacción del dia ' +
                nextDayDate.toDateString() +
                ' y encontrarás CID del Bloque de cadena lateral, puedes visualizarlo en un explorador de IPFS como: https://ipfs.io/ipfs/<EL CID DEL BLOQUE>',
            },
            {
              key: 'nft_cid',
              label: 'CID del NFT',
              value: 'Dentro encontrarás el CID de este BitPass (NFT) como clave del valor: ' + btml,
            },
            {
              key: 'dSequence_url',
              label: 'dSequence Explorer',
              value:
                'Para una visualización completa usa este link: '+'https://dsequence.agramproject.com'+'/product/' + btml,
            },
          ],
        },
      };
      files.push(this.core.fileFromJson(pkpassJson, 'pass'));

      for (const k in bitpass.__data[0].val.passImages) {
        try {
          if (bitpass.__data[0].val.passImages[k]) {
            const itm = bitpass.__data[0].val.passImages[k].split('ipfs://')[1];

            const resource = await firstValueFrom(this.core.data.requests.getIpfsFile(itm));
            files.push(new File([resource], k));
          }
        } catch (_err) {
          console.error('Error loading image', k);
        }
      }

      cbOk?.(files);
    } catch (err) {
      cbKo?.(err);
    }
  }

  generatePkpass(files: File[], cbOk: Function = null, cbKo: Function = null) {
    // console.warn(files);
    this.core.data.requests.pkpassSign(files).subscribe(
      (resource) => {
        cbOk?.(resource);
        // if (resource.ok) {
        // } else {
        //   cbKo?.('Error signing pkpass');
        // }
      },
      (err) => cbKo?.(err)
    );
  }

  //---------//

  signablePlainObject(signableObjRef) {
    const obj: any = {};
    for (let k in signableObjRef) {
      if (k !== 'signature' && !k.startsWith('__')) {
        obj[k] = signableObjRef[k];
      }
    }
    return obj;
  }

  signSignable(
    signableObjRef,
    privateKey: string,
    cbOk?: Function,
    cbKo?: Function,
    loading = null
  ) {
    const obj = this.signablePlainObject(signableObjRef);
    var hash = Base64.fromUint8Array(
      crypto.createHash('sha256').update(JSON.stringify(obj)).digest()
    );

    this.core.data.requests.pgpSign(hash, privateKey).subscribe(
      (res: any) => {
        console.log('pgpSign', res.signedMessage);
        cbOk?.(res.signedMessage);
      },
      (err) => {
        console.error('pgpSign', err);
        cbKo?.(err);
      }
    );
  }

  validateSign(
    signableObjRef,
    publicKey: string,
    cbOk: Function = null,
    cbKo: Function = null
  ) {
    if (signableObjRef.signature===undefined) cbKo && cbKo('No signable object provided');
    else {
      const obj = this.signablePlainObject(signableObjRef);
      var hash = Base64.fromUint8Array(
        crypto.createHash('sha256').update(JSON.stringify(obj)).digest()
      );

      this.core.data.requests
        .pgpVerify(signableObjRef.signature, hash, publicKey)
        .subscribe(
          (res) => {
            // console.log('pgpVerify', res);
            if (res) {
              cbOk?.(res);
            } else {
              cbKo?.(res);
            }
          },
          (err) => {
            console.error('pgpVerify', err);
            cbKo?.(err);
          }
        );
    }
  }

  /**
   * @deprecated
   * @param privKey
   * @param loading
   * @returns promise
   */
  getUserCypheredPw(privKey, loading = null) {
    return new Promise<string>(async (cbOk, cbKo) => {
      if (privKey) cbOk(null);
      else {
        if (loading) await loading.dismiss();
        this.core.alertCtrl
          .create({
            message: 'Introduzca su contraseña para descifrar la clave privada',
            inputs: [{ type: 'password', name: 'pw' }],
            buttons: [
              {
                role: 'cancel',
                text: 'Cancelar',
                handler: () => cbKo('cancel'),
              },
              {
                text: 'Confirmar',
                handler: async (data) => {
                  console.log(data);
                  if (loading) await loading.present();
                  cbOk(data.pw);
                },
              },
            ],
          })
          .then((a) => a.present());
      }
    });
  }

  setLastBPVisited(url) {
    localStorage.setItem('lastBPURL', url);
  }

  putVisitedBP(bp: string) {
    bp = bp.includes('v') ? bp.split('v')[1] : bp;

    if (!this.visitedBP.length) {
      this.visitedBP = (localStorage.getItem('visitedBP') ? JSON.parse(localStorage.getItem('visitedBP')) : []);
    }

    var i = 0;
    this.visitedBP.forEach(element => {
      if (element == bp) {
        this.visitedBP.splice(i, 1);
        i--;
      }
      i++
    });

    this.visitedBP.unshift(bp);
    localStorage.setItem('visitedBP', JSON.stringify(this.visitedBP));
  }

  deleteVisitedBP() {
    this.visitedBP = [];
    localStorage.removeItem('visitedBP');
  }

}
