import { BtmlMap, ParentSpecific } from "../../base-specific";
import { Certificate, CertificateTypes } from "./certificate/certificate";


export type ConceptTypes = Certificate<CertificateTypes>;
export const ConceptBtmlMap: BtmlMap = {
    1: Certificate,
}

export interface IConcept<T extends ConceptTypes> extends ParentSpecific<T> {
}

export class Concept<T extends ConceptTypes> extends ParentSpecific<T> implements IConcept<T> {
    readonly _proto?: string = 'Concept';
    readonly btmlPart: number = 2;

    constructor(data: IConcept<T>) {
        super(data, ConceptBtmlMap);
        // Implement update logic here
    }

}
