import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { Balance, GetFeeRequest, MakeSegwitTxRequest2 } from 'src/app/services/api/models';
import { CoreProvider } from 'src/app/services/core';
import { UserStorageModels } from 'src/app/services/user-storage-models';
import { environment } from 'src/environments/environment';
import BaseModal from '../base-modal';
import { TokenTypes, PaymentTypes } from '../../misc-module/components/blockchain-wallets/blockchain-wallets.component';

export interface IFormData {
  bitCuantity: number;
  description: string;
  address: string;
  moneyOptions: { tokenType: TokenTypes, payType: PaymentTypes };
  wallet: UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets;
}

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrls: ['./payment-modal.component.scss'],
})
export class PaymentModalComponent extends BaseModal<IFormData> implements OnInit, OnDestroy {
  protected formIdentifier: string = 'pay';

  @Input() set data(value: any) {
    console.log(value);

    if (!value.formData) {
      this.dataInfo = value;
      if (value.address) {
        this.address = value.address;
      }
    } else {
      this.wallet = value.formData.wallet;
      this.address = value.formData.address;
      this.description = value.formData.description;
      this.moneyOptions = value.formData.moneyOptions;
      this.bitCuantity = value.formData.bitCuantity;

      this.fetchBitpass(this.wallet.bp).subscribe({
        next: res => {
          if (res) {
            this.bitpass = res;
            this.toSecondPage();
          }
        }, error: err => {
          console.error(err);
        }
      })
    }

  }

  dataInfo: { address: string, tokenType: TokenTypes, payType: PaymentTypes, wallet: UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets }

  page = 1;
  address = '';
  validAddress = false;
  successPayment = 0;
  seconds = 10;
  totalBalance: Balance = null;
  balance: Balance;
  bitCuantity: number;
  euroCuantity: number;
  decimals: number;
  placeholder = '0';
  description = '';
  feeData: GetFeeRequest;
  fee = 1;
  euroFee = 0;
  satvB = 0;
  txid: string;
  txidURL: string;
  segwitBody: MakeSegwitTxRequest2;
  timer: any;

  bitpass: Bitpass<any>;
  wallet: UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets;
  moneyOptions: { tokenType: TokenTypes, payType: PaymentTypes };

  constructor(public core: CoreProvider) {
    super(core);
  }
  protected onCommit(): Promise<any> {
    return new Promise((ok, ko) => {
      try {
        this.page = 3;
        var chain = this.bitpass.__data[0].val.specific.specific.specific.specific.specific.chain;
        // Proceed with the payment
        try {
          if (this.dataInfo.payType=='lightning') {
            this.core.api.lnDefault.apiPaymentsCreateApiV1PaymentsPost(
              this.formDataStore.wallet.chain,
              (this.formDataStore.wallet as UserStorageModels.LightningWallets).credentials.adminkey,
              {body: { out: true, bolt11: this.formDataStore.address }}
            ).subscribe({
              next: res => {
                console.log(res);

                this.txid = res.payment_hash;
                this.txidURL = '';
                this.balance = {unconfirmed: this.bitCuantity, confirmed: this.bitCuantity};

                ok(res);
              },
              error: err => {
                console.error('ERROR: ', err);
                this.successPayment = 3;
                ko(err);
              }
            });
          } else {
            this.core.api.bitcoin.makeSegwitTx2({
              chain: chain,
              body: {
                seed: (this.wallet as UserStorageModels.BlockchainWallets).seed,
                fee: this.satvB,
                outputs: [
                  {
                    addressTarget: this.address.split('?')[0],
                    amount: this.convertToRealValue(this.bitCuantity, true),
                  },
                ],
              },
            }).subscribe({
              next: res => {
                if (res) {
                  console.log(res);

                  this.txid = res.txid;
                  this.txidURL = `${this.core.relayEndpoint}electrs/${chain}/tx/${this.txid}`;

                  (this.wallet as UserStorageModels.BlockchainWallets).sent.push({
                    addressTarget: this.address.split('?')[0],
                    label: this.description,
                    date: '' + Date.now(),
                    idTx: this.txid
                  });
                  this.core.auth.saveUserStorage();

                  this.getBalanceChain();
                  ok(res)
                }
              },
              error: err => {
                console.error('ERROR: ', err);
                this.successPayment = 2;
                ko(err);
              }
            });
          }
        } catch (error) {
          console.log('ERROR Sending Payment', error);
          this.successPayment = 2;
          ko(error);
        }
      } catch (error) {
        ko(error);
      }
    })
  }

  onSubmit() {
    this.readFormToStore();
    this.onCommit();
  }

  ngOnInit() { }

  setBitpass(bp: Bitpass<any>) {
    this.bitpass = bp;
    this.checkAddress();
    this.updatePlaceholder();
    this.getFee();
  }
  setDecimals(dec: number) {
    this.decimals = dec;
  }
  setWallet(wallet) {
    this.wallet = wallet;
  }
  changeCuantity(event) {
    this.bitCuantity = event;
  }
  setMoneyOptions(options) {
    this.moneyOptions = options;
  }

  checkAddress() {
    if (this.bitpass) {
      this.validAddress = true;
      if (this.dataInfo.payType == 'lightning') return; // Everytime valid

      // BTC Address Validations
      var addressValidation = this.bitpass.__data[0].val.specific.specific.specific.specific.specific.addressValidation;

      if (!this.address.startsWith(addressValidation)) {
        this.validAddress = false;
        return;
      } else if (this.address.substring(addressValidation.length).split('?')[0].length != 38) {
        this.validAddress = false;
        return;
      }
    }
  }

  capturedQr(result: string) {
    console.warn('QR RESULT', result);
    this.address = result;
    this.checkAddress();
    if (this.validAddress) {
      this.page = 2;
    }
  }
  capturedQrError(result: string) {
    console.error('QR RESULT ERR', result);
    this.address = result;
    this.checkAddress();
  }


  toSecondPage() {
    var chain = this.bitpass.__data[0].val.specific.specific.specific.specific.specific.chain;
    // Fill bitCuantity input with the amount given
    this.bitCuantity = parseFloat(this.address.split('=')[1]) ?? null;
    this.bitToEuro();

    // Get total Balance
    if ('pubkey' in this.wallet) {
      console.log('Trying to fetch wallet balance');
      this.core.api.find.XpubBalance({
        chain: chain,
        body: {
          ypub: this.wallet.pubkey
        },
      }).subscribe((res: any) => {
        this.totalBalance = res;
        console.log(this.totalBalance);
        this.totalBalance.confirmed = this.convertToRealValue(this.totalBalance.confirmed, false);
      });
    }
    if (this.moneyOptions.payType=='lightning') {
      this.core.api.lnDefault.apiWalletApiV1WalletGet(chain, (this.wallet as UserStorageModels.LightningWallets).credentials.adminkey).subscribe(res => {
        this.totalBalance = {unconfirmed: 0, confirmed: this.convertToRealValue(res.balance/1000, false)};
      });
      this.core.api.lnDefault.apiPaymentsDecodeApiV1PaymentsDecodePost(chain, undefined, {body: {data: this.address.split('?')[0]}}).subscribe(res => {
        this.bitCuantity = res.amount_msat / 1000 / Math.pow(10, 8);
      });
    }

    this.page = 2;
  }

  bitToEuro() {
    this.euroCuantity = this.bitCuantity * 20000;
  }
  euroToBit() {
    this.bitCuantity = this.euroCuantity / 20000;
  }
  checkDecimals() {
    var decPart = (this.bitCuantity + '').split('.')[1];
    if (decPart) {
      if (decPart.length > this.decimals) {
        this.bitCuantity = parseFloat(this.bitCuantity.toFixed(this.decimals));
      }
    }
  }

  updatePlaceholder() {
    this.placeholder = '0';
    for (let j = 0; j < this.decimals; j++) {
      if (j == 0) this.placeholder += ',';
      this.placeholder += 0;
    }
  }

  getFee() {
    var chain = this.bitpass.__data[0].val.specific.specific.specific.specific.specific.chain
    if (chain == 'testnet' || chain == 'mainnet') {
      this.core.api.bitcoin.getFee({ chain: chain }).subscribe((res) => {
        if (res) {
          this.feeData = res;
          this.satvB = this.feeData.estimation.conservativeFee;
          this.calculateFee();
        }
      });
    } else {
      this.feeData = null;
    }
  }

  calculateFee() {
    this.fee = parseFloat(this.convertToRealValue(this.satvB * 150, false).toFixed(this.decimals));
    this.euroFee = parseFloat((this.fee * 20000).toFixed(2));
  }

  getBalanceChain() {
    this.core.api.find.balanceChain({
      chain: this.bitpass.__data[0].val.specific.specific.specific.specific.specific.chain,
      address: this.address.split('?')[0],
    }).subscribe((res) => {
      if (res) {
        this.balance = res;
        console.log(this.balance);
      }
    });
  }

  /**
   *
   * @param symbol True: positive | False: negative
   * @returns
   */
  convertToRealValue(value, symbol: boolean) {
    if (symbol) {
      return (value * Math.pow(10, this.bitpass.__data[0].val.specific.specific.specific.specific.specific.decimals));
    } else {
      return (value * Math.pow(10, -this.bitpass.__data[0].val.specific.specific.specific.specific.specific.decimals));
    }
  }

  moveSlider(event) {
    this.satvB = event.detail.value;
    this.calculateFee();
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  navigateToWallet() {
    clearInterval(this.timer);
    this.core.modalCtrl.dismiss();
    this.core.navCtrl.navigateRoot('/wallet');
  }

  public async successToast() {
    await (
      await this.core.toastCtrl.create({
        message: 'Texto copiado en el portapapeles',
        duration: 2000,
        color: 'success',
      })
    ).present();
  }

  pow(exp, decimals) {
    return Math.pow(exp, decimals);
  }

  cropLength(string: string = ' ', length: number) {
    return string.slice(length) + '...';
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps.fetchBitpass(btml, Bitpass).then((bp) => {
        console.warn('URL ' + btml + ' loaded', bp);
        this.bpCache[btml].next(bp.product);
      }).catch((err) => {
        console.error('Cannot fetch minipass for url ' + btml);
      });
    }
    return this.bpCache[btml];
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bitCuantity: 0,
      description: '',
      address: '',
      moneyOptions: { tokenType: '' as TokenTypes, payType: '' as PaymentTypes },
      wallet: {} as UserStorageModels.BlockchainWallets | UserStorageModels.LightningWallets | UserStorageModels.VaultWallets,
    };
    dataStore.description = this.description;
    dataStore.moneyOptions = this.moneyOptions;
    dataStore.bitCuantity = this.bitCuantity;
    dataStore.address = this.address;
    dataStore.wallet = this.wallet;
    this.formDataStore = dataStore;
  }
}
