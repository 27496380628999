import { BtmlMap, ParentSpecific } from "../../../base-specific";
import { Ownership } from "./ownership/ownership";


export type CertificateTypes = Ownership;
export const CertificateBtmlMap: BtmlMap = {
    3: Ownership,
}

export interface ICertificate<T extends CertificateTypes> extends ParentSpecific<T> {
}

export class Certificate<T extends CertificateTypes> extends ParentSpecific<T> implements ICertificate<T> {
    readonly _proto?: string = 'Certificate';
    readonly btmlPart: number = 1;

    constructor(data: ICertificate<T>) {
        super(data, CertificateBtmlMap);

        // Implement update logic here
    }

}
