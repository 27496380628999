/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class NextBtmlKeyAction extends RunnableAction {
  static readonly identifier = 'nextBtmlKey';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const type = params[0];

    const resp = await requests.nextBtmlKey(type);
    if (!resp.ok)
      throw new Error(
        'Error fetching nextBtml for type ' +
        type +
        '. Error: ' +
        JSON.stringify(resp),
      );

    return [(await resp.json()).nextBtml];
  }
}
