import { ActionSpec, RunnableAction } from '../runnable-action';
import { WorkerContext } from '../worker-context';

export class OpenFormAction extends RunnableAction {
  static readonly identifier = 'openForm';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const formIdentifier = params[0];
    let formData = undefined;
    try {
      formData = JSON.parse(params[1]) || undefined;
    } catch (err) {
      console.warn('Error parsing formData', err);
    }

    if (!WorkerContext.instance().modalFactory) {
      throw new Error('Current worker context has no modalFactory');
    }

    const ret = await WorkerContext.instance().modalFactory.openModal(
      formIdentifier,
      { formData }
    );

    return [JSON.stringify(ret)];
  }
}
