/* eslint-disable @typescript-eslint/no-explicit-any */
import { getActionExecutor } from '../action-catalog';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class OwnershipTransfer extends RunnableAction {
  static readonly identifier = 'ownershipTransfer';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const btmlOwnershipBitpass = params[0];
    const btmlNewOwnerIdentity = params[1];

    const ownershipBpData = JSON.parse((
      await getActionExecutor('fetchBitpass')!.run(`${btmlOwnershipBitpass}`)
    )[1]);

    ownershipBpData[0].val.specific.specific.specific.specific.allowedIdentity = btmlNewOwnerIdentity;

    await getActionExecutor('saveBitpass')!.run(
      JSON.stringify(ownershipBpData),
      process.env.WORKER_SIGN_AUTHOR!,
      process.env.WORKER_SIGN_PRIVKEY!,
      '',
      `${btmlOwnershipBitpass}`,
    );

    return ['true'];
  }
}
