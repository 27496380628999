/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddressRequest } from '../models/address-request';
import { PassphraseRequestNew } from '../models/passphrase-request-new';
import { SeedRequestNew } from '../models/seed-request-new';
import { GetBlockRequest } from '../models/get-block-request';
import { GetFeeRequest } from '../models/get-fee-request';
import { GetTxRequest } from '../models/get-tx-request';
import { MakeSegwitTxRequest2 } from '../models/make-segwit-tx-request-2';
import { XzPubRequestNew } from '../models/xz-pub-request-new';


/**
 * `Create` value content with maximum visibility and security in <b>Blockchain</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer1Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bitcoinControllerSegwitAddress()` */
  static readonly BitcoinControllerSegwitAddressPath = '/BTC/{chain}/create/address/{type}';

  /**
   * (comming...) Explore UTXOs of a wallet.
   *
   * Gives you a new address from:
   * - *<b>LittleUser</b>: The seed storage in the internal space for the user in the node (use 'passphrase' parameter)
   * - From a external public master key (use 'ypub' parameter)
   *
   * *<b>LittleUser</b>: The cyphered seed is save in the server,
   * user internal storage is only for 1 seed, if you create another one it will be overwritten. User external storage for manage more than 1 seed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `segwitAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  segwitAddress$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Select the type of address
     *  Available values : segwit
     */
      type: 'segwit';
  
    /**
     * Add a valid xpub/zpub (or a PGP privKey in LittleUser mode)
     */
    body?: XzPubRequestNew
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AddressRequest>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerSegwitAddressPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('type', params.type, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddressRequest>;
      })
    );
  }

  /**
   * (comming...) Explore UTXOs of a wallet.
   *
   * Gives you a new address from:
   * - *<b>LittleUser</b>: The seed storage in the internal space for the user in the node (use 'passphrase' parameter)
   * - From a external public master key (use 'ypub' parameter)
   *
   * *<b>LittleUser</b>: The cyphered seed is save in the server,
   * user internal storage is only for 1 seed, if you create another one it will be overwritten. User external storage for manage more than 1 seed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `segwitAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  segwitAddress(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Select the type of address
     *  Available values : segwit
     */
      type: 'segwit';
  
    /**
     * Add a valid xpub/zpub (or a PGP privKey in LittleUser mode)
     */
    body?: XzPubRequestNew
    },
    context?: HttpContext
  ): Observable<AddressRequest> {
    return this.segwitAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<AddressRequest>): AddressRequest => r.body)
    );
  }

  /** Path part for operation `btcGetSeed()` */
  static readonly BtcGetSeedPath = '/BTC/{chain}/create/seed/{type}';

  /**
   * Generate new Seed.
   *
   * Add a passphrase or the PGP privateKey to cypher and gives you a new random seed (and its public master key).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSeed$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Select the type of wallet
     *  Available values : segwit
     */
      type: 'segwit';
      body?: PassphraseRequestNew
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SeedRequestNew>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BtcGetSeedPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('type', params.type, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SeedRequestNew>;
      })
    );
  }

  /**
   * Generate new Seed.
   *
   * Add a passphrase or the PGP privateKey to cypher and gives you a new random seed (and its public master key).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSeed(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Select the type of wallet
     *  Available values : segwit
     */
      type: 'segwit';
      body?: PassphraseRequestNew
    },
    context?: HttpContext
  ): Observable<SeedRequestNew> {
    return this.getSeed$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeedRequestNew>): SeedRequestNew => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerIsSync()` */
  static readonly BitcoinControllerIsSyncPath = '/BTC/{chain}/search/chainList';

  /**
   * List of BTC chains nodes and its status.
   *
   * Use to obtain the status of a chain before use it or leave empty to obtain the complete list of nodes syncronized or not (false)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `isSync()` instead.
   *
   * This method doesn't expect any request body.
   */
  isSync$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerIsSyncPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * List of BTC chains nodes and its status.
   *
   * Use to obtain the status of a chain before use it or leave empty to obtain the complete list of nodes syncronized or not (false)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `isSync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  isSync(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.isSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerGetFee()` */
  static readonly BitcoinControllerGetFeePath = '/BTC/{chain}/search/fee';

  /**
   * Explore necessary fee to enter in the blockchain.
   *
   * Gives you all necessary data to guide in the election of the correct fee.
   *
   * *All is in bytes or sat per bytes (sat/b)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFee()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFee$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetFeeRequest>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerGetFeePath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetFeeRequest>;
      })
    );
  }

  /**
   * Explore necessary fee to enter in the blockchain.
   *
   * Gives you all necessary data to guide in the election of the correct fee.
   *
   * *All is in bytes or sat per bytes (sat/b)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFee$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFee(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<GetFeeRequest> {
    return this.getFee$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetFeeRequest>): GetFeeRequest => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerGetTx()` */
  static readonly BitcoinControllerGetTxPath = '/BTC/{chain}/search/tx/{txid}';

  /**
   * Explore tx data.
   *
   * Choose a valid chain and de id of a transaction, and receive all data of it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTx()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTx$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid txid
     */
      txid: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetTxRequest>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerGetTxPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('txid', params.txid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetTxRequest>;
      })
    );
  }

  /**
   * Explore tx data.
   *
   * Choose a valid chain and de id of a transaction, and receive all data of it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTx$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTx(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid txid
     */
      txid: string;
    },
    context?: HttpContext
  ): Observable<GetTxRequest> {
    return this.getTx$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTxRequest>): GetTxRequest => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerGetBlockC()` */
  static readonly BitcoinControllerGetBlockCPath = '/BTC/{chain}/search/{blockHash}';

  /**
   * Explore block data.
   *
   * Choose a valid chain and de id of a block, and receive all data of it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBlockC()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlockC$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid blockHash
     */
      blockHash: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetBlockRequest>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerGetBlockCPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('blockHash', params.blockHash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetBlockRequest>;
      })
    );
  }

  /**
   * Explore block data.
   *
   * Choose a valid chain and de id of a block, and receive all data of it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBlockC$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBlockC(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid blockHash
     */
      blockHash: string;
    },
    context?: HttpContext
  ): Observable<GetBlockRequest> {
    return this.getBlockC$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetBlockRequest>): GetBlockRequest => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerMakeSegwitTx2()` */
  static readonly BitcoinControllerMakeSegwitTx2Path = '/BTC/{chain}/send/tx/segwit';

  /**
   * Make a BTC tx or timestamps using Segwit.
   *
   * Make a BTC tx or timestamps using Segwit:
   *
   *
   *
   * *You need at least 1 opreturn or 1 output and 1 passphrase or 1 seed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `makeSegwitTx2()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  makeSegwitTx2$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
  
    /**
     * Parameters:
     *
     * - <b>Seed</b>: valid seed <i>(BIP 39)<//i>
     * - <b>Passphrase (optional)</b>: privKey PGP that you used before to create your seed cyphered. If empty passphrase is provided, user storage seed is used
     * - <b>Fee</b>: sat/vbyte
     * - <b>Inputs (optional)</b>: n number of payments you want to make
     *   - <b>txid</b>: bitcoin transaction
     *   - <b>vout</b>: position inside transaction outputs
     * - <b>Outputs (optional)</b>: n number of payments you want to make
     *   - <b>addressTarget</b>: the bitcoin address to pay
     *   - <b>amount</b>: quantity of sats to pay
     * - <b>Opreturn (optional)</b>: array of strings to put a CIDs (public) or hashes (private) and create timestamps
     */
    body?: MakeSegwitTxRequest2
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<GetTxRequest>> {
    const rb = new RequestBuilder(this.rootUrl, Layer1Service.BitcoinControllerMakeSegwitTx2Path, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetTxRequest>;
      })
    );
  }

  /**
   * Make a BTC tx or timestamps using Segwit.
   *
   * Make a BTC tx or timestamps using Segwit:
   *
   *
   *
   * *You need at least 1 opreturn or 1 output and 1 passphrase or 1 seed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `makeSegwitTx2$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  makeSegwitTx2(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
  
    /**
     * Parameters:
     *
     * - <b>Seed</b>: valid seed <i>(BIP 39)<//i>
     * - <b>Passphrase (optional)</b>: privKey PGP that you used before to create your seed cyphered. If empty passphrase is provided, user storage seed is used
     * - <b>Fee</b>: sat/vbyte
     * - <b>Inputs (optional)</b>: n number of payments you want to make
     *   - <b>txid</b>: bitcoin transaction
     *   - <b>vout</b>: position inside transaction outputs
     * - <b>Outputs (optional)</b>: n number of payments you want to make
     *   - <b>addressTarget</b>: the bitcoin address to pay
     *   - <b>amount</b>: quantity of sats to pay
     * - <b>Opreturn (optional)</b>: array of strings to put a CIDs (public) or hashes (private) and create timestamps
     */
    body?: MakeSegwitTxRequest2
    },
    context?: HttpContext
  ): Observable<GetTxRequest> {
    return this.makeSegwitTx2$Response(params, context).pipe(
      map((r: StrictHttpResponse<GetTxRequest>): GetTxRequest => r.body)
    );
  }

}
