import { ActionSpec, RunnableAction } from '../runnable-action';

export class SumAction extends RunnableAction {
  static readonly identifier = 'sum';
  public actionSpec: ActionSpec = {
    description: 'Sumar dos entradas y genera resultado en una salida',
    actionParmDef: {
      input: [
        { pattern: '(\d+(?:[\.]\d{2})?)', label: 'Operator 1', tooltip: 'Ej: 3' },
        { pattern: '(\d+(?:[\.]\d{2})?)', label: 'Operator 2', tooltip: 'Ej: 17' },
      ],
      output: [
        { pattern: '(\d+(?:[\.]\d{2})?)', label: 'Result', tooltip: 'Ej: 20' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const operator1 = params[0];
    const operator2 = params[1];
    const result = +operator1 + +operator2;
    return [result + ''];
  }
}
