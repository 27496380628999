/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class PgpVerifyAction extends RunnableAction {
  static readonly identifier = 'pgpVerify';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const signedMessage = params[0];
    const data = params[1];
    const publicKey = params[2];

    const resp = await requests.pgpVerify(signedMessage, data, publicKey);
    if (!resp.ok)
      throw new Error(
        'Error in pgpVerify of ' +
        signedMessage +
        ', ' +
        data +
        ', ' +
        publicKey +
        '. Error: ' +
        (await resp.text()),
      );

    return [await resp.text()];
  }
}
