import { BtmlMap, ParentSpecific } from "../base-specific";
import { Image } from "./image/image";

export type FileTypes = Image;
export const FileBtmlMap: BtmlMap = {
    1: Image,
}

export interface IFile<RT extends FileTypes> extends ParentSpecific<RT> {
    fileName: string;
}

export class File<RT extends FileTypes> extends ParentSpecific<RT> implements IFile<RT> {
    readonly _proto?: string = 'File';
    readonly btmlPart: number = 0;

    fileName: string = null;

    constructor(data: IFile<RT>) {
        super(data, FileBtmlMap);

        this.fileName = data.fileName;
        // Implement update logic here
    }

}
