/* eslint-disable @typescript-eslint/no-explicit-any */
import { getActionExecutor } from '../action-catalog';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class OwnershipCreate extends RunnableAction {
  static readonly identifier = 'ownershipCreate';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const btmlBitpass = params[0];
    const btmlOwnerIdentity = params[1];

    // Create ownershipBP
    const ownershipIndexBtml = 5213000000; // Default ownership index bp btml
    const ownershipIndexBpData = JSON.parse((
      await getActionExecutor('fetchBitpass')!.run(`${ownershipIndexBtml}`)
    )[1]);

    if (ownershipIndexBpData[0].val.specific.specific.specific.specific.bpKeyIndex[btmlBitpass]) {
      throw new Error('Already exists a ownership for btmlBitpass key ' + btmlBitpass);
    }

    const taskbitpassData = ownershipBpTemplate;
    taskbitpassData[0].val.specific.specific.specific.specific.bpBtml = btmlBitpass;
    taskbitpassData[0].val.specific.specific.specific.specific.allowedIdentity = btmlOwnerIdentity;

    const newOwnershipBitpassBtml = JSON.parse((await getActionExecutor('saveBitpass')!.run(
      JSON.stringify(taskbitpassData),
      process.env.WORKER_SIGN_AUTHOR!,
      process.env.WORKER_SIGN_PRIVKEY!,
      '12130',
      // undefined, // No target btml, its new bitpass
    ))[3]);

    // Update ownership index
    ownershipIndexBpData[0].val.specific.specific.specific.specific.bpKeyIndex[btmlBitpass] = newOwnershipBitpassBtml;

    await getActionExecutor('saveBitpass')!.run(
      JSON.stringify(ownershipIndexBpData),
      process.env.WORKER_SIGN_AUTHOR!,
      process.env.WORKER_SIGN_PRIVKEY!,
      '',
      `${ownershipIndexBtml}`,
    )

    return [newOwnershipBitpassBtml]; // btml ownership-bp
  }
}

const ownershipBpTemplate = [
  {
    _proto: 'ICON',
    title: 'Task',
    val: {
      title: 'Task ejemplo',
      description: 'The Fantastic Guitars Collection',
      aux: 'Javier Arres',
      media: [
        {
          type: 'image',
          url: 'ipfs://QmNjyGQnxnNpXe4yKDAVSfwWKXQKPCtJZBHZbs9RhHzKMr',
        },
      ],
      'pass.json': {
        organizationName: 'Javier Arres',
        logoText: 'Botanic Guitar',
        headerFields: [
          {
            key: 'Detailorg_1631960329054',
            label: 'Javier Arres',
            value: 'Botanic Guitar',
          },
        ],
        primaryFields: [],
        secondaryFields: [],
        auxiliaryFields: [],
        backFields: [],
        foregroundColor: 'rgb(0,0,0)',
        backgroundColor: 'rgb(155,155,155)',
      },
      passImages: {
        'logo.png': 'ipfs://QmfRaFGqcqRAqhfEh2BUt2HNKbryxt5MLuTbwwSRJz78GD',
        'logo@2x.png': '',
        'background.png':
          'ipfs://QmdTexQRAS9tg2k5LLvbjR2MfNgvoLZXCYTAxzu1RznrZp',
        'background@2x.png': '',
        'strip.png': 'ipfs://QmXYTnSESXqHRJhPSs3BmJxPw6pqzZZ1fuZSYkzwvdCuaq',
        'strip@2x.png': '',
        'footer.png': '',
        'footer@2x.png': '',
        'thumbnail.png':
          'ipfs://QmSs2keRyfstQmCPwDKgY6aqKtuJJqHDMAHhjzkqFoiuGP',
        'thumbnail@2x.png': '',
        'icon.png': 'ipfs://QmQVm9NG3p12ygPkMqjsCmiS8wVFRpAK3JxX3fA1913HeB',
        'icon@2x.png': '',
      },
      schemaVersion: 3,
      icon: 'ipfs://QmUR5cZLAHRGa1byCsq4NpjZDbZbLwj8LNtkQUxMNW2FCt',
      summary: {
        title: 'Tarea ejemplo',
        cards: [],
      },
      updated: 1638105514681,
      specific: {
        _proto: 'Resource',
        btmlPart: 1,
        fileName: '',
        specific: {
          _proto: 'Concept',
          btmlPart: 2,
          conceptName: '',
          conceptDescription: '',
          specific: {
            _proto: 'Certificate',
            btmlPart: 1,
            specific: {
              _proto: 'Ownership',
              btmlPart: 3,
              bpBtml: '',
              allowedIdentity: '',
            },
          },
        },
      },
    },
  },
];
