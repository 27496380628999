import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { bitPass: Bitpass<any>, ownerBtml: string, transferRegister: Bitpass<any> }

@Component({
  selector: 'app-property-modal',
  templateUrl: './property-modal.component.html',
  styleUrls: ['./property-modal.component.scss'],
})
export class PropertyModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'bpProperty';

  bitPass: Bitpass<any>;
  registerBook: Bitpass<any>;
  transferRegister: Bitpass<any>;
  ownerBtml: string;

  @Input()
  set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, autoSubmit?: boolean, [other: string]: any }) {
    if (value.formData) {
      this.bitPass = value.formData.bitPass;
      this.ownerBtml = value.formData.ownerBtml;
      this.transferRegister = value.formData.transferRegister;

    } else if (value.bitpass) {
      this.bitPass = value.bitpass;

      // Look for the register book BP
      this.fetchBitpass('5213000000').subscribe((res) => {
        this.registerBook = res;
        if (this.registerBook) {
          var i = 0;
          // Iterate over bpKeyIndex
          Object.keys(this.registerBook.__data[0].val.specific.specific.specific.specific.bpKeyIndex).forEach((element) => {
            // If the current iteration element is equals to the current product btml look for this element/product
            if (element == '' + this.bitPass.__selfBtml) {
              this.fetchBitpass(Object.values(this.registerBook.__data[0].val.specific.specific.specific.specific.bpKeyIndex)[i] + '').subscribe((result) => {
                this.transferRegister = result;
                if (this.transferRegister) {
                  this.ownerBtml = this.transferRegister.__data[0].val.specific.specific.specific.specific.allowedIdentity + '';
                }
              });
            }
            i++;
          });
        }
      });
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() { }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.length > 11) {
      btml = btml.substring(0, 10);
    }
    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps.fetchBitpass(btml, Bitpass).then((bp) => {
        console.warn('URL ' + btml + ' loaded', bp);
        this.bpCache[btml].next(bp.product);
      }).catch((err) => {
        console.error('Cannot fetch minipass for url ' + btml);
      });
    }
    return this.bpCache[btml];
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bitPass: this.bitPass,
      ownerBtml: '',
      transferRegister: this.transferRegister
    };
    dataStore.bitPass = this.bitPass;
    dataStore.ownerBtml = this.ownerBtml;
    dataStore.transferRegister = this.transferRegister;
    this.formDataStore = dataStore;
  }
}
