import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth-module/auth.module').then(m => m.AuthPageModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./pages/public-module/public.module').then(m => m.PublicPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./pages/main-module/main.module').then(m => m.MainPageModule),
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
