import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicGestureConfig } from '../utils/IonicGestureConfig';
import { ApiModule } from './services/api/api.module';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { ApiModule as LnApiModule } from './services/lnapi/api.module';
import { ApiAuthInterceptor } from './services/api-auth.interceptor';
import { ApiLnParseInterceptor } from './services/api-ln-parse.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        ApiModule.forRoot({ rootUrl: 'RUNTIME_VALUE' }),
        LnApiModule.forRoot({ rootUrl: 'INTERCEPT_LN_REQ' }),
        AppRoutingModule,
        HttpClientModule,
        MonacoEditorModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
        { provide: HTTP_INTERCEPTORS, useClass: ApiAuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ApiLnParseInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
