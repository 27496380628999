import { Specific } from "../../../../../base-specific";

export interface ICoin extends Specific {
    chain: string;
    symbol: string;
    decimals: number;
    addressValidation: string;
}

export class Coin extends Specific implements ICoin  {
    readonly _proto?: string = 'Coin';
    readonly btmlPart: number = 1;

    chain: string = null;
    symbol: string = null;
    decimals: number = null;
    addressValidation: string = null;

    constructor(data: ICoin) {
        super(data);

        this.chain = data.chain ?? null;
        this.symbol = data.symbol ?? null;
        this.decimals = data.decimals ?? null;
        this.addressValidation = data.addressValidation ?? null;

        // Implement update logic here
    }
}
