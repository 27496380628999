import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { EditorComponent } from 'ngx-monaco-editor';
import { CoreProvider } from 'src/app/services/core';

@Component({
  selector: 'app-monacoeditor-modal',
  templateUrl: './monacoeditor-modal.component.html',
  styleUrls: ['./monacoeditor-modal.component.scss'],
})
export class MonacoeditorModalComponent implements OnInit {

  @ViewChild('editor')
  editor: any;

  editorOptions = { theme: 'vs-dark', language: 'json' };
  code: any;

  constructor(public core: CoreProvider) { }

  @Input()
  set data(value: {obj: any}) {
    this.code = JSON.stringify(value.obj);
    setTimeout(() => this.formatEditor(), 1000);
  }

  ngOnInit() {
    window.dispatchEvent(new Event('resize'));
    setTimeout(()=> window.dispatchEvent(new Event('resize')), 1000);
  }

  formatEditor() {
    this.editor._editor.getAction('editor.action.formatDocument').run()
  }

  async presentAlertMultipleButtons() {
    const alert = await this.core.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Desea descartar la edición?',
      buttons: [
        {
          text: 'Descartar cambios',
          cssClass: 'other-button',
          id: 'discard-button',
          handler: () => {
            this.dismissModal();
          }
        },
        {
          text: 'Seguir editando',
          role: 'discard',
          cssClass: 'cta-button',
          id: 'continue-button',
          handler: () => {
          }
        },
        {
          text: 'Guardar cambios y cerrar',
          cssClass: 'danger',
          id: 'save-button',
          handler: () => {
            this.dismissSaveModal();
          }
        },
      ]
    });

    await alert.present();
  }

  dismissModal() {
    this.core.modalCtrl.dismiss();
  }

  dismissSaveModal() {
    this.core.modalCtrl.dismiss(JSON.parse(this.code));
  }
}
