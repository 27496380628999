import { Component, Input, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';
const { Share } = Plugins;

export interface IFormData { bpUrl: string }

@Component({
  selector: 'app-bitpass-qr-modal',
  templateUrl: './bitpass-qr-modal.component.html',
  styleUrls: ['./bitpass-qr-modal.component.scss'],
})
export class BitpassQrModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'bitpassQR';

  @Input() set data(value: any) {
    if (value.formData) {
      this.currentUrl = value.formData.bpUrl;
    }
  }

  currentUrl: string;

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() {
    if (!this.currentUrl) {
      this.currentUrl = document.location.href;
    }
  }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public async successToast() {
    await (await this.core.toastCtrl.create({
      message: 'Texto copiado en el portapapeles',
      duration: 2000,
      color: 'success'
    })).present();
  }

  share() {
    Share.share({
      title: '¡Explora este producto en dSequence!',
      text: this.currentUrl,
    });
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bpUrl: ''
    };
    dataStore.bpUrl = this.currentUrl;
    this.formDataStore = dataStore;
  }
}
