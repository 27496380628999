import { PaymentTypes, TokenTypes } from "../pages/main-module/pages/misc-module/components/blockchain-wallets/blockchain-wallets.component";

export type CoinBitpassType = {
    [key in PaymentTypes]: {
        [key in TokenTypes]?: {btml: string, used: boolean}[]
    }
}
// CoinBitpassType not used in order to infer const from definition and get extended language server validation
export const coinBitpass = {
    blockchain: {
        bitcoin: [
            { btml: '1111100001', used: true },
            { btml: '1111100002', used: false }
        ],
        tokens: [
            { btml: '1111100001', used: false },
            { btml: '1111100002', used: true }
        ],
        debts: [
            { btml: '1111100003', used: true },
            { btml: '1111100002', used: false }
        ],
        consumables: [
            { btml: '1111100004', used: true }
        ]
    },

    lightning: {
        bitcoin: [
            { btml: '1111100001', used: true },
            { btml: '1111100002', used: false }
        ],
        tokens: [
            { btml: '1111100001', used: false },
            { btml: '1111100002', used: true }
        ],
        debts: [
            { btml: '1111100003', used: true },
            { btml: '1111100002', used: false }
        ],
        consumables: [
            { btml: '1111100004', used: true }
        ]
    },

    vault: {
        bitcoin: [
            { btml: '1111100001', used: true },
            { btml: '1111100002', used: false }
        ],

        tokens: [
            { btml: '1111100001', used: false },
            { btml: '1111100002', used: true }
        ],

        debts: [
            { btml: '1111100003', used: true },
            { btml: '1111100002', used: false }
        ]
    },
}// as CoinBitpassType;