/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { Layer7Service } from './services/layer-7.service';
import { Layer9Service } from './services/layer-9.service';
import { Layer6Service } from './services/layer-6.service';
import { Layer1Service } from './services/layer-1.service';
import { Layer8Service } from './services/layer-8.service';
import { Layer0Service } from './services/layer-0.service';
import { Layer3Service } from './services/layer-3.service';
import { Layer4Service } from './services/layer-4.service';
import { Layer2Service } from './services/layer-2.service';
import { Layer5Service } from './services/layer-5.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    Layer7Service,
    Layer9Service,
    Layer6Service,
    Layer1Service,
    Layer8Service,
    Layer0Service,
    Layer3Service,
    Layer4Service,
    Layer2Service,
    Layer5Service,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
