import { getActionExecutor } from '../action-catalog';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class FetchBitpassAction extends RunnableAction {
  static readonly identifier = 'fetchBitpass';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const btml = params[0];

    const record = await getActionExecutor('extractRecord')!.run(
      ...(await getActionExecutor('lastRecord')!.run(
        ...(await getActionExecutor('keyLastTen')!.run(btml)),
      )),
    );

    const bitpass = (
      await getActionExecutor('fetchIpfsJson')!.run(
        record[1],
      )
    )[0];

    const bitpassData = (
      await getActionExecutor('fetchIpfsJson')!.run(
        (await getActionExecutor('extractBitpass')!.run(bitpass))[0],
      )
    )[0];

    // Bitpass main object, bitpass data, actual bitpass_cid
    return [bitpass, bitpassData, record[1]];
  }
}
