/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionSpec, RunnableAction } from '../runnable-action';

export class LastRecordAction extends RunnableAction {
  static readonly identifier = 'lastRecord';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const recordsResp = JSON.parse(params[0]);

    let winner: any = null;
    recordsResp.records.forEach((rec: any) => {
      if (rec.timestamp <= recordsResp.timestamp) {
        if (!winner) winner = rec;
        if (winner.timestamp < rec.timestamp) {
          winner = rec;
        }
      }
    });

    return [JSON.stringify(winner)];
  }
}
