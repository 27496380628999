/* eslint-disable @typescript-eslint/interface-name-prefix */
/* eslint-disable @typescript-eslint/no-explicit-any */

//#region Same as interfaces from bitpass-types lib (still not as dependency)
export interface IActionParamDef {
  pattern: string;
  label: string;
  tooltip: string;
}
export interface IActionParams {
  input: IActionParamDef[];
  output: IActionParamDef[];
}
//#endregion

export interface ActionSpec {
  description: string;
  actionParmDef: IActionParams;
}

export abstract class RunnableAction {
  public constructor(private config: any = null) { }
  public static readonly identifier: string;
  public abstract actionSpec: ActionSpec;
  public abstract run(...params: string[]): Promise<string[]>;
}
