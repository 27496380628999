/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AgramAddTask } from '../models/agram-add-task';
import { BuyBpConfirmRequestDto } from '../models/buy-bp-confirm-request-dto';
import { BuyBpRequestDto } from '../models/buy-bp-request-dto';
import { ClaimBpConfirmRequestDto } from '../models/claim-bp-confirm-request-dto';
import { ClaimBpNotificationRequestDto } from '../models/claim-bp-notification-request-dto';
import { ConfirmOwnershipDto } from '../models/confirm-ownership-dto';
import { CreateAppDto } from '../models/create-app-dto';
import { CreateIdentityDto } from '../models/create-identity-dto';
import { ImportTaskResultDto } from '../models/import-task-result-dto';
import { OwnershipCreateDto } from '../models/ownership-create-dto';
import { SoldBpRequestDto } from '../models/sold-bp-request-dto';
import { ValueDto } from '../models/value-dto';
import { WithdrawBpRequestDto } from '../models/withdraw-bp-request-dto';


/**
 * `Program` value exchanges with verifiable asynchronous <b>Worker Bots</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer7Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agramControllerGetWorkerList()` */
  static readonly AgramControllerGetWorkerListPath = '/AGRAM/search/workerList';

  /**
   * List of available workers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWorkerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerGetWorkerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * List of available workers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWorkerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWorkerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.getWorkerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `agramAddWorkerTask()` */
  static readonly AgramAddWorkerTaskPath = '/AGRAM/{worker}/add/task';

  /**
   * Add a new task to the worker.
   *
   * Give a new task to will be made for the worker of the node, if everything is correct
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addWorkerTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWorkerTask$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
  
    /**
     * Add the Btml and your timestamp, with these parameters:
     * - <b>btml</b>: (string) Btml of the BP
     *   - <b>example</b>: 0123456789
     * - <b>timestamp</b>: (number) Unix Timestamp
     *   - <b>example</b>: 1640995200
     */
    body: AgramAddTask
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{

/**
 * This is the BTML of the single BitPass created in this process
 */
'btml_used'?: string;
}>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramAddWorkerTaskPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * This is the BTML of the single BitPass created in this process
         */
        'btml_used'?: string;
        }>;
      })
    );
  }

  /**
   * Add a new task to the worker.
   *
   * Give a new task to will be made for the worker of the node, if everything is correct
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addWorkerTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addWorkerTask(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
  
    /**
     * Add the Btml and your timestamp, with these parameters:
     * - <b>btml</b>: (string) Btml of the BP
     *   - <b>example</b>: 0123456789
     * - <b>timestamp</b>: (number) Unix Timestamp
     *   - <b>example</b>: 1640995200
     */
    body: AgramAddTask
    },
    context?: HttpContext
  ): Observable<{

/**
 * This is the BTML of the single BitPass created in this process
 */
'btml_used'?: string;
}> {
    return this.addWorkerTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * This is the BTML of the single BitPass created in this process
 */
'btml_used'?: string;
}>): {

/**
 * This is the BTML of the single BitPass created in this process
 */
'btml_used'?: string;
} => r.body)
    );
  }

  /** Path part for operation `agramControllerBuyBpConfirm()` */
  static readonly AgramControllerBuyBpConfirmPath = '/AGRAM/{worker}/buy/end';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buyBPConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyBPConfirm$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: BuyBpConfirmRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerBuyBpConfirmPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buyBPConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyBPConfirm(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: BuyBpConfirmRequestDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.buyBPConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerBuyBp()` */
  static readonly AgramControllerBuyBpPath = '/AGRAM/{worker}/buy/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `buyBP()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyBP$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: BuyBpRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerBuyBpPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `buyBP$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  buyBP(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: BuyBpRequestDto
    },
    context?: HttpContext
  ): Observable<string> {
    return this.buyBP$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `agramControllerClaimBpConfirm()` */
  static readonly AgramControllerClaimBpConfirmPath = '/AGRAM/{worker}/claim/confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimBpConfirm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimBpConfirm$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ClaimBpConfirmRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerClaimBpConfirmPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimBpConfirm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimBpConfirm(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ClaimBpConfirmRequestDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.claimBpConfirm$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerClaimBpNotification()` */
  static readonly AgramControllerClaimBpNotificationPath = '/AGRAM/{worker}/claim/notification';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `claimBpNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimBpNotification$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ClaimBpNotificationRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerClaimBpNotificationPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `claimBpNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  claimBpNotification(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ClaimBpNotificationRequestDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.claimBpNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerConfirmOwnership()` */
  static readonly AgramControllerConfirmOwnershipPath = '/AGRAM/{worker}/confirm/ownership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `confirmOwnership()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmOwnership$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ConfirmOwnershipDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerConfirmOwnershipPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `confirmOwnership$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  confirmOwnership(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: ConfirmOwnershipDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.confirmOwnership$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerCreateApp()` */
  static readonly AgramControllerCreateAppPath = '/AGRAM/{worker}/create/app';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApp()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApp$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: CreateAppDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValueDto>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerCreateAppPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValueDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApp$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApp(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: CreateAppDto
    },
    context?: HttpContext
  ): Observable<ValueDto> {
    return this.createApp$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValueDto>): ValueDto => r.body)
    );
  }

  /** Path part for operation `agramControllerCreateIdentity()` */
  static readonly AgramControllerCreateIdentityPath = '/AGRAM/{worker}/create/identity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createIdentity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIdentity$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: CreateIdentityDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValueDto>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerCreateIdentityPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValueDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createIdentity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIdentity(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: CreateIdentityDto
    },
    context?: HttpContext
  ): Observable<ValueDto> {
    return this.createIdentity$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValueDto>): ValueDto => r.body)
    );
  }

  /** Path part for operation `agramCreateImportTask()` */
  static readonly AgramCreateImportTaskPath = '/AGRAM/{worker}/create/task/import';

  /**
   * Create import task.
   *
   * Create import task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createImportTask()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createImportTask$Response(
    params?: {
      body?: Array<{
}>
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ImportTaskResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramCreateImportTaskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImportTaskResultDto>;
      })
    );
  }

  /**
   * Create import task.
   *
   * Create import task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createImportTask$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createImportTask(
    params?: {
      body?: Array<{
}>
    },
    context?: HttpContext
  ): Observable<ImportTaskResultDto> {
    return this.createImportTask$Response(params, context).pipe(
      map((r: StrictHttpResponse<ImportTaskResultDto>): ImportTaskResultDto => r.body)
    );
  }

  /** Path part for operation `agramControllerCreateOwnership()` */
  static readonly AgramControllerCreateOwnershipPath = '/AGRAM/{worker}/give/ownership';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOwnership()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOwnership$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: OwnershipCreateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerCreateOwnershipPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createOwnership$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOwnership(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: OwnershipCreateDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.createOwnership$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerWithdrawBp()` */
  static readonly AgramControllerWithdrawBpPath = '/AGRAM/{worker}/sell/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawBP()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdrawBP$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: WithdrawBpRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerWithdrawBpPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawBP$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  withdrawBP(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: WithdrawBpRequestDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.withdrawBP$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `agramControllerSoldBp()` */
  static readonly AgramControllerSoldBpPath = '/AGRAM/{worker}/sell/start';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `soldBP()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  soldBP$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: SoldBpRequestDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, Layer7Service.AgramControllerSoldBpPath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `soldBP$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  soldBP(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';
      body?: SoldBpRequestDto
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.soldBP$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
