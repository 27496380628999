import { Specific } from "../../../../base-specific";

export interface IOwnership extends Specific {
    bpBtml: string;
    allowedIdentity: string;
}

export class Ownership extends Specific implements IOwnership  {
    readonly _proto?: string = 'Ownership';
    readonly btmlPart: number = 3;

    bpBtml: string = null;
    allowedIdentity: string = null;

    constructor(data: IOwnership) {
        super(data);

        this.bpBtml = data.bpBtml;
        this.allowedIdentity = data.allowedIdentity;

        // Implement update logic here
    }
}
