/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileImpl, WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class SavePkpassAction extends RunnableAction {
  static readonly identifier = 'savePkpass';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };

  async run(...params: string[]): Promise<string[]> {
    const bitpassData = JSON.parse(params[0]);

    const pkpass = await this.fillPkpassFromProduct(bitpassData);
    const signedPkpass = new FileImpl(
      await this.generatePkpass(pkpass),
      'file.pkpass',
    );
    const resp = await requests.ipfsPinFile(signedPkpass);

    if (!resp.ok)
      throw new Error(
        'Error pin pkpass from product data. Error: ' + JSON.stringify(resp),
      );

    return [(await resp.json()).cid];
  }

  private async fillPkpassFromProduct(product: any): Promise<FileImpl[]> {
    const files: FileImpl[] = [];
    const pkpassJson = {
      formatVersion: 1,
      organizationName: 'Detailorg Consulting', // TODO: Try to change this and check sign
      logoText: product[0].val.title,
      description: 'NFT',
      foregroundColor: product[0].val['pass.json'].foregroundColor,
      backgroundColor: product[0].val['pass.json'].backgroundColor,
      generic: {
        headerFields: product[0].val['pass.json'].headerFields,
        primaryFields: product[0].val['pass.json'].primaryFields,
        secondaryFields: product[0].val['pass.json'].secondaryFields,
        auxiliaryFields: product[0].val['pass.json'].auxiliaryFields,
        backFields: [
          {
            key: 'title_back',
            label: 'Instrucciones de Verificación',
            value: ' ',
          },
          {
            key: 'address_btc',
            label: 'Inmutabilización en Bitcoin',
            value:
              'Esta es la dirección de Bitcoin que inmutabiliza el producto: ' +
              process.env.BASE_URL +
              ' puedes verla a través de un explorador público como: https://blockstream.info/testnet/address/' +
              process.env.BASE_URL,
          },
          {
            key: 'sidechain_block',
            label: 'Bloque de la Sidechain',
            value:
              'Ve a la transacción del dia ' +
              new Date().toDateString() +
              ' y encontrarás CID del Bloque de cadena lateral, puedes visualizarlo en un explorador de IPFS como: https://ipfs.io/ipfs/',
          },
          {
            key: 'nft_cid',
            label: 'CID del NFT',
            value: 'Dentro encontrarás el dato inmutabilizado',
          },
          {
            key: 'dSequence_url',
            label: 'dSequence Explorer',
            value:
              'Para una visualización completa usa este link: https://www.dsequence.agramproject.com/product/' +
              'TODO',
          },
        ],
      },
    };
    files.push(this.fileFromJson(pkpassJson, 'pass'));

    for (const k in product[0].val.passImages) {
      try {
        const itm = product[0].val.passImages[k].split('ipfs://')[1];

        if (!itm) throw new Error('Not ipfs image');
        const resource = await requests.ipfsGet(itm);
        if (!resource.ok) throw new Error('Cannot fetch resource');
        files.push(new FileImpl(await resource.buffer(), k));
      } catch (_err) {
        console.error('Error loading image', k);
      }
    }

    return files;
  }

  private generatePkpass(files: FileImpl[]): Promise<Buffer> {
    // console.warn(files);
    return new Promise((cbOk, cbKo) => {
      requests
        .pkpassSign(files)
        .then(async (resource) => {
          if (resource.ok) {
            cbOk(await resource.buffer());
          } else {
            cbKo('Error signing pkpass');
          }
        })
        .catch((err) => cbKo(err));
    });
  }

  private fileFromJson(json: object, name?: string) {
    return new FileImpl(
      // [new Uint8Array([0xEF, 0xBB, 0xBF]), JSON.stringify(json)],
      Buffer.from(JSON.stringify(json)),
      (name ?? Date.now()) + '.json',
      // { type: "application/json" }
    );
  }
}
