import { BitpassData } from "./bitpass-data";
import { Application } from "./specific/application/application";
import { ParentSpecific, Specific } from "./specific/base-specific";
import { Index, IndexActions, IndexConceptCertificateOwnership } from "./specific/index";
import { Actions } from "./specific/index/actions/actions";
import { Certificate as IndexCertificate } from "./specific/index/concept/certificate/certificate";
import { Ownership as IndexOwnership } from "./specific/index/concept/certificate/ownership/ownership";
import { Concept as IndexConcept } from "./specific/index/concept/concept";
import { Certificate } from "./specific/resource/concept/certificate/certificate";
import { Ownership } from "./specific/resource/concept/certificate/ownership/ownership";
import { Concept } from "./specific/resource/concept/concept";
import { Identity } from "./specific/resource/identity/identity";
import { Person } from "./specific/resource/identity/person/person";
import { Financial } from "./specific/resource/product/financial/financial";
import { Coin } from "./specific/resource/product/financial/monetary/coin/coin";
import { Monetary } from "./specific/resource/product/financial/monetary/monetary";
import { Product } from "./specific/resource/product/product";
import { Resource, ResourceConceptCertificateOwnership, ResourceIdentity, ResourceIdentityPerson, ResourceProduct } from "./specific/resource/resource";

interface IConstructor<T> {
    new (...args: any[]): T;

    // Or enforce default constructor
    // new (): T;
}


export interface IBitpass<S extends ParentSpecific<S>|Specific> {
    pkpass_cid: string;
    data_cid: string;
    author: string;
    signature: string;
    cyphered: boolean;
    nodeInfo: BitpassNodeInfo;

    __data?: BitpassData<S>[];
    __signatureValidation?: boolean|'partial';
    __selfCid?: string;
    __selfBtml?: string;
}

export class BitpassNodeInfo {
    // clusterUrl: string;
    blockchain: string;
    address: string;
}

export class Bitpass<S extends ParentSpecific<S>|Specific> implements IBitpass<S> {
    pkpass_cid: string = '';
    data_cid: string = '';
    author: string = '';
    signature: string = '';
    cyphered: boolean = false;
    nodeInfo: BitpassNodeInfo;

    __data?: BitpassData<S>[];
    __signatureValidation?: boolean|'partial';
    __selfCid?: string;
    __selfBtml?: string;

    constructor(data: IBitpass<S>, sCtor: IConstructor<S>) {
        this.pkpass_cid = data.pkpass_cid;
        this.data_cid = data.data_cid;
        this.author = data.author;
        this.signature = data.signature;
        this.cyphered = data.cyphered;
        this.nodeInfo = data.nodeInfo;
        this.__signatureValidation = data.__signatureValidation;
        this.__selfCid = data.__selfCid;
        this.__selfBtml = data.__selfBtml;
        this.__data = data.__data.map(itm => new BitpassData(itm, sCtor));
    }
}

export class GenericBitpass extends Bitpass<any> {
    constructor(data: IBitpass<any>) {
        super(data, null);
    }
};
export class BitpassResourceProduct extends Bitpass<Resource<Product<any>>> {
    constructor(data: IBitpass<Resource<Product<any>>>) {
        super(data, ResourceProduct);
    }
};
export class BitpassResourceProductFinancialMonetaryCoin extends Bitpass<Resource<Product<Financial<Monetary<Coin>>>>> {
    constructor(data: IBitpass<Resource<Product<Financial<Monetary<Coin>>>>>) {
        super(data, ResourceProduct);
    }
};
export class BitpassIndexActions extends Bitpass<Index<Actions>> {
    constructor(data: IBitpass<Index<Actions>>) {
        super(data, IndexActions);
    }
};
export class BitpassResourceIdentity extends Bitpass<Resource<Identity<any>>> {
    constructor(data: IBitpass<Resource<Identity<any>>>) {
        super(data, ResourceIdentity);
    }
};
export class BitpassResourceIdentityPerson extends Bitpass<Resource<Identity<Person>>> {
    constructor(data: IBitpass<Resource<Identity<Person>>>) {
        super(data, ResourceIdentityPerson);
    }
};
export class BitpassResourceConceptCertificateOwnership extends Bitpass<Resource<Concept<Certificate<Ownership>>>> {
    constructor(data: IBitpass<Resource<Concept<Certificate<Ownership>>>>) {
        super(data, ResourceConceptCertificateOwnership);
    }
};
export class BitpassIndexConceptCertificateOwnership extends Bitpass<Index<IndexConcept<IndexCertificate<IndexOwnership>>>> {
    constructor(data: IBitpass<Index<IndexConcept<IndexCertificate<IndexOwnership>>>>) {
        super(data, IndexConceptCertificateOwnership);
    }
};
export class BitpassApplication extends Bitpass<Application> {
    constructor(data: IBitpass<Application>) {
        super(data, Application);
    }
};
