import { Injectable, ApplicationRef, SecurityContext } from '@angular/core';
import { ToastController, AlertController, LoadingController, ModalController, Platform, IonInput, NavController, PopoverController, MenuController } from '@ionic/angular';
import { ConsoleServiceProvider } from './console-service';
import { first } from 'rxjs/internal/operators/first';
import { environment } from 'src/environments/environment';
import { DataServiceProvider } from './data-service';
import { AuthServiceProvider } from './auth-service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AddImageComponentPage } from '../components/addimagecomponent/addimagecomponent';
import Compressor from 'compressorjs';

import { AppGlobalServiceProvider } from './app-global-service';
import { BitpassServiceProvider } from './bitpass-service';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { ProductPage } from '../pages/main-module/pages/product/product.page';
import { ActionsServiceProvider } from './actions-service';
import { IModalFactory, WorkerContext } from '../lib/agram-worker/worker-context';
import { ModalMap } from './modal-map';
import { PersonalizationServiceProvider } from './personalization-service';
import { ResourceProvider } from './resource-service';
import { coinBitpass } from './coin-bitpass';
import { ElectronService } from './electron.service';
import { VersionServiceProvider } from './version-service';

export type AHDeviceOptions = 'ios' | 'android' | 'pwa' | 'mac' | 'windows';

@Injectable({ providedIn: 'root' })
export class CoreProvider implements IModalFactory {
  public theme: 'dark'|'light' = 'dark';

  public get relayEndpoint() {
    return environment.current_relay;
  }
  public set relayEndpoint(value) {
    environment.current_relay = value;
    localStorage.setItem('current_relay', value);
  }
  public get browserId() {
    return environment.browser_id;
  }
  public set browserId(value) {
    environment.browser_id = value;
  }
  // public browserId = '';

  public global = environment;
  public stable = false;
  public appIsStable = this.appRef.isStable.pipe(first(isStable => {
    if (isStable === true) {
      this.stable = true;
      return true;
    } else {
      return false;
    }
  }));
  public device: AHDeviceOptions = 'pwa';

  constructor(
    private _loading: LoadingController,
    // public http: HttpClient,
    public readonly console: ConsoleServiceProvider,
    public readonly toastCtrl: ToastController,
    public readonly alertCtrl: AlertController,
    public readonly modalCtrl: ModalController,
    public readonly popoverCtrl: PopoverController,
    public readonly navCtrl: NavController,
    public readonly menuCtrl: MenuController,
    public readonly data: DataServiceProvider,
    public readonly resource: ResourceProvider,
    public readonly bps: BitpassServiceProvider,
    public readonly actions: ActionsServiceProvider,
    public readonly api: ApiService,
    public readonly auth: AuthServiceProvider,
    public readonly electron: ElectronService,
    public readonly version: VersionServiceProvider,
    public readonly personalization: PersonalizationServiceProvider,
    public readonly platform: Platform,
    public readonly appConfig: AppGlobalServiceProvider,
    public readonly sanitizer: DomSanitizer,
    private appRef: ApplicationRef,
  ) {
    // // Cargar app config desde local storage
    // if (!localStorage.getItem("appConfigJson")) {
    //   this.appConfigJson.style.color_CTA = document.documentElement.style.getPropertyValue('--ion-color-primary');
    //   localStorage.setItem("appConfigJson", JSON.stringify(this.appConfigJson));
    // }

    const current_relay = localStorage.getItem('current_relay');
    if (current_relay) {
      environment.current_relay = current_relay;
    }

    this.appRef.isStable.subscribe(stable => {
      this.console.debug('App state changed to: '+(stable?'stable':'unstable'));
    });

    let ahDevice: AHDeviceOptions = this.platform.platforms().includes('ios')
      ? 'ios'
      : this.platform.platforms().includes('android')
      ? 'android'
      : 'pwa';
    if (this.platform.is('mobileweb')) ahDevice = 'pwa';
    this.device = ahDevice;
    if (this.device == 'pwa' && this.electron.isElectron) {
      if (this.electron.platform == 'darwin') this.device = 'mac';
      if (this.electron.platform == 'win32') this.device = 'windows';
    }

    const browserID = localStorage.getItem('browserId');
    if (browserID) {
      this.browserId = browserID;
    } else {
      this.browserId = this.createUUID();
      localStorage.setItem('browserId', this.browserId);
    }

    this.version.initCore(this);
    this.data.initCore(this);
    this.personalization.initCore(this);
    this.auth.initCore(this);
    this.resource.initCore(this);
    this.bps.initCore(this);
    this.actions.initCore(this);

    WorkerContext.instance().setRequestOverride(data.workerRequests);
    WorkerContext.instance().setModalFactory(this);
  }

  signablePlainObject = (signableObjRef) => this.bps.signablePlainObject(signableObjRef);

  public async createLoading(text=null, noPresent=false) {
    let loading = await this._loading.create({message: text});
    if (!noPresent) await loading.present();
    return loading;
  }

  public async successToast(loading=null, message=null) {
    if (loading) loading.dismiss();
    await (await this.toastCtrl.create({
      message: message||'Se ha completado la acción correctamente',
      duration: 3000,
      color: 'success'
    })).present();
  }

  public async errorToast(loading=null, message=null) {
    if (loading) loading.dismiss();
    await (await this.toastCtrl.create({
      message: message||'Se ha producido un error, inténtelo de nuevo más tarde',
      duration: 5000,
      color: 'danger'
    })).present();
  }

  public getDateFromTimestamp(date: string) {
    let t = date.split(/[- :]/);
    return new Date(Date.UTC(parseInt(t[0]), parseInt(t[1])-1, parseInt(t[2]), parseInt(t[3]), parseInt(t[4]), parseInt(t[5])));
  }

  public getIsoTime(date=null): string {
    const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(((date) ? date:Date.now()) - tzoffset)).toISOString().slice(0, -1);
  }

  public parseIsoTime(input: string): Date {
    let d = new Date(input.replace('Z', '')+'Z');
    d.setHours(d.getHours()+(d.getTimezoneOffset()/60));
    return d;
  }

  public copyToClipboard(e, selector = 'ion-input input') {
    e.target.parentNode.querySelector(selector).select();
    document.execCommand('copy');
  }

  public copyTextAreaToClipboard(e, selector = 'ion-textarea textarea') {
    e.target.parentNode.querySelector(selector).select();
    document.execCommand('copy');
  }


  public copyStrToClipboard(value) {
    navigator.clipboard.writeText(value);
  }

  // --

  public doReorder<T>(ev, items: T[]) {
    ev.detail.complete(items);
  }

  public deleteMe(needle: any, haystack: any[]) {
    const index = haystack.indexOf(needle);
    if (index > -1) haystack.splice(index, 1);
  }

  public async loadResourceCb(file: File, cb:Function, options:any={}) {
    const opts = {
      errorValue: options.errorValue||'/assets/404.png',
      fixedSize: options.fixedSize||null,
      compressMultiple: options.compressMultiple||null,
    };
    console.warn('Resource files', file);
    // const file = event.target.children[0].files[0];

    // const handleUpload = (newFile) => {
    //   return new Promise(done => {
    //     this.data.requests.putIpfs(newFile).subscribe((res:any) => {
    //       done('ipfs://'+res.cid);
    //     }, _ => {
    //       done(opts.errorValue);
    //     });
    //   });
    // }
    const handleUpload = (newFile) => {
      return new Promise((resolve, reject) => {
        this.data.requests.putIpfs(newFile).subscribe(
          (res: any) => {
            resolve("ipfs://" + res.cid);
          },
          (error) => {
            reject(error);
          }
        );
      });
    };

    if (file.type.startsWith('image')) {
      const dat = {
        initialFiles: [file],
        fixedSize: opts.fixedSize,
      };
      console.log(dat)
      this.modalCtrl.create({
        component: AddImageComponentPage,
        backdropDismiss: false,
        mode: 'ios',
        componentProps: dat,
      }).then(modal => {
        modal.onDidDismiss().then(async data => {
          if (data.data && data.data.length) {
            // console.log('Cropped image', data.data);
            if (opts.fixedSize) {
              if (!opts.compressMultiple) {
                this.compressImage(
                  data.data[0],
                  opts.fixedSize.width,
                  opts.fixedSize.height
                ).then(async file => {
                  // console.log('Compressed image', file);
                  cb?.(await handleUpload(file));
                });
              } else {
                cb?.(await Promise.all(opts.compressMultiple.map(fixedSize => new Promise(done => {
                  this.compressImage(
                    data.data[0],
                    fixedSize.width,
                    fixedSize.height
                  ).then(file => {
                    // console.log('Compressed image', file);
                    done(handleUpload(file));
                  })
                }))))
              }
            } else {
              cb?.(await handleUpload(data.data[0]));
            }
          }
        });
        modal.present();
      });
    } else {
      cb?.(await handleUpload(file));
    }
  }

  public loadResource<T>(event, val:T, options:any={}, prop: keyof T = 'img' as keyof T, cbOk: Function = null) {
    console.warn('loading Resource');
    console.log(val);
    val[prop] = null; // val.icon

    this.loadResourceCb(event.target.children[0].files[0], (url) => {
      val[prop] = url; // Ipfs:// -- Objeto.
      if (cbOk) cbOk(url);
    }, options);
    // const reader  = new FileReader();
    // reader.onloadend = () => val.img = reader.result;
    // reader.readAsDataURL(file);
  }

  public openIonInputFile(fInput: IonInput) {
    fInput.getInputElement().then(el => el.click());
  }

  createUUID() {
    return '4bf96c8c-45ea-4b31-bb72-9afffbb8b7a4';
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
       var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
       return v.toString(16);
    });
  }

  fileFromString(string: string, name?) {
    return new File(
      [string],
      (name ?? Date.now())+".json",
      {type: "application/json"}
    );
  }

  fileFromJson(json, name?) {
    return new File(
      // [new Uint8Array([0xEF, 0xBB, 0xBF]), JSON.stringify(json)],
      [JSON.stringify(json)],
      (name ?? Date.now())+".json",
      {type: "application/json"}
    );
  }

  compressImage(blob: File|Blob, width:number, height:number) {
    return new Promise((ok, ko) => {
      new Compressor(blob, {
        quality: .8,
        convertSize: 10000000,
        width, height,
        maxWidth: width, maxHeight: height,
        resize: 'contain',
        mimeType: 'image/png',
        success: (result) => {
          // console.log('result', width, height, result);
          ok(result);
        },
        error: err => {
          console.error(err);
          ko(err);
        },
      });
    })
  }
  // ---

  handleLink(url: string, productCtx?: ProductPage) {

    if (url.startsWith('app://bp/')) {
      this.navCtrl.navigateForward('/product'+url.replace('app://bp/','/'));
    } else if (url.startsWith('app://form/')) {
      if (productCtx) {
        const form = url.replace('app://form/', '').split('?');
        productCtx.openModal(form[0], form[1]?.split('&').reduce((acc,p) => ({...acc, [p.split('=')[0]]: p.split('=')[1] ?? null }), {}))
      } else {
        console.error('Trying to open form without product context');
      }
    } else {
      this.openExternalLink(url);
    }
  }

  openExternalLink(url) {
    window.open(url, '_blank').focus();
  }

  openModal(component: string | object, params: object): Promise<any> {
    return new Promise((ok, ko) => {
      if (typeof component == 'string') {
        component = ModalMap[component];
        if (!component) {
          console.error('Invalid component', component);
          ko({msg: 'Invalid component', component});
        }
      }

      this.modalCtrl.create({
        component: component as any,
        cssClass: 'fullscreenModal',
        componentProps: { data: params },
        backdropDismiss: false,
      }).then(modal => {
        modal.onDidDismiss().then(ok).catch(ko);
        modal.present();
      }).catch(ko);
    });
  }

  toggleTheme(appIndex: number) {
    this.theme = this.theme === 'light' ? 'dark' : 'light';
    this.auth.userStorage.apps.list[appIndex].themeCustomization.content.theme = this.theme;
    this.auth.saveUserStorage();
    console.log('Changed theme to: ' + this.theme);
  }

  getUserDID(): any|null {
    const localLogins = JSON.parse(localStorage.getItem('loginuser'));
    if (localLogins) for (const element of localLogins.logins) {
      if (element.email == this.auth.user.email && element.relayUrl == this.relayEndpoint) {
        console.log('Getting userDID:', element);
        return element;
      }
    };
    return null;
  }

  /**
   * This method returns a clear array of used BTMLs from coinBitpass object
   */
  getCoinList() {
    var coinList = [];

    Object.entries(coinBitpass).forEach((key) => {
      Object.entries(key[1]).forEach((key) => {
        if (key[0] != 'btml') {
          Object.entries(key[1]).forEach((key: any) => {
            if (key[1].used && !coinList.includes(key[1].btml)) {
              coinList.push(key[1].btml);
            }
          })
        } else {
          if (key[0] && !coinList.includes(key[1])) {
            coinList.push(key[1]);
          }
        }
      })
    })
    return coinList;
  }

  async sha256Hash(message: string) {
    const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
        .map((b) => b.toString(16).padStart(2, "0"))
        .join(""); // convert bytes to hex string
    return hashHex;
  }
}
