import { BtmlMap, ParentSpecific } from "../../../../base-specific";
import { Coin } from "./coin/coin";


export type MonetaryTypes = Coin;
export const MonetaryBtmlMap: BtmlMap = {
    1: Coin,
}

export interface IMonetary<T extends MonetaryTypes> extends ParentSpecific<T> {
}

export class Monetary<T extends MonetaryTypes> extends ParentSpecific<T> implements IMonetary<T> {
    readonly _proto?: string = 'Monetary';
    readonly btmlPart: number = 1;

    constructor(data: IMonetary<T>) {
        super(data, MonetaryBtmlMap);

        // Implement update logic here
    }

}
