import { TTranslations } from '../lib/bitpass-types/models/specific/application/application';

export const DefaultTranslations: TTranslations = {
  es: {
    title: 'Título',
    description: 'Ejempo de descripción',
    tooltip_description: 'Descripción para la portada',
    aux: 'Ejemplo de auxiliar',
    tooltip_aux: 'Texto auxiliar para la portada',
    tooltip_favicon: 'svg del icono',
    tooltip_url_title: 'Título para la portada',
    tooltip_footer: 'IMG SVG que aparece abajo del todo en la parte de exploración del BP',
    tooltip_spinner: 'gif de loading',
    tooltip_background: 'svg o PNG en buena resolución',
    tooltip_background_thumbnail: 'img en baja resolución del background',
    start_btn: 'Entrar',
    start_visitor: 'Entrar como visitante',
    agram_DID_title: 'dSequence',
    agram_did_description: 'Ejemplo de descripción',

    edit_btn: 'Editar',
    save_btn: 'Guardar',

    title_product: '',
    tooltip_product_title: '',
    explorer: '',

    // Identidy page
    title_identity: 'Identidades',
    primary_identity: 'Par de claves PGP',
    bitpass_identity: 'BP de identidad Pública',

    // Menú lateral de navegación
    sign_in: 'Iniciar sesión',
    title_wallet: 'Carteras',
    title_criteria: 'Criterios',
    title_chat: 'Conversaciones',
    title_actions: 'Acciones',
    title_identities: 'Identidades',
    title_record: 'Historial',
    title_planned: 'Planificado',
    title_statistics: 'Estadísticas',
    title_applications: 'Aplicaciones',
    title_configuration: 'Configuración',

    // Menú lateral de búsqueda
    title_node_lookup: 'Búsqueda en el nodo',
    title_BP_resources_advertising: 'Publicidad',
    title_BP_resources_products: 'Productos',
    title_BP_resources_certificates: 'Certificados',
    title_BP_resources_contacts: 'Contactos',
    title_BP_resources_actions: 'Acciones',
    title_BP_resources_verifications: 'Verificaciones',
    title_BP_resources_regards: 'Recuerdos',
    title_BP_resources_planned: 'Planificado',
    title_BP_resources_analysis: 'Análisis',
    title_BP_resources_applications: 'Aplicaciones',

    // Formularios
    see: 'ver',
    product_visited_bp: 'productos visitados en BitPass',
    product_fullscreen: 'producto con pantalla completa',
    product_qr_code: 'código QR del producto',
    product_pk_pass: 'pase de llaves del producto',
    product_3d: 'visualización en 3D del producto',
    product_create: 'creación de producto',
    product_edit_actual: 'edición del producto actual',
    product_edit_json: 'edición del producto en formato JSON',
    product_edit_save: 'guardado de la edición del producto',
    product_edit_cancel: 'cancelar la edición del producto',
    product_edit_registry: 'registro de edición del producto',
    product_edit_form: 'formulario de producto',
    product_edit_new: 'creación de nuevo producto',
    compilation: 'compilación',
    product_version_app: 'versión de la aplicación del producto',
    create_identity: 'creación de identidad',
    product_condition: 'condición del producto',
    sale: 'venta',
    buy: 'compra',
    pay: 'pago',
    receive: 'recepción',
    exchange: 'intercambio',
    product_chat: 'chat del producto',
    run: 'ejecución',
    add_to_cart: 'añadir al carrito',
    confirm_buy: 'confirmar compra',
    cancell_sale: 'cancelar venta',
    verify: 'verificar',
    subparts: 'subpartes',
    property: 'propiedad',
    product_history: 'historial del producto',
    pending_tasks: 'tareas pendientes',
    save: 'guardar',
    cart: 'carrito',
    download: 'descarga',
    fav: 'favoritos',
    plan: 'plan',
    program: 'programa',
    task: 'tarea',
    value: 'valor',
    rate: 'tasa',
    guide: 'guía',

    // Modales
    editBp: 'editBp',
    visitedBp: 'visitedBp',
    fullscreen: 'fullscreen',
    bitpassQR: 'bitpassQR',
    pkpassViewer: 'pkpassViewer',
    bpEditor: 'bpEditor',
    createIdentity: 'createIdentity',
    bpSubparts: 'bpSubparts',
    bpProperty: 'bpProperty',
    bpHistory: 'bpHistory',
    bitpassClaimConfirm: 'bitpassClaimConfirm',
    bitpassClaimNotification: 'bitpassClaimNotification'
  },
  en: {
    title: 'Title',
    description: 'Description example',
    tooltip_description: 'Cover description',
    aux: 'Ejemplo de auxiliar',
    tooltip_aux: 'Auxiliary text for the cover',
    tooltip_favicon: 'icon svg',
    tooltip_url_title: 'Cover title which appears at the bottom of the exploration place of BP',
    tooltip_footer: 'IMG SVG whichP',
    tooltip_spinner: 'Loading gif',
    tooltip_background: 'Great resolution svg or PNG',
    tooltip_background_thumbnail: 'Low resolution image for background',
    start_btn: 'Get in',
    start_visitor: 'Get in as guest',
    agram_DID_title: 'dSequence',
    agram_did_description: 'Description example',

    edit_btn: 'Edit',
    save_btn: 'Save',

    title_product: '',
    tooltip_product_title: '',
    explorer: '',

    // Identidy page
    title_identity: 'Identities',
    primary_identity: 'PGP key pair',
    bitpass_identity: 'Identity bitpass',

    // Menú lateral de navegación
    sign_in: 'Sign in',
    title_wallet: 'Wallets',
    title_criteria: 'Criteria',
    title_chat: 'Conversations',
    title_actions: 'Actions',
    title_identities: 'Identities',
    title_record: 'Records',
    title_planned: 'Planned',
    title_statistics: 'Statistics',
    title_applications: 'Apps',
    title_configuration: 'Configuration',

    // Menú lateral de búsqueda
    title_node_lookup: 'Node lookup',
    title_BP_resources_advertising: 'Advertising',
    title_BP_resources_products: 'Products',
    title_BP_resources_certificates: 'Certificates',
    title_BP_resources_contacts: 'Contacts',
    title_BP_resources_actions: 'Actions',
    title_BP_resources_verifications: 'Verifications',
    title_BP_resources_regards: 'Regards',
    title_BP_resources_planned: 'Planned',
    title_BP_resources_analysis: 'Analysis',
    title_BP_resources_applications: 'Applications',

    // Formularios
    see: 'see',
    product_visited_bp: 'visited products in bitpass',
    product_fullscreen: 'product with full screen',
    product_qr_code: 'product with QR code',
    product_pk_pass: 'product with PK Pass',
    product_3d: '3D product',
    product_create: 'product creation',
    product_edit_actual: 'edit actual product',
    product_edit_json: 'edit product in JSON',
    product_edit_save: 'save edited product',
    product_edit_cancel: 'cancel edited producto',
    product_edit_registry: 'edit product registry',
    product_edit_new: 'edit new product',
    product_edit_form: 'edit new form',
    compilation: 'compilation',
    product_version_app: 'product version for app',
    create_identity: 'create identity',
    product_condition: 'product condition',
    sale: 'sale',
    buy: 'buy',
    pay: 'pay',
    receive: 'receive',
    exchange: 'exchange',
    product_chat: 'product chat',
    run: 'run',
    add_to_cart: 'add to cart',
    confirm_buy: 'confirm buy',
    cancell_sale: 'cancel sale',
    verify: 'verify',
    subparts: 'subparts',
    property: 'property',
    product_history: 'product history',
    pending_tasks: 'pending tasks',
    save: 'save',
    cart: 'cart',
    download: 'download',
    fav: 'favorites',
    plan: 'plan',
    program: 'program',
    task: 'task',
    value: 'value',
    rate: 'rate',
    guide: 'guide',

    // Modales
    editBp: 'editBp',
    visitedBp: 'visitedBp',
    fullscreen: 'fullscreen',
    bitpassQR: 'bitpassQR',
    pkpassViewer: 'pkpassViewer',
    bpEditor: 'bpEditor',
    createIdentity: 'createIdentity',
    bpSubparts: 'bpSubparts',
    bpProperty: 'bpProperty',
    bpHistory: 'bpHistory',
    bitpassClaimConfirm: 'bitpassClaimConfirm',
    bitpassClaimNotification: 'bitpassClaimNotification'
  },
};
