export interface BtmlMap {
    [k: number]: typeof Specific,
}

export interface ISpecific {
    readonly _proto?: string;
    readonly btmlPart: number;
}
export abstract class Specific implements ISpecific {
    abstract readonly _proto?: string;
    abstract readonly btmlPart: number;
    constructor(data: ISpecific) {
        // There is nothing to map at parent
    }
}
export abstract class ParentSpecific<S extends ParentSpecific<S> | Specific | unknown> extends Specific implements ISpecific {
    abstract readonly _proto?: string;
    abstract readonly btmlPart: number;
    specific: S;
    constructor(data: ParentSpecific<S> | Specific, btmlMap: BtmlMap) {
        super(data);
        // We are sure that childSpecific is a Specific class child instance
        if ('specific' in data && data.specific) {
            if (btmlMap[(data.specific as any).btmlPart]) {
                this.specific = new (btmlMap[(data.specific as any).btmlPart] as any)(data.specific);
            } else {
                console.error('EE', btmlMap, data, (data.specific as any).btmlPart)
                // throw new Error('No specific constructor for btmlPart ' + (data.specific as any).btmlPart);

                this.specific = null as unknown as S;
            }
        }
    }
}

