import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { bag: any, bagTitles: Bitpass<any>[], bitPass: Bitpass<any> }

@Component({
  selector: 'app-subparts-modal',
  templateUrl: './subparts-modal.component.html',
  styleUrls: ['./subparts-modal.component.scss'],
})
export class SubpartsModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'bpSubparts';

  bitPass: Bitpass<any>;

  bag = [[], [], [], [], [], [], [], [], [], []];
  bagTitles: Bitpass<any>[] = [];
  bagBitpass: string[] = [];

  @Input('ngModel')
  set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, autoSubmit?: boolean, [other: string]: any }) {
    if (value.formData) {
      this.bitPass = value.formData.bitPass;
      this.bag = value.formData.bag;
      this.bagTitles = value.formData.bagTitles;
    } else if (value.bitpass) {
      this.bitPass = value.bitpass;

      this.bitPass.__data[0].val.summary.cards.forEach((element) => {
        if (element.cardElements) {
          element.cardElements.forEach((element) => {
            if (element.btmlCard && element.btmlCard.btml && element.btmlCard.btml.startsWith('app://bp/')) {
              let btml = element.btmlCard.btml.slice(9);

              for (let i = 0; i < 10; i++) {
                if (btml.startsWith('' + i) && !this.bag[i].includes(btml)) {
                  this.bag[i].push(btml);
                }
              }
            }

          });
        }
      });
    }

    for (let i = 0; i < 10; i++) {
      if (this.bag[i].length) {
        this.fetchBitpass(this.bag[i][0]).subscribe((res) => {
          if (res) {
            // console.log(res.__selfBtml)
            this.bagBitpass[i] = res.__selfBtml;
            this.bagTitles[i] = res.__data[0].val.specific._proto;
          }
        });
      }
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() { }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps.fetchBitpass(btml, Bitpass).then((bp) => {
        this.bpCache[btml].next(bp.product);
      }).catch((err) => {
        console.error('Cannot fetch minipass for url ' + btml);
      });
    }
    return this.bpCache[btml];
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bag: this.bag,
      bagTitles: this.bagTitles,
      bitPass: this.bitPass
    };
    dataStore.bag = this.bag;
    dataStore.bagTitles = this.bagTitles;
    dataStore.bitPass = this.bitPass;
    this.formDataStore = dataStore;
  }
}
