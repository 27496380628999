import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({ providedIn: 'root' })
export class ConsoleServiceProvider {

  previusString = '_DeTailDebug: ';

  debug(msg, ...objects) {
    if (!environment.production) {
      if (objects == null) {
        console.log(this.previusString + msg);
      } else  {
        console.log(this.previusString + msg, objects);
      }
    }
  }

  log(msg, ...objects) {
    if (objects == null) {
      console.log(this.previusString + msg);
    } else {
      console.log(this.previusString + msg, objects);
    }
  }

}
