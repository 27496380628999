/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class PinIpfsJsonAction extends RunnableAction {
  static readonly identifier = 'pinIpfsJson';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const dataObject = JSON.parse(params[0]);

    const resp = await requests.ipfsPin(dataObject);
    if (!resp.ok)
      throw new Error(
        'Error pin IpfsJson ' +
        '. Status: ' +
        resp.status +
        'Error: ' +
        await resp.text(),
      );

    return [(await resp.json()).cid];
  }
}
