import { Specific } from "../../../../base-specific";

export interface IOwnership extends Specific {
    bpKeyIndex: {[key:string]: string};
}

export class Ownership extends Specific implements IOwnership  {
    readonly _proto?: string = 'Ownership';
    readonly btmlPart: number = 3;

    bpKeyIndex: {[key:string]: string} = null;

    constructor(data: IOwnership) {
        super(data);

        this.bpKeyIndex = data.bpKeyIndex;
        // Implement update logic here
    }
}
