import { Specific } from "../../base-specific";

export interface IParams extends Specific {
    input: string[];
    output: string[];
}
export interface IProcess extends Specific {
    label: string;
    actionSpec: string;
    params: IParams;
}
export interface IConfiguration extends Specific {
    label: string;
    process: IProcess[];
}

export interface IActions extends Specific {
    configurations: IConfiguration[];
}

export class Actions extends Specific implements IActions  {
    readonly _proto?: string = 'Actions';
    readonly btmlPart: number = 4;

    configurations: IConfiguration[] = [];

    constructor(data: IActions) {
        super(data);

        this.configurations = data.configurations;

        // Implement update logic here
    }
}
