import { CoreProvider } from 'src/app/services/core';
import { Injectable, EventEmitter } from '@angular/core';
import { UserStorageModels as USM } from './user-storage-models';
import { environment } from 'src/environments/environment';
import { LoginUser, NewUser } from './api/models';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthServiceProvider {

  core: CoreProvider = null;

  chainList: {[kind: string]: {
    chain: string;
    label: string;
    description: string;
    block_timing: string;
    inmutabilization_address: string;
    status_node: boolean,
    is_sync: boolean,
  }[]};
  user: any = null;
  get inited() {return this.user!==null;};
  onUser: EventEmitter<any> = new EventEmitter();
  // token: any = null;
  public get token() {
    return environment.auth_token;
  }
  public set token(value) {
    environment.auth_token = value;
  }

  refreshToken: any = null;
  refreshInterval = null;

  userStorage: USM.UserStorage;

  guestLogin = {
    email: 'visitor@detailorg.com',
    pass: 'hVY582dfhTsab18',
  }
  get isGuest() {
    return this.user?.email==this.guestLogin.email || false;
  }

  hasRole(role) {
    return (this.user && this.user.roles) ? this.user.roles.includes(role) : false;
  }

  constructor() {
    this.restoreUserStorage();
  }

  initCore(core:CoreProvider) {
    this.core = core;
    const token = localStorage.getItem('sessToken');
    if (token) try {
      const parsedToken = JSON.parse(token);
      this.token = parsedToken.access;
      this.refreshToken = parsedToken.refresh;
    } catch(_e) {}
    this.initChecks();
  }

  initChecks(cbOk:Function=null,  cbKo:Function=null, disableRefresh=false) {
    const errSession = () => {
      this.saveUser(false);
      this.token = false;
      this.saveToken();
      if (cbKo) cbKo();
    };
    if (this.token) {
      this.core.api.bitcoin.isSync({chain:'all'}).subscribe({next: res => {
        this.chainList = res;
      }, error: err => console.error('Error fetching chainList', err)})
      this.core.api.jwt.printCurrentUser().subscribe({next: async res => {
        const usResult = await this.fetchUserStorage(res);
        this.saveUser(res);
        if (cbOk) cbOk(usResult?.['userWithoutStorage'] ?? false);
      }, error: err => {
        if (this.refreshToken && !disableRefresh) {
          this.doRefresh(() => {
            this.initChecks(cbOk, cbKo, true);
          }, () => errSession());
        } else errSession();
      }});
    } else {
      errSession();
    }
  }

  activePass(passToken, cbOk:Function=null, cbKo:Function=null) {
    this.core.api.jwt.activateNewPass({body:{passToken}}).subscribe({next: (res:any) => {
      // If 200 OK status fire cbOk
      cbOk?.(res);
    }, error: err => cbKo?.(err)});
  }

  active(activeToken, cbOk?: Function, cbKo?: Function) {
    this.core.api.jwt.activateUser({activeToken, browserId: this.core.browserId}).subscribe({next: (res:any) => {
      if (res.resp) cbOk?.(res);
      else cbKo?.(res);
    }, error: err => cbKo?.(err)});
  }

  login(data: LoginUser, cbOk:Function=null, cbKo:Function=null) {
    this.core.api.jwt.login({body: data}).subscribe({next: (res:any) => {
      this.token = res.accessToken;
      this.refreshToken = res.refreshToken;
      this.saveToken();
      this.initChecks(cbOk, cbKo);
      this.setRefreshInterval();
    }, error: err => {
      this.saveUser(false);
      this.token = false;
      this.saveToken();
      if (cbKo) cbKo(err);
    }});
  }

  logout() {
    const deleteSession = () => {
      this.core.api.jwt.logout({body: {accessToken: this.token || ''}}).subscribe({next: (response) => {
        this.core.bps.deleteVisitedBP();
        this.user = false;
        this.token = false;
        this.refreshToken = false;
        this.saveToken();
        this.saveUser(false);
        this.core.navCtrl.navigateRoot('/auth/node-election');
      }, error: err =>{
          console.log(err);
      }});

    };

    if (this.token) {
      this.core.api.jwt.printCurrentUser().subscribe({next: res => {
        deleteSession();
      }, error: err => {
        deleteSession();
      }});
    } else {
      deleteSession();
    }
  }

  delete() {
    if (this.token) {
      this.core.api.jwt.delete({
        body: {userId: this.user.id}
      }).subscribe({next: (response)=>{
          this.core.successToast(null, 'El usuario ha sido eliminado correctamente');
          this.core.navCtrl.navigateRoot('/auth/register');
      }, error: err => {
        console.log(err)
      }});
    }
  }

  changePassword(newPassword: string) {
    if(this.token){
      return this.core.api.jwt.changePass({
        body: {password: newPassword}
      });
    }
  }

  fogottenPassword(email: string, newPassword: string) {
    return this.core.api.jwt.newPass({body: {email, newPassword}});
  }

  doRefresh(cbOk:Function=null, cbKo:Function=null) {
    this.clearRefreshInterval();
    console.warn('doing refresh', this.refreshToken);

    if (this.refreshToken) this.core.api.jwt.refresh({body: {
      refreshToken: this.refreshToken
    }}).subscribe({next: (res:any) => {
      this.token = res.accessToken;
      // this.refreshToken = res.refreshToken;
      if (cbOk) cbOk();
      this.saveToken();
      this.setRefreshInterval();
    }, error: err => {
      if (cbKo) cbKo(err);
    }});
  }
  clearRefreshInterval() { if (this.refreshInterval) clearInterval(this.refreshInterval); };
  setRefreshInterval(minutes:number=5) {
    this.clearRefreshInterval();
    this.refreshInterval = setInterval(() => this.doRefresh(), minutes*60*1000);
    console.warn('created interval', this.refreshInterval);
  }

  saveToken() {
    localStorage.setItem('sessToken', JSON.stringify({
      access: this.token,
      refresh: this.refreshToken
    }));
    if (this.refreshToken) this.setRefreshInterval();
  }
  saveUser(user) {
    this.user = user;
    this.onUser.next(this.user);
    console.log('saved user', this.user);
    if (this.user) this.setRefreshInterval();
  }

  register(data: NewUser & {relayUrl: string}, cbOk:Function=null, cbKo:Function=null) {
    data.relayUrl = undefined;
    this.core.api.jwt.registerUser({body: data}).subscribe({next: (res) => {
      if (cbOk) cbOk(res);
    }, error: err => {
      if (cbKo) cbKo(err);
    }});
  }


  async fetchUserStorage(user: any) {
    return new Promise<{userWithoutStorage: boolean}|void>(async (resolve, reject) => {
      try {
        const res: any = await firstValueFrom(this.core.api.jwt.getUserStorage());
        if (!res.response && user?.email!=this.guestLogin.email) {
          this.core.navCtrl.navigateRoot('/identity');
          resolve({userWithoutStorage: true})
        }
        const loginuser = localStorage.getItem('loginuser');
        if (loginuser) {
          const loginuserParsed = JSON.parse(loginuser);
          if (loginuserParsed) {
            const did = loginuserParsed.logins.filter(itm => itm.email==user.email && itm.relayUrl == this.core.relayEndpoint)[0];
            if (did && did.privkeyPGP) {
              this.core.api.restrict.decypherMessage({body: {
                cypherMessage: res.response,
                privateKey: did.privkeyPGP,
              }}).subscribe({
                next: async (res: Blob) => {
                  const parsedRes = JSON.parse(await res.text()) as USM.UserStorage;
                  if (parsedRes) {
                    // Perform upgrades
                    if (parsedRes.wallets.consumables.lightning?.label==undefined) {
                      parsedRes.wallets.consumables.lightning = { label: '', chain: '', bp: '', lnaddress: '', credentials: {
                        admin: '',
                        adminkey: '',
                        id: '',
                        inkey: '',
                        user: ''
                      }, received: [], sent: [] };
                    }

                    parsedRes.apps.list.push({appBtml: '9000000000', themeCustomization: {}})
                    parsedRes.apps.appUsed = 1;

                    console.log('Loading user storage', parsedRes);
                    this.userStorage = parsedRes;

                    // TODO: Check this new code for replacement at theme config from user storage
                    // this.core.lang = this.userStorage.identities.publicID.language;
                    // console.log(this.core.lang);
                  }
                  resolve();
                },
                // TODO: Handle error
                error: (err) => {
                  console.error(err);
                  resolve();
                }
              });
            } else resolve();
          } else resolve();
        } else resolve();
      } catch(err) {
        // TODO: Handle error
        console.error(err);
        resolve();
      }
    });
  }

  saveUserStorage(cbOk?: Function, cbKo?: Function) {
    this.core.api.restrict.cypherMessage({body:{
      publicKeys: [this.core.auth.userStorage!.identities.privateID.pubkeyPGP],
      message: JSON.stringify(this.userStorage),
    }}).subscribe({
      next: res => {
        this.core.api.jwt.postUserStorage({body: {hash: res.cypherMessage}}).subscribe({
          next: res => {
            console.log(res);
            cbOk?.(res);
          },
          error: err => {
            console.error(err);
            cbKo?.(err);
          }
        });
      },
      error: err => {
        console.error(err);
        cbKo?.(err);
      }
    });
  }

  toggleWalletProduct(btml: string, cbOk?: Function, cbKo?: Function) {
    if (!this.userStorage.wallets.consumables.products) this.userStorage.wallets.consumables.products = [];
    if (!this.userStorage.wallets.consumables.products.includes(btml)) {
      this.userStorage.wallets.consumables.products.push(btml);
    } else {
      this.userStorage.wallets.consumables.products = this.userStorage.wallets.consumables.products.filter(bt => bt!==btml);
    }
    this.saveUserStorage(cbOk, cbKo);
  }


  restoreUserStorage() {
    this.userStorage = {
      wallets: {
        bitcoin: {
          blockchain: {
            favorite: null,
            blockchainWallets: []
          },
          lightning: {
            favorite: 0,
            lightningWallets: [],
          },
          fedimint: {},
          vault: {
            favorite: 0,
            vaultWallets: []
          }
        },
        tokens: {
          blockchain: {
            favorite: 0,
            blockchainWallets: []
          },
          lightning: {
            favorite: 0,
            lightningWallets: []
          },
          fedimint: {},
          vault: {
            favorite: 0,
            vaultWallets: []
          }
        },
        debts: {
          blockchain: {
            favorite: 0,
            blockchainWallets: []
          },
          lightning: {
            favorite: 0,
            lightningWallets: []
          },
          fedimint: {},
          vault: {
            favorite: 0,
            vaultWallets: []
          }
        },
        consumables: {
          products: [],
          blockchain: {
            label: '',
            chain: '',
            bp: '',
            pubkey: '',
            seed: '',
            received: [],
            sent: [],
          },
          lightning: {
            label: '',
            chain: '',
            bp: '',
            lnaddress: '',
            credentials: {
              admin: '',
              adminkey: '',
              id: '',
              inkey: '',
              user: ''
            },
            received: [],
            sent: []
          }
        }
      },
      savedActions: {
        process: [],
        variables: [],
      },
      identities: {
        publicID: {
          userImage: null,
          userAlias: '',
          userDescription: '',
          bp_id: '',
          language: 'es',
          theme: 'dark'
        },
        privateID: undefined,
        history: [],
      },
      history: {
        events: []
      },
      apps: {
        appUsed: 0,
        list: [],
        history: []
      },
    };
  }

}
