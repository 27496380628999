/* eslint-disable @typescript-eslint/no-explicit-any */
import { getActionExecutor } from '../action-catalog';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class RunImportAction extends RunnableAction {
  static readonly identifier = 'runImport';
  public actionSpec: ActionSpec = {
    description: 'Execute import task from prodcuts metadata',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Products metadata', tooltip: 'BP Data products to create' },
      ],
      output: [
        { pattern: '.*', label: 'Json', tooltip: 'Created entries result json model' },
      ]
    }
  };

  async run(...params: string[]): Promise<string[]> {
    const metaDataProds = JSON.parse(params[0]);

    const btmlDoneMap: Record<string, string> = {};
    const handleReplacement = (string: string) => {
      if (string?.startsWith('TOBTML:')) {
        let btml = '';
        if ((btml = btmlDoneMap[string.split(':')[1]]) !== undefined) {
          string = 'app://bp/' + btml;
        } else {
          string = 'Error linking ' + string.split(':')[1];
        }
      }
      return string;
    };
    const assignTreeIpfsLinks = (card: any) => {
      if (!card) return;
      if (card.endLink) card.endLink.url = handleReplacement(card.endLink.url);
      if (card.cardImg) card.cardImg.url = handleReplacement(card.cardImg.url);
      if (card.cardBtn) card.cardBtn.url = handleReplacement(card.cardBtn.url);
      if (card.cardElements)
        for (const ce of card.cardElements) {
          if (ce.url) ce.url = handleReplacement(ce.url);
          if (ce.value) ce.value = handleReplacement(ce.value);
          if (ce.btmlCard?.btml) ce.btmlCard.btml = handleReplacement(ce.btmlCard.btml);
        }
    };

    for (const metaProd of metaDataProds) {
      const prod = metaProd.prod;
      // Perform required modifications
      for (const pv of prod) {
        if (pv._proto === 'ICON') {
          pv.val['updated'] = Date.now();
          for (const card of pv.val.summary.cards) assignTreeIpfsLinks(card);
        }
      }

      console.log(
        'Product =>',
        `${metaDataProds.indexOf(metaProd) + 1} of ${metaDataProds.length} (${((metaDataProds.indexOf(metaProd) + 1) / metaDataProds.length) * 100
        }%)`,
      );
      const signAuthor = process.env.WORKER_SIGN_AUTHOR!;
      const signKey = process.env.WORKER_SIGN_PRIVKEY!;
      const cryptoPassBtml = (
        await getActionExecutor('saveBitpass')!.run(
          JSON.stringify(prod),
          signAuthor,
          signKey,
          `${metaProd._meta.btmlType || '11000'}`,
          metaProd._meta.btml ? `${metaProd._meta.btml}` : undefined as unknown as string,
        )
      )[3];

      btmlDoneMap[metaProd._meta.id] = cryptoPassBtml;

      console.log('\t[DONE]');
    }

    return [JSON.stringify(btmlDoneMap)];
  }
}
