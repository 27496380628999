/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ChangePass } from '../models/change-pass';
import { ChangeRole } from '../models/change-role';
import { DeleteUser } from '../models/delete-user';
import { LnBitsPushAddress } from '../models/ln-bits-push-address';
import { LoginUser } from '../models/login-user';
import { LoginUserResponse } from '../models/login-user-response';
import { LogoutUser } from '../models/logout-user';
import { NewPass } from '../models/new-pass';
import { NewUser } from '../models/new-user';
import { QrRequest } from '../models/qr-request';
import { RefreshToken } from '../models/refresh-token';
import { StatusResponse } from '../models/status-response';
import { User } from '../models/user';
import { UserNewPasswordRequest } from '../models/user-new-password-request';
import { UserProfileResponse } from '../models/user-profile-response';
import { UserResponse } from '../models/user-response';
import { FetchMarketChartRange as CoinsFetchMarketChartRange } from '../models/coins/fetch-market-chart-range';
import { List as CoinsList } from '../models/coins/list';
import { Price as SimplePrice } from '../models/simple/price';
import { SupportedVsCurrencies as SimpleSupportedVsCurrencies } from '../models/simple/supported-vs-currencies';


/**
 * `Authorize` conection to external and internal services through <b>Api Rest</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer6Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `coingeckoApiControllerGetPrizeExplorerList()` */
  static readonly CoingeckoApiControllerGetPrizeExplorerListPath = '/API/search/prizeExplorerList';

  /**
   * List of available prize explorers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPrizeExplorerList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrizeExplorerList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.CoingeckoApiControllerGetPrizeExplorerListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * List of available prize explorers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPrizeExplorerList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPrizeExplorerList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.getPrizeExplorerList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `coingeckoApiControllerCoinsList()` */
  static readonly CoingeckoApiControllerCoinsListPath = '/API/{prize_explorer}/search/token/list';

  /**
   * List of coins id.
   *
   * Use this to obtain all the coins id in order to make API calls
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Coins_list()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coins_list$Response(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CoinsList>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.CoingeckoApiControllerCoinsListPath, 'get');
    if (params) {
      rb.path('prize_explorer', params.prize_explorer, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoinsList>;
      })
    );
  }

  /**
   * List of coins id.
   *
   * Use this to obtain all the coins id in order to make API calls
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Coins_list$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coins_list(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
    },
    context?: HttpContext
  ): Observable<CoinsList> {
    return this.Coins_list$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoinsList>): CoinsList => r.body)
    );
  }

  /** Path part for operation `coingeckoApiControllerCoingeckoSimplePrice()` */
  static readonly CoingeckoApiControllerCoingeckoSimplePricePath = '/API/{prize_explorer}/search/token/price';

  /**
   * Get the current price of any cryptocurrencies in any other supported currencies that you need.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Coingecko_simple_price()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko_simple_price$Response(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
      coinId: string;
      vs_currency: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SimplePrice>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.CoingeckoApiControllerCoingeckoSimplePricePath, 'get');
    if (params) {
      rb.path('prize_explorer', params.prize_explorer, {});
      rb.path('coinId', params.coinId, {});
      rb.path('vs_currency', params.vs_currency, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimplePrice>;
      })
    );
  }

  /**
   * Get the current price of any cryptocurrencies in any other supported currencies that you need.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Coingecko_simple_price$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko_simple_price(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
      coinId: string;
      vs_currency: string;
    },
    context?: HttpContext
  ): Observable<SimplePrice> {
    return this.Coingecko_simple_price$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimplePrice>): SimplePrice => r.body)
    );
  }

  /** Path part for operation `coingeckoApiControllerCoingeckoSupportedVsCurrencies()` */
  static readonly CoingeckoApiControllerCoingeckoSupportedVsCurrenciesPath = '/API/{prize_explorer}/search/token/vs';

  /**
   * List of compared pair tokens.
   *
   * Get list of supported vs/comparisons currencies.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Coingecko__SupportedVsCurrencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko__SupportedVsCurrencies$Response(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SimpleSupportedVsCurrencies>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.CoingeckoApiControllerCoingeckoSupportedVsCurrenciesPath, 'get');
    if (params) {
      rb.path('prize_explorer', params.prize_explorer, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimpleSupportedVsCurrencies>;
      })
    );
  }

  /**
   * List of compared pair tokens.
   *
   * Get list of supported vs/comparisons currencies.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Coingecko__SupportedVsCurrencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko__SupportedVsCurrencies(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
    },
    context?: HttpContext
  ): Observable<SimpleSupportedVsCurrencies> {
    return this.Coingecko__SupportedVsCurrencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<SimpleSupportedVsCurrencies>): SimpleSupportedVsCurrencies => r.body)
    );
  }

  /** Path part for operation `coingeckoApiControllerCoingeckoPrizeDataRange()` */
  static readonly CoingeckoApiControllerCoingeckoPrizeDataRangePath = '/API/{prize_explorer}/search/{coinId}/prize/dataRange/{fromDate}/{toDate}';

  /**
   * Get historical market data include price, market cap, and 24h volume within a range of timestamp (granularity auto). Minutely data will be used for duration within 1 day. Hourly data will be used for duration between 1 day and 90 days. Daily data will be used for duration above 90 days.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `Coingecko_prize_dataRange()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko_prize_dataRange$Response(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
      coinId: string;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CoinsFetchMarketChartRange>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.CoingeckoApiControllerCoingeckoPrizeDataRangePath, 'get');
    if (params) {
      rb.path('prize_explorer', params.prize_explorer, {});
      rb.path('coinId', params.coinId, {});
      rb.path('fromDate', params.fromDate, {});
      rb.path('toDate', params.toDate, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CoinsFetchMarketChartRange>;
      })
    );
  }

  /**
   * Get historical market data include price, market cap, and 24h volume within a range of timestamp (granularity auto). Minutely data will be used for duration within 1 day. Hourly data will be used for duration between 1 day and 90 days. Daily data will be used for duration above 90 days.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `Coingecko_prize_dataRange$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  Coingecko_prize_dataRange(
    params: {

    /**
     * Add a valid prize explorer
     */
      prize_explorer: 'coingecko';
      coinId: string;
      fromDate: string;
      toDate: string;
    },
    context?: HttpContext
  ): Observable<CoinsFetchMarketChartRange> {
    return this.Coingecko_prize_dataRange$Response(params, context).pipe(
      map((r: StrictHttpResponse<CoinsFetchMarketChartRange>): CoinsFetchMarketChartRange => r.body)
    );
  }

  /** Path part for operation `userControllerActivateNewPass()` */
  static readonly UserControllerActivateNewPassPath = '/JWT/activate/pass';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateNewPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateNewPass$Response(
    params?: {
      body?: UserNewPasswordRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerActivateNewPassPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateNewPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateNewPass(
    params?: {
      body?: UserNewPasswordRequest
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.activateNewPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerChangeRole()` */
  static readonly UserControllerChangeRolePath = '/JWT/change/role';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeRole()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole$Response(
    params?: {
      body?: ChangeRole
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChangeRole>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerChangeRolePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangeRole>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeRole$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeRole(
    params?: {
      body?: ChangeRole
    },
    context?: HttpContext
  ): Observable<ChangeRole> {
    return this.changeRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangeRole>): ChangeRole => r.body)
    );
  }

  /** Path part for operation `userControllerDelete()` */
  static readonly UserControllerDeletePath = '/JWT/forget/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete$Response(
    params: {
      body: DeleteUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerDeletePath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delete(
    params: {
      body: DeleteUser
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerLogin()` */
  static readonly UserControllerLoginPath = '/JWT/login/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(
    params: {
  
    /**
     * The input of login function
     */
    body: LoginUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginUserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerLoginPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginUserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(
    params: {
  
    /**
     * The input of login function
     */
    body: LoginUser
    },
    context?: HttpContext
  ): Observable<LoginUserResponse> {
    return this.login$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginUserResponse>): LoginUserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerLogout()` */
  static readonly UserControllerLogoutPath = '/JWT/logout/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logout()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout$Response(
    params: {
      body: LogoutUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerLogoutPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `logout$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  logout(
    params: {
      body: LogoutUser
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.logout$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerRefresh()` */
  static readonly UserControllerRefreshPath = '/JWT/refresh/activeToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `refresh()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refresh$Response(
    params: {
      body: RefreshToken
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LoginUserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerRefreshPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginUserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `refresh$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  refresh(
    params: {
      body: RefreshToken
    },
    context?: HttpContext
  ): Observable<LoginUserResponse> {
    return this.refresh$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoginUserResponse>): LoginUserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerRegisterUser()` */
  static readonly UserControllerRegisterUserPath = '/JWT/register/user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerUser()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser$Response(
    params?: {
      body?: NewUser
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<User>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerRegisterUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerUser$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerUser(
    params?: {
      body?: NewUser
    },
    context?: HttpContext
  ): Observable<User> {
    return this.registerUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `userControllerPrintCurrentUser()` */
  static readonly UserControllerPrintCurrentUserPath = '/JWT/search/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printCurrentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentUser$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserProfileResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerPrintCurrentUserPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserProfileResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printCurrentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printCurrentUser(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserProfileResponse> {
    return this.printCurrentUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserProfileResponse>): UserProfileResponse => r.body)
    );
  }

  /** Path part for operation `userControllerActivateUser()` */
  static readonly UserControllerActivateUserPath = '/JWT/search/{activeToken}/{browserId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateUser$Response(
    params: {
      activeToken: string;
      browserId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerActivateUserPath, 'get');
    if (params) {
      rb.path('activeToken', params.activeToken, {});
      rb.path('browserId', params.browserId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `activateUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateUser(
    params: {
      activeToken: string;
      browserId: string;
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.activateUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerChangePass()` */
  static readonly UserControllerChangePassPath = '/JWT/update/pass/signed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changePass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePass$Response(
    params: {
      body: ChangePass
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerChangePassPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changePass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changePass(
    params: {
      body: ChangePass
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.changePass$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerNewPass()` */
  static readonly UserControllerNewPassPath = '/JWT/update/pass/unsigned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newPass$Response(
    params: {
      body: NewPass
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerNewPassPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `newPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newPass(
    params: {
      body: NewPass
    },
    context?: HttpContext
  ): Observable<UserResponse> {
    return this.newPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserResponse>): UserResponse => r.body)
    );
  }

  /** Path part for operation `userControllerGetUserStorage()` */
  static readonly UserControllerGetUserStoragePath = '/JWT/user/storage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserStorage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStorage$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerGetUserStoragePath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserStorage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserStorage(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getUserStorage$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `userControllerPostUserStorage()` */
  static readonly UserControllerPostUserStoragePath = '/JWT/user/storage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `postUserStorage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserStorage$Response(
    params?: {
      body?: LnBitsPushAddress
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UserControllerPostUserStoragePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `postUserStorage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  postUserStorage(
    params?: {
      body?: LnBitsPushAddress
    },
    context?: HttpContext
  ): Observable<any> {
    return this.postUserStorage$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `utilsControllerMakePkpass()` */
  static readonly UtilsControllerMakePkpassPath = '/NPM/create/pkpass';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `makePkpass()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  makePkpass$Response(
    params: {
  
    /**
     * Array of files on multipart key "file"
     */
    body: {
'file'?: Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UtilsControllerMakePkpassPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/vnd.apple.pkpass', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `makePkpass$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  makePkpass(
    params: {
  
    /**
     * Array of files on multipart key "file"
     */
    body: {
'file'?: Array<Blob>;
}
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.makePkpass$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `utilsControllerCreateRequest()` */
  static readonly UtilsControllerCreateRequestPath = '/NPM/create/qr';

  /**
   * Create QR.
   *
   * Create QR from a string. Parameters:<br>
   *     - String: String value for QR data.<br>
   *     - Type: Possible values for the respone are: png, svg, utf8.<br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRequest()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest$Response(
    params?: {
      body?: QrRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UtilsControllerCreateRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Create QR.
   *
   * Create QR from a string. Parameters:<br>
   *     - String: String value for QR data.<br>
   *     - Type: Possible values for the respone are: png, svg, utf8.<br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRequest(
    params?: {
      body?: QrRequest
    },
    context?: HttpContext
  ): Observable<any> {
    return this.createRequest$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `utilsControllerCreateVcard()` */
  static readonly UtilsControllerCreateVcardPath = '/NPM/create/vcard';

  /**
   * Made vCard file.
   *
   * Create a vCard adding the files and inputs neccessaries
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createVcard()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  createVcard$Response(
    params?: {
      body?: QrRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.UtilsControllerCreateVcardPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Made vCard file.
   *
   * Create a vCard adding the files and inputs neccessaries
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createVcard$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   *
   * @deprecated
   */
  createVcard(
    params?: {
      body?: QrRequest
    },
    context?: HttpContext
  ): Observable<any> {
    return this.createVcard$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `statusControllerStatusPass()` */
  static readonly StatusControllerStatusPassPath = '/Server/create/status/pkpass';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusPass()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusPass$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.StatusControllerStatusPassPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/vnd.apple.pkpass', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusPass$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusPass(
    params?: {
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.statusPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `statusControllerStatus()` */
  static readonly StatusControllerStatusPath = '/Server/search/status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `status()` instead.
   *
   * This method doesn't expect any request body.
   */
  status$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatusResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.StatusControllerStatusPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `status$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  status(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatusResponse> {
    return this.status$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusResponse>): StatusResponse => r.body)
    );
  }

  /** Path part for operation `pgpControllerUploadFile()` */
  static readonly PgpControllerUploadFilePath = '/Server/upload/file';

  /**
   * Upload file to local.
   *
   * Request body for multipart/form-data based file upload
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile$Response(
    params: {
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.PgpControllerUploadFilePath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Upload file to local.
   *
   * Request body for multipart/form-data based file upload
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadFile(
    params: {
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<any> {
    return this.uploadFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `statusControllerStatusPassWs()` */
  static readonly StatusControllerStatusPassWsPath = '/Server/upload/status/pkpass/{identifier}/{serial}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statusPassWS()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusPassWS$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, Layer6Service.StatusControllerStatusPassWsPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/vnd.apple.pkpass', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statusPassWS$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  statusPassWS(
    params?: {
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.statusPassWS$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
