import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApiLnParseInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(this.parseRequest(request));
  }

  parseRequest(request: HttpRequest<any>) {
    if (!request.url.includes('INTERCEPT_LN_REQ')) {
      return request;
    } else {
      const browserId = environment.browser_id;
      const token = request.headers.get('token') ?? '';
      const auth = environment.auth_token
        ? { Authorization: `Bearer ${environment.auth_token}` }
        : {};

      const action = request.url.replace('INTERCEPT_LN_REQ/', '');
      const method = request.method;
      const params = request.body ?? {};
      const url = `${environment.current_relay.replace(/\/$/g, '')}/LN/${request.headers.get('chain') ?? 'all'}/access/custodial`;

      return request.clone({
        url,
        method: 'POST',
        body: {
          action, method, params
        },
        setHeaders: {
          ...auth,
          token,
          'x-browser-id': browserId,
        },
      });
    }
  }
}
