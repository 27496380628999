import { Injectable, SecurityContext } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CoreProvider } from './core';
import { HttpContext } from '@angular/common/http';

export interface ResourceEventData {
  cleanUrl: string;
  sanitizedUrl: SafeUrl;
  /** @deprecated you should use cleanUrl or sanitizedUrl */
  url: string;
  type: string;
  blob: Blob;
};

export interface ResourceRequestOptions {
  loadingValue: string;
  errorValue: string;
  debugContext?: string;
  ipfsCiphered?: boolean;
};

interface ResourceCache {
  [key: string]: {
    src: string|SafeUrl;
    ev: BehaviorSubject<ResourceEventData>;
    // fullReturn: false;
  };
};

@Injectable({ providedIn: 'root' })
export class ResourceProvider {
  private resourceCache: ResourceCache = {
    loading: {
      src: '/assets/loading.gif',
      ev: new BehaviorSubject<ResourceEventData>({
        cleanUrl: '/assets/loading.gif',
        sanitizedUrl: '/assets/loading.gif',
        url: '/assets/loading.gif',
        type: 'image/gif',
        blob: undefined,
      }),
    },
  };

  core: CoreProvider;
  async initCore(core: CoreProvider) {
    this.core = core;
  }

  cleanURL(oldURL: string|SafeUrl, debugContext?: string): string {
    console.warn(`RES[${debugContext}] CLEAN`, oldURL);
    const tes = this.core.sanitizer.sanitize(
      SecurityContext.RESOURCE_URL,
      (typeof oldURL == 'string') ? this.core.sanitizer.bypassSecurityTrustResourceUrl(oldURL) : oldURL
    );
    return tes;
  }
  sanitizeURL(oldURL: string, debugContext?: string): SafeUrl {
    console.warn(`RES[${debugContext}] SANITIZE`, oldURL);
    return this.core.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  resourceURL(base: string, options: Partial<ResourceRequestOptions> = {}): BehaviorSubject<ResourceEventData> {
    const opts: ResourceRequestOptions = {
      loadingValue: options.loadingValue ?? this.core.personalization.appConfig.content?.images?.loading ?? '/assets/loading.gif',
      errorValue: options.errorValue ?? this.core.personalization.appConfig.content?.images?.fallback ?? '/assets/404.png',
      debugContext: options.debugContext ?? undefined,
      ipfsCiphered: options.ipfsCiphered ?? false,
    };
    const commitChange = (newVal: string|SafeUrl, blob?: Blob, force = false) => {
      if (this.resourceCache[base].src != newVal || force) {
        this.resourceCache[base].src = newVal;
        let cleanUrl: string = this.cleanURL(newVal);
        let sanitizedUrl: string|SafeUrl = this.sanitizeURL(cleanUrl);

        this.resourceCache[base].ev.next({
          cleanUrl,
          sanitizedUrl,
          url: cleanUrl,
          blob: blob,
          type: blob?.type,
        });

        console.log(
          '1:EMMITING NEW VAL',
          base,
          this.resourceCache[base].src,
        );
      }
    };
    if (!this.resourceCache[base]) {
      this.resourceCache[base] = {
        src: opts.loadingValue,
        ev: new BehaviorSubject({url: opts.loadingValue, sanitizedUrl: this.sanitizeURL(opts.loadingValue), cleanUrl: opts.loadingValue, type: undefined, blob: undefined})
      };

      if (!base || typeof base != 'string') {
        commitChange(opts.errorValue);
      } else if (base.startsWith('ipfs://')) {
        const cid = base.replace('ipfs://', '');
        this.core.api.ipfs.getfiles$Response({CID: cid}).subscribe({
          next: async (resource) => {
            if (resource.ok) {
              if (options.ipfsCiphered) {
                try {
                  const context = new HttpContext();
                  const res = await firstValueFrom(this.core.api.restrict.decypherMessage$Response({body:{
                    cypherMessage: await resource.body.text(),
                    privateKey: this.core.auth.userStorage.identities.privateID.privkeyPGP,
                  }}, context));
                  if (!res.ok) {
                    console.error('Error decyphering resource', res);
                    commitChange(opts.errorValue);
                  }
                  commitChange(URL.createObjectURL(res.body), res.body);
                } catch(err) {
                  console.error('Error decyphering resource', err);
                  commitChange(opts.errorValue);
                }
              } else {
                // Directly fetch from blob
                commitChange(URL.createObjectURL(resource.body), resource.body);
              }
            } else {
              commitChange(opts.errorValue);
            }
          },
          error: (err) => {
            commitChange(opts.errorValue);
          },
        });
      } else {
        commitChange(base);
      }
    }

    return this.resourceCache[base].ev;
  }
}
