import { BtmlMap } from "./base-specific";
import { Resource, ResourceTypes } from "./resource/resource";
import { File, FileTypes } from "./file/file";
import { Task } from "./task/task";
import { Index } from "./index/index";
import { Application } from "./application/application";
import { Worker } from "./worker/worker";

export type TlTypes = File<FileTypes>|Resource<ResourceTypes>|Task;
export const TlBtmlMap: BtmlMap = {
    0: File,
    1: Resource,
    5: Index,
    7: Task,
    8: Worker,
    9: Application,
}

export function genericFactory(btmlMap: BtmlMap, btmlPart: number, data: TlTypes): TlTypes {
    if (btmlMap[btmlPart]) {
        return new (btmlMap[btmlPart] as any)(data);
    } else {
        return null;
    }
}
export function genericTlFactory(data: TlTypes) {
    if (TlBtmlMap[data.btmlPart]) {
        return new (TlBtmlMap[data.btmlPart] as any)(data);
    } else {
        return null;
    }
}
