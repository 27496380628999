import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';
import { IonInput } from '@ionic/angular';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { firstValueFrom } from 'rxjs';
const { Share } = Plugins;

export interface IFormData { bpBtml: string, email: string }

@Component({
  selector: 'app-bitpass-claim-notification-modal',
  templateUrl: './bitpass-claim-notification-modal.component.html',
  styleUrls: ['./bitpass-claim-notification-modal.component.scss'],
})
export class BitpassClaimNotificationModalComponent extends BaseModal<IFormData> {
  protected formIdentifier: string = 'bitpassClaimNotification';

  processStatus: 'idle'|'fetching_bp'|'updating_bp'|'sending_notification'|'success'|'error' = 'idle';

  changingBP: boolean = false;
  btmlType: string;

  @Input() set data(value: {bpBtml: string, bitpass: Bitpass<any>}) {
    console.warn('MODAL PARAMS', value)
    this.readFormToStore();
    if (value.bpBtml) {
      this.updateBTML(value.bpBtml);
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  protected async onCommit(data: IFormData): Promise<any> {
    try {
      this.processStatus = 'fetching_bp';
      const claimKey = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });

      const bitpass = await this.core.bps.fetchBitpass(data.bpBtml, Bitpass);

      this.processStatus = 'updating_bp';
      bitpass.product.__data[0].val.claimHash = await this.core.sha256Hash(claimKey);
      await this.core.bps.saveProduct(bitpass.product.__data, data.bpBtml.substring(0, 5), bitpass.record.key);

      this.processStatus = 'sending_notification';
      const res = await firstValueFrom(this.core.api.agram.claimBpNotification({worker: 'general_worker_server', body: {
        receiverEmail: data.email,
        btml: data.bpBtml,
        claimKey
      }}));
      if (!res) throw new Error('Invalid response from server');
      this.core.toastCtrl.create({
        message: 'Claim notification sent!',
        duration: 3000,
        color: 'success'
      }).then(toast => toast.present());
      this.processStatus = 'success';
    } catch (error) {
      console.error('Last status: '+this.processStatus, error);
      this.core.toastCtrl.create({
        message: 'Error sending claim notification',
        duration: 3000,
        color: 'danger'
      }).then(toast => toast.present());
      this.processStatus = 'error';
    }
  }

  //#region BP Change
  getBTMLType() {
    return Number(this.btmlType);
  }
  setBTMLType(value: string) {
    this.btmlType = value;
  }
  // Method used by the app-choose-bp (choose Bitpass).
  chooseBP(e) {
    this.changingBP = true;
    this.formDataStore.bpBtml = e;
  }
  async updateBTML(BTMLid = this.formDataStore.bpBtml) {
    this.formDataStore.bpBtml = BTMLid;
    this.changingBP = false;
    this.btmlType = this.formDataStore.bpBtml.substring(0, 5);

    // Maybe check at the future that current bp btml is owned by the user.

    // this.btmlForm.value = BTMLid;
    // this.fetchBitpass(this.urlBPid).subscribe({
    //   next: (res) => {
    //     if (res) {
    //       this.activeSale = res.__data[0].val.soldInfo?.activeSold
    //         ? true
    //         : false;
    //       this.precioVenta = res.__data[0].val.soldInfo?.price;
    //       // this.CIDForm.value = res.data_cid.toString();
    //     }
    //   },
    //   error: (err) => {},
    // });
  }
  //#endregion


  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore: IFormData = this.formDataStore || {
      bpBtml: '',
      email: '',
    };
    this.formDataStore = dataStore;
  }
}
