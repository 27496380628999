import { Component } from '@angular/core';
import { CoreProvider } from './services/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public core:CoreProvider) {}
}
