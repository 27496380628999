// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from "./ienvironment";

export const environment: IEnvironment = {
  production: true,
  prop_index_btml: "5213000000",
  open_form_action_btml: "1400000001",
  current_relay: 'https://test.api.detailorg.com/',
  browser_id: '4bf96c8c-45ea-4b31-bb72-9afffbb8b7a4',
  auth_token: '',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
