/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Base64 } from 'js-base64';
import * as crypto from 'crypto';

export class FileImpl {
  fileBits: BlobPart[];
  buffer: Buffer;
  fileName: string;

  constructor(file: BlobPart[] | Buffer, fileName: string) {
    this.fileName = fileName;
    if (file instanceof Buffer) {
      this.buffer = <Buffer>(<unknown>file);
    } else {
      this.fileBits = <BlobPart[]>(<unknown>file);
    }
  }

  getBuffer(): Promise<Buffer> {
    return new Promise((ok, ko) => {
      try {
        if (this.buffer) ok(this.buffer);
        else ok(Buffer.from(this.fileBits));
      } catch (err) {
        ko(err);
      }
    });
  }
}

export interface IRequestContext {
  ipfsGet: (cid: string) => Promise<any>;
  addressGet: (address: string, chain: string) => Promise<any>;
  newAddressUser: (passphrase: string, chain: string, type: string) => Promise<any>;
  makeTransfer: (transferCnf: any, chain: string) => Promise<any>;
  ipfsPin: (dataObject: any, cluster: string) => Promise<any>;
  ipfsPinFile: (file: FileImpl, cluster: string) => Promise<any>;
  keyLastTen: (lastTen: string, address: string) => Promise<any>;
  nextBtmlKey: (type: string, address: string) => Promise<any>;
  postRecord: (record: any) => Promise<any>;
  pgpSign: (data: string, privateKey: string) => Promise<any>;
  pgpVerify: (signedMessage: string, data: string, publicKey: string) => Promise<any>;
  pkpassSign: (files: FileImpl[]) => Promise<any>;
}
export interface IModalFactory {
  openModal(component: string|object, params: object): Promise<any>;
  handleLink(data: string): void;
}
export interface IWorkerContext {
  modalFactory: IModalFactory;
  requests: IRequestContext;
  signablePlainObject: (signableObjRef: any) => any;
  signSignable: Function;
  validateSign: Function;
}

function notOverridenError() {
  return new Error('Request context should be overrided');
}

export class WorkerContext implements IWorkerContext {
  private static contextInstance: WorkerContext;
  public static instance(): WorkerContext {
    if (!this.contextInstance) this.contextInstance = new WorkerContext();
    return this.contextInstance;
  }

  public setModalFactory(override: IModalFactory): WorkerContext {
    this.modalFactory = override;
    return this;
  }
  public setRequestOverride(override: IRequestContext): WorkerContext {
    this.requestOverride = override;
    return this;
  }

  public modalFactory: IModalFactory;
  public requestOverride: IRequestContext;
  public readonly requests = {
    ipfsGet: (cid: string): Promise<any> => {
      return this.requestOverride?.ipfsGet(cid) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    addressGet: (address: string, chain: string = 'testnet'): Promise<any> => {
      return this.requestOverride?.addressGet(address, chain) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    newAddressUser: (passphrase: string, chain: string = 'testnet', type: string = 'segwit'): Promise<any> => {
      return this.requestOverride?.newAddressUser(passphrase, chain, type) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    makeTransfer: (transferCnf: any, chain: string = 'testnet'): Promise<any> => {
      return this.requestOverride?.makeTransfer(transferCnf, chain) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    ipfsPin: (dataObject: any, cluster: string = 'server_ipfs_node'): Promise<any> => {
      return this.requestOverride?.ipfsPin(dataObject, cluster) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    ipfsPinFile: async (file: FileImpl, cluster: string = 'server_ipfs_node'): Promise<any> => {
      return this.requestOverride?.ipfsPinFile(file, cluster) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    keyLastTen: (lastTen: string, address = 'address_server'): Promise<any> => {
      return this.requestOverride?.keyLastTen(lastTen, address) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    nextBtmlKey: (type: string, address = 'address_server'): Promise<any> => {
      return this.requestOverride?.nextBtmlKey(type, address) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    postRecord: (record: any): Promise<any> => {
      return this.requestOverride?.postRecord(record) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    pgpSign: (data: string, privateKey: string): Promise<any> => {
      return this.requestOverride?.pgpSign(data, privateKey) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    pgpVerify: (signedMessage: string, data: string, publicKey: string): Promise<any> => {
      return this.requestOverride?.pgpVerify(signedMessage, data, publicKey) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
    pkpassSign: async (files: FileImpl[]): Promise<any> => {
      return this.requestOverride?.pkpassSign(files) ?? new Promise((_, ko) => {
        ko(notOverridenError());
      });
    },
  }

  public readonly signablePlainObject = (signableObjRef: any) => {
    const obj: any = {};
    for (const k in signableObjRef) {
      if (k !== 'signature' && !k.startsWith('__')) {
        obj[k] = signableObjRef[k];
      }
    }
    return obj;
  }

  public readonly signSignable = (
    signableObjRef: any,
    privateKey: string,
    cbOk?: Function,
    cbKo?: Function,
  ) => {
    const obj = this.signablePlainObject(signableObjRef);
    const hash = Base64.fromUint8Array(
      crypto.createHash('sha256').update(JSON.stringify(obj)).digest(),
    );

    this.requests.pgpSign(hash, privateKey)
      .then(async (res) => {
        // console.log('pgpSign', await res.text());
        cbOk?.((await res.json()).signedMessage);
      })
      .catch((err: any) => {
        console.error('pgpSign', err);
        cbKo?.(err);
      });
  }

  public readonly validateSign = (
    signableObjRef: any,
    publicKey: string,
    cbOk: Function | null = null,
    cbKo: Function | null = null,
  ): void => {
    if (!signableObjRef.signature) cbKo?.('No signable object provided');
    else {
      const obj = this.signablePlainObject(signableObjRef);
      const hash = Base64.fromUint8Array(
        crypto.createHash('sha256').update(JSON.stringify(obj)).digest(),
      );

      this.requests
        .pgpVerify(signableObjRef.signature, hash, publicKey)
        .then((res) => {
          console.log('pgpVerify', res);
          if (res) {
            cbOk?.(res);
          } else {
            cbKo?.(res);
          }
        })
        .catch((err) => {
          console.error('pgpVerify', err);
          cbKo?.(err);
        });
    }
  }
}


