import { Specific } from "../../base-specific";

export interface IImage extends Specific {
    imageName: string;
    imageDescription: string;
}

export class Image extends Specific implements IImage  {
    readonly _proto?: string = 'Image';
    readonly btmlPart: number = 1;

    imageName: string = null;
    imageDescription: string = null;

    constructor(data: IImage) {
        super(data);

        this.imageName = data.imageName;
        this.imageDescription = data.imageDescription;

        // Implement update logic here
    }
}
