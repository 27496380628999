/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Cid } from '../models/cid';
import { Ipfs } from '../models/ipfs';


/**
 * `Hash` any data and file by chaining it to any interaction with <b>Address-Content</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer4Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ipfsControllerGetClusterList()` */
  static readonly IpfsControllerGetClusterListPath = '/IPFS/search/clusterList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getClusterList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClusterList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<Ipfs>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer4Service.IpfsControllerGetClusterListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Ipfs>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getClusterList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getClusterList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<Ipfs>> {
    return this.getClusterList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Ipfs>>): Array<Ipfs> => r.body)
    );
  }

  /** Path part for operation `ipfsControllerGetfiles()` */
  static readonly IpfsControllerGetfilesPath = '/IPFS/search/{CID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getfiles$Response(
    params: {
      CID: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, Layer4Service.IpfsControllerGetfilesPath, 'get');
    if (params) {
      rb.path('CID', params.CID, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getfiles(
    params: {
      CID: string;
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.getfiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `ipfsControllerPinIpfs()` */
  static readonly IpfsControllerPinIpfsPath = '/IPFS/{cluster}/pin/file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pinIpfs()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pinIpfs$Response(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer4Service.IpfsControllerPinIpfsPath, 'post');
    if (params) {
      rb.path('cluster', params.cluster, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pinIpfs$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  pinIpfs(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.pinIpfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `ipfsControllerPinnedIpfs()` */
  static readonly IpfsControllerPinnedIpfsPath = '/IPFS/{cluster}/search/allPinned';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pinnedIpfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  pinnedIpfs$Response(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<Ipfs>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer4Service.IpfsControllerPinnedIpfsPath, 'get');
    if (params) {
      rb.path('cluster', params.cluster, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Ipfs>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pinnedIpfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pinnedIpfs(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
    },
    context?: HttpContext
  ): Observable<Array<Ipfs>> {
    return this.pinnedIpfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Ipfs>>): Array<Ipfs> => r.body)
    );
  }

  /** Path part for operation `ipfsControllerUnpinFromIpfs()` */
  static readonly IpfsControllerUnpinFromIpfsPath = '/IPFS/{cluster}/unpin/{CID}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unpinFromIpfs()` instead.
   *
   * This method doesn't expect any request body.
   */
  unpinFromIpfs$Response(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
      CID: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer4Service.IpfsControllerUnpinFromIpfsPath, 'post');
    if (params) {
      rb.path('cluster', params.cluster, {});
      rb.path('CID', params.CID, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `unpinFromIpfs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unpinFromIpfs(
    params: {

    /**
     * Add a valid IPFS cluster
     */
      cluster: string;
      CID: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.unpinFromIpfs$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
