import { Injectable } from '@angular/core';

export interface AppConfig {
  name: string;
}

@Injectable({ providedIn: 'root' })
export class AppGlobalServiceProvider {

  private configData: AppConfig = {
    name: 'dSequence APP',
  };
  public get config() {
    return this.configData;
  };

}
