import { ActionSpec, RunnableAction } from '../runnable-action';

export class BtcDayUserSegwitTx extends RunnableAction {
  static readonly identifier = 'btcDayUserSegwitTx';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    console.log("PARAMS", params)

    throw new Error(
      'Not yet implemented',
    );

    return [];
  }
}
