/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class PostRecordAction extends RunnableAction {
  static readonly identifier = 'postRecord';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const record = JSON.parse(params[0]);

    const resp = await requests.postRecord(record);
    if (!resp.ok)
      throw new Error(
        'Error in post record of ' +
        params[0] +
        '. Error: ' +
        (await resp.text()),
      );

    return [await resp.text()];
  }
}
