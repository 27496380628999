import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import { BehaviorSubject } from 'rxjs';
import { IonInput } from '@ionic/angular';
import { UserStorageModels } from 'src/app/services/user-storage-models';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';

@Component({
  selector: 'app-cancel-sale-modal',
  templateUrl: './cancel-sale-modal.component.html',
  styleUrls: ['./cancel-sale-modal.component.scss'],
})
export class CancelSaleModalComponent implements OnInit {
  changingBP: boolean = false; // Boolean which will allow us to re-order HTML elements, it will be on if we're changing Bitpass
  urlBPid: string; // String which contains parsed URL (app://<btml>) in order to call macro-pass component
  bitPass: Bitpass<any>; // Variable which saves bitpass information (input)
  page = 1;
  // BTMLType: string; // BTML Type (First 5 characters of BTML)
  btmlNewId: string; // The New BTML which user will be moved to whenever he clicks "Change the BP"
  precioVenta: number;

  // Variables to access to product property
  ownerBTML: string; // BTML Owner
  registerBook: Bitpass<any>; // Register Book (We will use it to know who is the product owner).
  transferRegister: Bitpass<any>;

  // Variables for managing the Form
  submitted = false; // Boolean which controls if we sent the form
  successCancelation: boolean; // Boolean which controls if the cancelation has occurred.
  cancelSellForm: FormGroup; // Form variable.

  // Observable variables to control the Form Inputs
  @ViewChild('btmlForm') btmlForm: IonInput; // BTML.
  // @ViewChild('BTMLTypeForm') BTMLTypeForm: IonInput;  // BTML Type.
  @ViewChild('cidForm') cidForm: IonInput; // CID.

  // Variables to access to the user identity
  bpIDPubKey: string;
  identity: UserStorageModels.Identities;
  iden = new BehaviorSubject(this.core.auth.userStorage?.identities);

  @Input()
  set data(value: { bitpass?: Bitpass<any>; bmtl?: string }) {
    if (value.bitpass) {
      this.bitPass = value.bitpass;
    }
  }

  constructor(public core: CoreProvider) {}

  ngOnInit() {
    // We assign a default value for BTML Type.
    // this.BTMLType = this.bitPass?.__selfBtml.substring(0, 5);
    // Instance the form
    this.cancelSellForm = new FormGroup({
      btml: new FormControl(this.bitPass?.__selfBtml, [Validators.required]),
      cid: new FormControl(this.bitPass?.__selfCid, [Validators.required]),
      // BTMLType: new FormControl(this.BTMLType, [Validators.required]),
    });
    // Initial values.
    this.urlBPid = 'app://bp/' + this.bitPass?.__selfBtml;
    this.btmlNewId = this.bitPass?.__selfBtml;
    this.updateOwnerBTML();
    this.precioVenta = this.bitPass?.__data[0].val.soldInfo?.price;

    // It will get the value of the identity of the user (identity variable).
    this.iden.subscribe({
      next: (res) => {
        this.identity = res;
        this.fetchBitpass(res.publicID.bp_id?.slice(0, 10)).subscribe({
          next: (res) => {
            if (res) {
              this.bpIDPubKey = res.__data[0].val.specific.specific.publicKey;
            }
          },
          error: (err) => {},
        });
      },
    });
  }

  //Variables used to manage BTML Type variable.
  // getBTMLType() {
  //   return Number(this.BTMLType);
  // }
  // setBTMLType(value: string) {
  //   this.BTMLType = value;
  // }

  // Will check if user has edited BTML Field in order to show Update button.
  checkEditedBTML() {
    return this.btmlForm?.value == this.btmlNewId;
  }

  // Will check if the product is for sale or not.
  checkProductIsSelling() {
    return this.precioVenta > 0;
  }

  // Will check if the current user is the owner of the product.
  checkProductOwner() {
    this.checkEditedBTML();
    return (
      this.ownerBTML == this.identity?.publicID?.bp_id.slice(0, 10) &&
      this.ownerBTML != undefined
    );
  }

  // If the product is for sale and the current user is the product owner then formValid will be true so sellForm will be able to be submitted.
  formValid() {
    return this.checkProductIsSelling() && this.checkProductOwner();
  }

  async openBitpassSelection() {
    this.changingBP = !this.changingBP;
    await new Promise((f) => setTimeout(f, 100));
    // this.BTMLTypeForm.value=this.BTMLType
  }

  // Variable called whenever we update the BTML. It will obtain information about the new product owner.
  updateOwnerBTML() {
    // Real ownerBTML (is node identity while in sold process)
    // this.fetchBitpass('5213000000').subscribe((res) => {
    //   this.registerBook = res;
    //   if (this.registerBook) {
    //     var i = 0;
    //     this.ownerBTML = 'Verificando';
    //     // Iterate over bpKeyIndex
    //     Object.keys(
    //       this.registerBook.__data[0].val.specific.specific.specific.specific
    //         .bpKeyIndex
    //     ).forEach((element) => {
    //       // If the current iteration element is equals to the current product btml look for this element/product
    //       if (element == '' + this.btmlNewId) {
    //         this.fetchBitpass(
    //           Object.values(
    //             this.registerBook.__data[0].val.specific.specific.specific
    //               .specific.bpKeyIndex
    //           )[i] + ''
    //         ).subscribe((result) => {
    //           this.transferRegister = result;
    //           if (this.transferRegister) {
    //             this.ownerBTML =
    //               this.transferRegister.__data[0].val.specific.specific.specific
    //                 .specific.allowedIdentity + '';
    //           }
    //         });
    //       }
    //       i++;
    //     });
    //   }
    // });

    // Sale info oldID
    this.ownerBTML = this.bitPass.__data[0].val.soldInfo.oldID;
  }

  // Method used by the app-choose-bp (choose Bitpass).
  chooseBP(e) {
    this.changingBP = true;
    this.btmlNewId = e;
  }

  // Method which will be called everytime we move to another Bitpass.
  async updateBTML(btml = this.btmlNewId) {
    this.ownerBTML = undefined;
    this.btmlNewId = btml;
    this.changingBP = false;
    // this.BTMLType=this.btmlNewId.substring(0,5)
    // console.log(this.BTMLType)

    //We must do this wait in order to let the html refresh so inputs will not be seen as "null" in code.
    await new Promise((f) => setTimeout(f, 500));

    this.urlBPid = 'app://bp/' + btml;

    this.btmlForm.value = btml;
    this.updateOwnerBTML();
    this.fetchBitpass(this.urlBPid).subscribe({
      next: (res) => {
        if (res) {
          this.precioVenta = res.__data[0].val.soldInfo?.price;
          this.cidForm.value = res.data_cid.toString();
        }
      },
      error: (err) => {},
    });
  }

  // Method called by the form button. It will cancel the sale.
  cancelSale() {
    this.submitted = true;
    if (this.cancelSellForm.valid && this.formValid) {
      var json = {
        btml: this.cancelSellForm.controls['btml'].value,
        signedCid: this.cancelSellForm.controls['cid'].value,
        signature: '',
      };

      this.core.api.verify
        .signMessage({
          body: {
            data: json.btml + json.signedCid,
            privateKey:
              this.core.auth.userStorage.identities.privateID.privkeyPGP,
          },
        })
        .subscribe((res) => {
          if (res) json.signature = res.signedMessage;
          this.core.api.agram
            .withdrawBP({ worker: 'general_worker_server', body: json })
            .subscribe({
              next: (res) => {
                if (res) this.page = 2;
                this.successCancelation = true;
              },
              error: (err) => {
                this.page = 2;
                this.successCancelation = false;
              },
            });
        });
    }
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps
        .fetchBitpass(btml, Bitpass)
        .then((bp) => {
          this.bpCache[btml].next(bp.product);
        })
        .catch((err) => {
          console.error('Cannot fetch minipass for url ' + btml);
        });
    }
    return this.bpCache[btml];
  }

  async presentAlertMultipleButtons() {
    const alert = await this.core.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Esta seguro que desea retirar este producto de la venta?',
      buttons: [
        {
          text: 'Retirar producto',
          cssClass: 'other-button',
          id: 'discard-button',
          handler: () => {
            this.cancelSale();
          },
        },
        {
          text: 'Volver',
          role: 'discard',
          cssClass: 'cta-button',
          id: 'continue-button',
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }
}
