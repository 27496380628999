/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { BodyApiTopupBalanceAdminApiV1TopupPut } from '../models/body-api-topup-balance-admin-api-v-1-topup-put';
import { Callback } from '../models/callback';
import { ConversionData } from '../models/conversion-data';
import { CreateExtension } from '../models/create-extension';
import { CreateLnurlData } from '../models/create-lnurl-data';
import { DecodePayment } from '../models/decode-payment';
import { EditableSettings } from '../models/editable-settings';
import { Page } from '../models/page';
import { Payment } from '../models/payment';
import { LnbitsCoreViewsApiCreateInvoiceData } from '../models/lnbits-core-views-api-create-invoice-data';

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAuditorAdminApiV1AuditGet()` */
  static readonly ApiAuditorAdminApiV1AuditGetPath = '/admin/api/v1/audit';

  /**
   * Api Auditor.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAuditorAdminApiV1AuditGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditorAdminApiV1AuditGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiAuditorAdminApiV1AuditGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Auditor.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAuditorAdminApiV1AuditGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAuditorAdminApiV1AuditGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiAuditorAdminApiV1AuditGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiGetSettingsAdminApiV1SettingsGet()` */
  static readonly ApiGetSettingsAdminApiV1SettingsGetPath = '/admin/api/v1/settings/';

  /**
   * Api Get Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGetSettingsAdminApiV1SettingsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetSettingsAdminApiV1SettingsGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiGetSettingsAdminApiV1SettingsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Get Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGetSettingsAdminApiV1SettingsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetSettingsAdminApiV1SettingsGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiGetSettingsAdminApiV1SettingsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiUpdateSettingsAdminApiV1SettingsPut()` */
  static readonly ApiUpdateSettingsAdminApiV1SettingsPutPath = '/admin/api/v1/settings/';

  /**
   * Api Update Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpdateSettingsAdminApiV1SettingsPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUpdateSettingsAdminApiV1SettingsPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: EditableSettings
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUpdateSettingsAdminApiV1SettingsPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Update Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUpdateSettingsAdminApiV1SettingsPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUpdateSettingsAdminApiV1SettingsPut(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: EditableSettings
    },
    context?: HttpContext
): Observable<any> {
    return this.apiUpdateSettingsAdminApiV1SettingsPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDeleteSettingsAdminApiV1SettingsDelete()` */
  static readonly ApiDeleteSettingsAdminApiV1SettingsDeletePath = '/admin/api/v1/settings/';

  /**
   * Api Delete Settings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDeleteSettingsAdminApiV1SettingsDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDeleteSettingsAdminApiV1SettingsDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiDeleteSettingsAdminApiV1SettingsDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Delete Settings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDeleteSettingsAdminApiV1SettingsDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDeleteSettingsAdminApiV1SettingsDelete(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDeleteSettingsAdminApiV1SettingsDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiRestartServerAdminApiV1RestartGet()` */
  static readonly ApiRestartServerAdminApiV1RestartGetPath = '/admin/api/v1/restart/';

  /**
   * Api Restart Server.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRestartServerAdminApiV1RestartGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRestartServerAdminApiV1RestartGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiRestartServerAdminApiV1RestartGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Restart Server.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiRestartServerAdminApiV1RestartGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRestartServerAdminApiV1RestartGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiRestartServerAdminApiV1RestartGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTopupBalanceAdminApiV1TopupPut()` */
  static readonly ApiTopupBalanceAdminApiV1TopupPutPath = '/admin/api/v1/topup/';

  /**
   * Api Topup Balance.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTopupBalanceAdminApiV1TopupPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTopupBalanceAdminApiV1TopupPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: BodyApiTopupBalanceAdminApiV1TopupPut
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTopupBalanceAdminApiV1TopupPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Topup Balance.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTopupBalanceAdminApiV1TopupPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTopupBalanceAdminApiV1TopupPut(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: BodyApiTopupBalanceAdminApiV1TopupPut
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTopupBalanceAdminApiV1TopupPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDownloadBackupAdminApiV1BackupGet()` */
  static readonly ApiDownloadBackupAdminApiV1BackupGetPath = '/admin/api/v1/backup/';

  /**
   * Api Download Backup.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDownloadBackupAdminApiV1BackupGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDownloadBackupAdminApiV1BackupGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiDownloadBackupAdminApiV1BackupGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Api Download Backup.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDownloadBackupAdminApiV1BackupGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDownloadBackupAdminApiV1BackupGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<void> {
    return this.apiDownloadBackupAdminApiV1BackupGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `healthApiV1HealthGet()` */
  static readonly HealthApiV1HealthGetPath = '/api/v1/health';

  /**
   * Health.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `healthApiV1HealthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthApiV1HealthGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.HealthApiV1HealthGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Health.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `healthApiV1HealthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  healthApiV1HealthGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.healthApiV1HealthGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiWalletApiV1WalletGet()` */
  static readonly ApiWalletApiV1WalletGetPath = '/api/v1/wallet';

  /**
   * Api Wallet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWalletApiV1WalletGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletApiV1WalletGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiWalletApiV1WalletGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Wallet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWalletApiV1WalletGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletApiV1WalletGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiWalletApiV1WalletGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiUpdateWalletApiV1WalletNewNamePut()` */
  static readonly ApiUpdateWalletApiV1WalletNewNamePutPath = '/api/v1/wallet/{new_name}';

  /**
   * Api Update Wallet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpdateWalletApiV1WalletNewNamePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpdateWalletApiV1WalletNewNamePut$Response(
    chain: string,
    token: string | undefined,
    params: {
      new_name: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUpdateWalletApiV1WalletNewNamePutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('new_name', params.new_name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Update Wallet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUpdateWalletApiV1WalletNewNamePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpdateWalletApiV1WalletNewNamePut(
    chain: string,
    token: string | undefined,
    params: {
      new_name: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiUpdateWalletApiV1WalletNewNamePut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `paymentListApiV1PaymentsGet()` */
  static readonly PaymentListApiV1PaymentsGetPath = '/api/v1/payments';

  /**
   * get list of payments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentListApiV1PaymentsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentListApiV1PaymentsGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      checking_id?: string;

    /**
     * Supports Filtering. Supports Search
     */
      amount?: number;

    /**
     * Supports Filtering
     */
      fee?: number;

    /**
     * Supports Filtering. Supports Search
     */
      memo?: string;

    /**
     * Supports Filtering
     */
      time?: string;

    /**
     * Supports Filtering
     */
      bolt11?: string;

    /**
     * Supports Filtering
     */
      preimage?: string;

    /**
     * Supports Filtering
     */
      payment_hash?: string;

    /**
     * Supports Filtering
     */
      expiry?: string;

    /**
     * Supports Filtering
     */
      extra?: {
};

    /**
     * Supports Filtering
     */
      wallet_id?: string;

    /**
     * Supports Filtering
     */
      webhook?: string;

    /**
     * Supports Filtering
     */
      webhook_status?: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Array<Payment>>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.PaymentListApiV1PaymentsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortby', params.sortby, {});
      rb.query('direction', params.direction, {});
      rb.query('search', params.search, {});
      rb.query('checking_id', params.checking_id, {});
      rb.query('amount', params.amount, {});
      rb.query('fee', params.fee, {});
      rb.query('memo', params.memo, {});
      rb.query('time', params.time, {});
      rb.query('bolt11', params.bolt11, {});
      rb.query('preimage', params.preimage, {});
      rb.query('payment_hash', params.payment_hash, {});
      rb.query('expiry', params.expiry, {});
      rb.query('extra', params.extra, {});
      rb.query('wallet_id', params.wallet_id, {});
      rb.query('webhook', params.webhook, {});
      rb.query('webhook_status', params.webhook_status, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }
  /**
   * get list of payments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentListApiV1PaymentsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentListApiV1PaymentsGet(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      checking_id?: string;

    /**
     * Supports Filtering. Supports Search
     */
      amount?: number;

    /**
     * Supports Filtering
     */
      fee?: number;

    /**
     * Supports Filtering. Supports Search
     */
      memo?: string;

    /**
     * Supports Filtering
     */
      time?: string;

    /**
     * Supports Filtering
     */
      bolt11?: string;

    /**
     * Supports Filtering
     */
      preimage?: string;

    /**
     * Supports Filtering
     */
      payment_hash?: string;

    /**
     * Supports Filtering
     */
      expiry?: string;

    /**
     * Supports Filtering
     */
      extra?: {
};

    /**
     * Supports Filtering
     */
      wallet_id?: string;

    /**
     * Supports Filtering
     */
      webhook?: string;

    /**
     * Supports Filtering
     */
      webhook_status?: number;
    },
    context?: HttpContext
): Observable<Array<Payment>> {
    return this.paymentListApiV1PaymentsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>): Array<Payment> => r.body)
    );
  }
  /** Path part for operation `apiPaymentsCreateApiV1PaymentsPost()` */
  static readonly ApiPaymentsCreateApiV1PaymentsPostPath = '/api/v1/payments';

  /**
   * Api Payments Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentsCreateApiV1PaymentsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsCreateApiV1PaymentsPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: LnbitsCoreViewsApiCreateInvoiceData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPaymentsCreateApiV1PaymentsPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Payments Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentsCreateApiV1PaymentsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsCreateApiV1PaymentsPost(
    chain: string,
    token: string | undefined,
    params: {
      body: LnbitsCoreViewsApiCreateInvoiceData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPaymentsCreateApiV1PaymentsPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `paymentListApiV1PaymentsPaginatedGet()` */
  static readonly PaymentListApiV1PaymentsPaginatedGetPath = '/api/v1/payments/paginated';

  /**
   * get paginated list of payments.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paymentListApiV1PaymentsPaginatedGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentListApiV1PaymentsPaginatedGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      checking_id?: string;

    /**
     * Supports Filtering. Supports Search
     */
      amount?: number;

    /**
     * Supports Filtering
     */
      fee?: number;

    /**
     * Supports Filtering. Supports Search
     */
      memo?: string;

    /**
     * Supports Filtering
     */
      time?: string;

    /**
     * Supports Filtering
     */
      bolt11?: string;

    /**
     * Supports Filtering
     */
      preimage?: string;

    /**
     * Supports Filtering
     */
      payment_hash?: string;

    /**
     * Supports Filtering
     */
      expiry?: string;

    /**
     * Supports Filtering
     */
      extra?: {
};

    /**
     * Supports Filtering
     */
      wallet_id?: string;

    /**
     * Supports Filtering
     */
      webhook?: string;

    /**
     * Supports Filtering
     */
      webhook_status?: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Page>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.PaymentListApiV1PaymentsPaginatedGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortby', params.sortby, {});
      rb.query('direction', params.direction, {});
      rb.query('search', params.search, {});
      rb.query('checking_id', params.checking_id, {});
      rb.query('amount', params.amount, {});
      rb.query('fee', params.fee, {});
      rb.query('memo', params.memo, {});
      rb.query('time', params.time, {});
      rb.query('bolt11', params.bolt11, {});
      rb.query('preimage', params.preimage, {});
      rb.query('payment_hash', params.payment_hash, {});
      rb.query('expiry', params.expiry, {});
      rb.query('extra', params.extra, {});
      rb.query('wallet_id', params.wallet_id, {});
      rb.query('webhook', params.webhook, {});
      rb.query('webhook_status', params.webhook_status, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Page>;
      })
    );
  }
  /**
   * get paginated list of payments.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paymentListApiV1PaymentsPaginatedGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paymentListApiV1PaymentsPaginatedGet(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      checking_id?: string;

    /**
     * Supports Filtering. Supports Search
     */
      amount?: number;

    /**
     * Supports Filtering
     */
      fee?: number;

    /**
     * Supports Filtering. Supports Search
     */
      memo?: string;

    /**
     * Supports Filtering
     */
      time?: string;

    /**
     * Supports Filtering
     */
      bolt11?: string;

    /**
     * Supports Filtering
     */
      preimage?: string;

    /**
     * Supports Filtering
     */
      payment_hash?: string;

    /**
     * Supports Filtering
     */
      expiry?: string;

    /**
     * Supports Filtering
     */
      extra?: {
};

    /**
     * Supports Filtering
     */
      wallet_id?: string;

    /**
     * Supports Filtering
     */
      webhook?: string;

    /**
     * Supports Filtering
     */
      webhook_status?: number;
    },
    context?: HttpContext
): Observable<Page> {
    return this.paymentListApiV1PaymentsPaginatedGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Page>): Page => r.body)
    );
  }
  /** Path part for operation `apiPaymentsPayLnurlApiV1PaymentsLnurlPost()` */
  static readonly ApiPaymentsPayLnurlApiV1PaymentsLnurlPostPath = '/api/v1/payments/lnurl';

  /**
   * Api Payments Pay Lnurl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentsPayLnurlApiV1PaymentsLnurlPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsPayLnurlApiV1PaymentsLnurlPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateLnurlData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPaymentsPayLnurlApiV1PaymentsLnurlPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Payments Pay Lnurl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentsPayLnurlApiV1PaymentsLnurlPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsPayLnurlApiV1PaymentsLnurlPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateLnurlData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPaymentsPayLnurlApiV1PaymentsLnurlPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPaymentsSseApiV1PaymentsSseGet()` */
  static readonly ApiPaymentsSseApiV1PaymentsSseGetPath = '/api/v1/payments/sse';

  /**
   * Api Payments Sse.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentsSseApiV1PaymentsSseGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentsSseApiV1PaymentsSseGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPaymentsSseApiV1PaymentsSseGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Payments Sse.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentsSseApiV1PaymentsSseGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentsSseApiV1PaymentsSseGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPaymentsSseApiV1PaymentsSseGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPaymentApiV1PaymentsPaymentHashGet()` */
  static readonly ApiPaymentApiV1PaymentsPaymentHashGetPath = '/api/v1/payments/{payment_hash}';

  /**
   * Api Payment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentApiV1PaymentsPaymentHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentApiV1PaymentsPaymentHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
      'X-Api-Key'?: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPaymentApiV1PaymentsPaymentHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('payment_hash', params.payment_hash, {});
      rb.header('X-Api-Key', params['X-Api-Key'], {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Payment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentApiV1PaymentsPaymentHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentApiV1PaymentsPaymentHashGet(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
      'X-Api-Key'?: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPaymentApiV1PaymentsPaymentHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLnurlscanApiV1LnurlscanCodeGet()` */
  static readonly ApiLnurlscanApiV1LnurlscanCodeGetPath = '/api/v1/lnurlscan/{code}';

  /**
   * Api Lnurlscan.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLnurlscanApiV1LnurlscanCodeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLnurlscanApiV1LnurlscanCodeGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      code: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiLnurlscanApiV1LnurlscanCodeGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('code', params.code, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Lnurlscan.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLnurlscanApiV1LnurlscanCodeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLnurlscanApiV1LnurlscanCodeGet(
    chain: string,
    token: string | undefined,
    params: {
      code: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLnurlscanApiV1LnurlscanCodeGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPaymentsDecodeApiV1PaymentsDecodePost()` */
  static readonly ApiPaymentsDecodeApiV1PaymentsDecodePostPath = '/api/v1/payments/decode';

  /**
   * Api Payments Decode.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentsDecodeApiV1PaymentsDecodePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsDecodeApiV1PaymentsDecodePost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: DecodePayment
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPaymentsDecodeApiV1PaymentsDecodePostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Payments Decode.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentsDecodeApiV1PaymentsDecodePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPaymentsDecodeApiV1PaymentsDecodePost(
    chain: string,
    token: string | undefined,
    params: {
      body: DecodePayment
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPaymentsDecodeApiV1PaymentsDecodePost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPerformLnurlauthApiV1LnurlauthPost()` */
  static readonly ApiPerformLnurlauthApiV1LnurlauthPostPath = '/api/v1/lnurlauth';

  /**
   * Api Perform Lnurlauth.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPerformLnurlauthApiV1LnurlauthPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPerformLnurlauthApiV1LnurlauthPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: Callback
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPerformLnurlauthApiV1LnurlauthPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Perform Lnurlauth.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPerformLnurlauthApiV1LnurlauthPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPerformLnurlauthApiV1LnurlauthPost(
    chain: string,
    token: string | undefined,
    params: {
      body: Callback
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPerformLnurlauthApiV1LnurlauthPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiListCurrenciesAvailableApiV1CurrenciesGet()` */
  static readonly ApiListCurrenciesAvailableApiV1CurrenciesGetPath = '/api/v1/currencies';

  /**
   * Api List Currencies Available.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiListCurrenciesAvailableApiV1CurrenciesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiListCurrenciesAvailableApiV1CurrenciesGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiListCurrenciesAvailableApiV1CurrenciesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api List Currencies Available.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiListCurrenciesAvailableApiV1CurrenciesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiListCurrenciesAvailableApiV1CurrenciesGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiListCurrenciesAvailableApiV1CurrenciesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiFiatAsSatsApiV1ConversionPost()` */
  static readonly ApiFiatAsSatsApiV1ConversionPostPath = '/api/v1/conversion';

  /**
   * Api Fiat As Sats.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFiatAsSatsApiV1ConversionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiFiatAsSatsApiV1ConversionPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: ConversionData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiFiatAsSatsApiV1ConversionPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Fiat As Sats.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFiatAsSatsApiV1ConversionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiFiatAsSatsApiV1ConversionPost(
    chain: string,
    token: string | undefined,
    params: {
      body: ConversionData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiFiatAsSatsApiV1ConversionPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `imgApiV1QrcodeDataGet()` */
  static readonly ImgApiV1QrcodeDataGetPath = '/api/v1/qrcode/{data}';

  /**
   * Img.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imgApiV1QrcodeDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  imgApiV1QrcodeDataGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      data: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ImgApiV1QrcodeDataGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('data', params.data, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Img.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imgApiV1QrcodeDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  imgApiV1QrcodeDataGet(
    chain: string,
    token: string | undefined,
    params: {
      data: any;
    },
    context?: HttpContext
): Observable<void> {
    return this.imgApiV1QrcodeDataGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `websocketUpdatePostApiV1WsItemIdPost()` */
  static readonly WebsocketUpdatePostApiV1WsItemIdPostPath = '/api/v1/ws/{item_id}';

  /**
   * Websocket Update Post.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websocketUpdatePostApiV1WsItemIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  websocketUpdatePostApiV1WsItemIdPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      item_id: string;
      data: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.WebsocketUpdatePostApiV1WsItemIdPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('item_id', params.item_id, {});
      rb.query('data', params.data, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Websocket Update Post.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websocketUpdatePostApiV1WsItemIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websocketUpdatePostApiV1WsItemIdPost(
    chain: string,
    token: string | undefined,
    params: {
      item_id: string;
      data: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.websocketUpdatePostApiV1WsItemIdPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `websocketUpdateGetApiV1WsItemIdDataGet()` */
  static readonly WebsocketUpdateGetApiV1WsItemIdDataGetPath = '/api/v1/ws/{item_id}/{data}';

  /**
   * Websocket Update Get.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `websocketUpdateGetApiV1WsItemIdDataGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  websocketUpdateGetApiV1WsItemIdDataGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      item_id: string;
      data: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.WebsocketUpdateGetApiV1WsItemIdDataGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('item_id', params.item_id, {});
      rb.path('data', params.data, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Websocket Update Get.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `websocketUpdateGetApiV1WsItemIdDataGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  websocketUpdateGetApiV1WsItemIdDataGet(
    chain: string,
    token: string | undefined,
    params: {
      item_id: string;
      data: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.websocketUpdateGetApiV1WsItemIdDataGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInstallExtensionApiV1ExtensionPost()` */
  static readonly ApiInstallExtensionApiV1ExtensionPostPath = '/api/v1/extension';

  /**
   * Api Install Extension.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInstallExtensionApiV1ExtensionPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInstallExtensionApiV1ExtensionPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: CreateExtension
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiInstallExtensionApiV1ExtensionPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Install Extension.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInstallExtensionApiV1ExtensionPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInstallExtensionApiV1ExtensionPost(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
      body: CreateExtension
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInstallExtensionApiV1ExtensionPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiUninstallExtensionApiV1ExtensionExtIdDelete()` */
  static readonly ApiUninstallExtensionApiV1ExtensionExtIdDeletePath = '/api/v1/extension/{ext_id}';

  /**
   * Api Uninstall Extension.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUninstallExtensionApiV1ExtensionExtIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUninstallExtensionApiV1ExtensionExtIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiUninstallExtensionApiV1ExtensionExtIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('ext_id', params.ext_id, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Uninstall Extension.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUninstallExtensionApiV1ExtensionExtIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUninstallExtensionApiV1ExtensionExtIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiUninstallExtensionApiV1ExtensionExtIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `getExtensionReleasesApiV1ExtensionExtIdReleasesGet()` */
  static readonly GetExtensionReleasesApiV1ExtensionExtIdReleasesGetPath = '/api/v1/extension/{ext_id}/releases';

  /**
   * Get Extension Releases.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtensionReleasesApiV1ExtensionExtIdReleasesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionReleasesApiV1ExtensionExtIdReleasesGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetExtensionReleasesApiV1ExtensionExtIdReleasesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('ext_id', params.ext_id, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Get Extension Releases.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtensionReleasesApiV1ExtensionExtIdReleasesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionReleasesApiV1ExtensionExtIdReleasesGet(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.getExtensionReleasesApiV1ExtensionExtIdReleasesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet()` */
  static readonly GetExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGetPath = '/api/v1/extension/release/{org}/{repo}/{tag_name}';

  /**
   * Get Extension Release.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      org: string;
      repo: string;
      tag_name: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.GetExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('org', params.org, {});
      rb.path('repo', params.repo, {});
      rb.path('tag_name', params.tag_name, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Get Extension Release.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet(
    chain: string,
    token: string | undefined,
    params: {
      org: string;
      repo: string;
      tag_name: string;
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.getExtensionReleaseApiV1ExtensionReleaseOrgRepoTagNameGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `deleteExtensionDbApiV1ExtensionExtIdDbDelete()` */
  static readonly DeleteExtensionDbApiV1ExtensionExtIdDbDeletePath = '/api/v1/extension/{ext_id}/db';

  /**
   * Delete Extension Db.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteExtensionDbApiV1ExtensionExtIdDbDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExtensionDbApiV1ExtensionExtIdDbDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.DeleteExtensionDbApiV1ExtensionExtIdDbDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('ext_id', params.ext_id, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Delete Extension Db.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteExtensionDbApiV1ExtensionExtIdDbDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteExtensionDbApiV1ExtensionExtIdDbDelete(
    chain: string,
    token: string | undefined,
    params: {
      ext_id: string;
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.deleteExtensionDbApiV1ExtensionExtIdDbDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiCreateTinyurlApiV1TinyurlPost()` */
  static readonly ApiCreateTinyurlApiV1TinyurlPostPath = '/api/v1/tinyurl';

  /**
   * Api Create Tinyurl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreateTinyurlApiV1TinyurlPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreateTinyurlApiV1TinyurlPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      url: string;
      endless?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiCreateTinyurlApiV1TinyurlPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('url', params.url, {});
      rb.query('endless', params.endless, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Create Tinyurl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreateTinyurlApiV1TinyurlPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreateTinyurlApiV1TinyurlPost(
    chain: string,
    token: string | undefined,
    params: {
      url: string;
      endless?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiCreateTinyurlApiV1TinyurlPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiGetTinyurlApiV1TinyurlTinyurlIdGet()` */
  static readonly ApiGetTinyurlApiV1TinyurlTinyurlIdGetPath = '/api/v1/tinyurl/{tinyurl_id}';

  /**
   * Api Get Tinyurl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGetTinyurlApiV1TinyurlTinyurlIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetTinyurlApiV1TinyurlTinyurlIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiGetTinyurlApiV1TinyurlTinyurlIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tinyurl_id', params.tinyurl_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Get Tinyurl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGetTinyurlApiV1TinyurlTinyurlIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetTinyurlApiV1TinyurlTinyurlIdGet(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiGetTinyurlApiV1TinyurlTinyurlIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete()` */
  static readonly ApiDeleteTinyurlApiV1TinyurlTinyurlIdDeletePath = '/api/v1/tinyurl/{tinyurl_id}';

  /**
   * Api Delete Tinyurl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiDeleteTinyurlApiV1TinyurlTinyurlIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tinyurl_id', params.tinyurl_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Delete Tinyurl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDeleteTinyurlApiV1TinyurlTinyurlIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTinyurlTTinyurlIdGet()` */
  static readonly ApiTinyurlTTinyurlIdGetPath = '/t/{tinyurl_id}';

  /**
   * Api Tinyurl.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTinyurlTTinyurlIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTinyurlTTinyurlIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiTinyurlTTinyurlIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tinyurl_id', params.tinyurl_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tinyurl.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTinyurlTTinyurlIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTinyurlTTinyurlIdGet(
    chain: string,
    token: string | undefined,
    params: {
      tinyurl_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTinyurlTTinyurlIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet()` */
  static readonly ApiPublicPaymentLongpollingPublicV1PaymentPaymentHashGetPath = '/public/v1/payment/{payment_hash}';

  /**
   * Api Public Payment Longpolling.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, ApiService.ApiPublicPaymentLongpollingPublicV1PaymentPaymentHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('payment_hash', params.payment_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Public Payment Longpolling.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPublicPaymentLongpollingPublicV1PaymentPaymentHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
