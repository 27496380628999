import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IonInput } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { BuyBpRequestDto } from 'src/app/services/api/models';
import { coinBitpass } from 'src/app/services/coin-bitpass';
import { CoreProvider } from 'src/app/services/core';
import { PaymentModalComponent } from '../payment-modal/payment-modal.component';

@Component({
  selector: 'app-buy-bp-modal',
  templateUrl: './buy-bp-modal.component.html',
  styleUrls: ['./buy-bp-modal.component.scss'],
})
export class BuyBpModalComponent implements OnInit {
  changingBP: boolean = false;
  btmlType: string;
  urlBPid: string;
  btmlNewId: string; // The New BTML which user will be moved to whenever he clicks "Change the BP"
  precioVenta: number;
  formValid: boolean;
  buyForm: FormGroup;

  // Formularios
  bitPass: Bitpass<any>;
  buyInfo: BuyBpRequestDto;
  chosenBtml: string;
  records = null;
  bitPasses: Bitpass<any>[] = [];
  coinBitPasses: Bitpass<any>[] = [];
  receiverBtmlID: string;
  activeSale: boolean;

  @ViewChild('btmlForm') btmlForm: IonInput; // BTML.
  @ViewChild('btmlTypeForm') btmlTypeForm: IonInput; // BTML Type.

  @Input('ngModel')
  set data(value: { bitpass: Bitpass<any> }) {
    this.bitPass = value.bitpass;
    this.chosenBtml = '' + this.bitPass.__selfBtml;
  }

  constructor(public core: CoreProvider) {}

  ngOnInit() {
    this.activeSale = this.bitPass.__data[0].val.soldInfo?.activeSold
      ? true
      : false;
    this.btmlType = this.bitPass?.__selfBtml.substring(0, 5);
    this.urlBPid = 'app://bp/' + this.bitPass?.__selfBtml;
    this.btmlNewId = this.bitPass?.__selfBtml;
    this.precioVenta = this.bitPass?.__data[0].val.soldInfo?.price;
    this.updateFormValid();
    this.buyForm = new FormGroup({
      btml: new FormControl(this.bitPass?.__selfBtml, [Validators.required]),
      btmlType: new FormControl(this.bitPass?.__selfBtml.substring(0, 5), [
        Validators.required,
      ]),
    });
    this.core.getCoinList().forEach((element) => {
      this.fetchBitpass(element).subscribe((value) => {
        if (value) {
          this.coinBitPasses.push(value);
        }
      });
    });
  }

  getBTMLType() {
    return Number(this.btmlType);
  }
  setBTMLType(value: string) {
    this.btmlType = value;
  }

  checkEditedBTML() {
    return this.btmlForm?.value == this.btmlNewId;
  }

  async updateBTML(BTMLid = this.btmlNewId) {
    this.btmlNewId = BTMLid;
    this.changingBP = false;
    this.btmlType = this.btmlNewId.substring(0, 5);
    this.updateFormValid();

    //We must do this wait in order to let the html refresh so inputs will not be seen as "null" in code.
    await new Promise((f) => setTimeout(f, 500));

    this.urlBPid = 'app://bp/' + BTMLid;

    this.btmlForm.value = BTMLid;
    this.fetchBitpass(this.urlBPid).subscribe({
      next: (res) => {
        if (res) {
          this.activeSale = res.__data[0].val.soldInfo?.activeSold
            ? true
            : false;
          this.precioVenta = res.__data[0].val.soldInfo?.price;
          // this.CIDForm.value = res.data_cid.toString();
        }
      },
      error: (err) => {},
    });
  }

  // Method used by the app-choose-bp (choose Bitpass).
  chooseBP(e) {
    this.changingBP = true;
    this.btmlNewId = e;
  }

  // Will check if the product is for sale or not.
  // checkProductIsSelling() {
  //   return (this.precioVenta>0)
  // }

  async openBitpassSelection() {
    this.changingBP = !this.changingBP;
    await new Promise((f) => setTimeout(f, 100));
    this.btmlTypeForm.value = this.btmlType;
  }

  // Will check if the product is for sale or not.
  checkProductIsSelling() {
    return this.precioVenta > 0;
  }

  // If the product is for sale and the current user is the product owner then formValid will be true so sellForm will be able to be submitted.
  updateFormValid() {
    this.formValid = this.checkProductIsSelling() && !this.activeSale;
    console.log('Formulario value: ' + this.formValid);
  }

  buy(): Promise<any> {
    return new Promise((ok, ko) => {
      this.assignOwnIdentity();
      this.buyInfo = {
        btml: this.chosenBtml,
        signedCid: this.bitPass?.__selfCid,
        receiverBtmlID: this.receiverBtmlID,
        signature: '',
      };
      console.log(this.buyInfo);

      this.core.api.verify
        .signMessage({
          body: {
            data:
              this.buyInfo.btml +
              this.buyInfo.signedCid +
              this.buyInfo.receiverBtmlID,
            privateKey:
              this.core.auth.userStorage.identities.privateID.privkeyPGP,
          },
        })
        .subscribe((res) => {
          if (res) {
            console.log(res);
            this.buyInfo.signature = res.signedMessage;
            this.core.api.agram
              .buyBP({ worker: 'general_worker_server', body: this.buyInfo })
              .subscribe((res) => {
                console.log(res);
                ok(res);
              });
          }
        });
    });
  }

  async buyBPConfirm() {
    const alert = await this.core.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Esta seguro que quiere comprar este producto?',
      buttons: [
        {
          text: 'Comprar producto',
          id: 'discard-button',
          handler: () => {
            this.buy().then((res) => console.log(res));
          },
        },
        {
          text: 'Comprar y pagar',
          role: 'discard',
          id: 'payment-button',
          handler: () => {
            this.buy().then((address) => this.openModal(address));
          },
        },
        {
          text: 'Volver',
          role: 'discard',
          id: 'continue-button',
          handler: () => {},
        },
      ],
    });
    await alert.present();
  }

  openModal(address: string) {
    let coinBtml = this.btmlFromChain();
    var tokenType: string;

    Object.entries(coinBitpass.blockchain).forEach(([key, value]) => {
      value.some((element) => {
        if (element.used && element.btml == coinBtml) {
          tokenType = key;
        }
      });
    });

    let soldInfo = this.bitPass.__data[0].val.soldInfo;
    address =
      address +
      '?amount=' +
      (soldInfo.price + soldInfo.networkFee + soldInfo.workerFee);
    this.core.modalCtrl.dismiss();
    this.core.modalCtrl
      .create({
        component: PaymentModalComponent,
        cssClass: 'fullscreenModal',
        componentProps: {
          data: {
            address: address,
            tokenType: tokenType,
            payType: 'blockchain',
            bitpass: this.bitPass,
          },
        },
      })
      .then((modal) => {
        modal.present();
      });
  }

  chooseBtml(btml) {
    this.chosenBtml = btml;
    setTimeout(() => {
      this.core.popoverCtrl.dismiss();
    }, 500);
  }

  assignOwnIdentity() {
    this.receiverBtmlID = this.core.auth.userStorage.identities.publicID.bp_id?.slice(0, 10);
  }

  refreshRecords(type: string) {
    this.records = null;
    this.bitPasses = [];

    this.core.api.btml
      .lasttenByType({ address: 'address_server', btml_type: type })
      .subscribe({
        next: (res: any) => {
          // TODO: Filter selecting greater versions
          res.records = Object.values(
            res.records.reduce((base, current) => {
              const btml = current.key.includes('v')
                ? current.key.split('v')[1]
                : current.key;
              const ver = current.key.includes('v')
                ? current.key.split('v')[0]
                : current.key;
              if (!base[btml] || base[btml].ver < ver) {
                base[btml] = { itm: current, ver };
              }
              return base;
            }, {})
          ).map((obj: any) => obj.itm);
          this.records = res;

          this.records.records.forEach((element) => {
            this.fetchBitpass(element.key.split('v')[1]).subscribe((res) => {
              if (res) this.bitPasses.push(res);
            });
          });
        },
        error: (err) => {
          console.error('error fetching records', err);
          this.records = false;
        },
      });
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }
    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps
        .fetchBitpass(btml, Bitpass)
        .then((bp) => {
          this.bpCache[btml].next(bp.product);
        })
        .catch((err) => {
          console.error('Cannot fetch minipass for url ' + btml);
        });
    }
    return this.bpCache[btml];
  }

  btmlFromChain() {
    let btml;
    this.coinBitPasses.some((element) => {
      if (
        element.__data[0].val.specific.specific.specific.specific.specific
          .chain == this.bitPass.__data[0].val.soldInfo.chain
      ) {
        btml = element.__selfBtml;
      }
    });
    return btml;
  }
}
