import { BpEditorModalComponent } from "../pages/main-module/components/bp-editor-modal/bp-editor-modal.component";
import { BitpassClaimConfirmModalComponent } from "../pages/main-module/pages/product/bitpass-claim-confirm-modal/bitpass-claim-confirm-modal.component";
import { BitpassClaimNotificationModalComponent } from "../pages/main-module/pages/product/bitpass-claim-notification-modal/bitpass-claim-notification-modal.component";
import { BitpassQrModalComponent } from "../pages/main-module/pages/product/bitpass-qr-modal/bitpass-qr-modal.component";
import { BpHistoryComponent } from "../pages/main-module/pages/product/bp-history/bp-history.component";
import { BuyBpModalComponent } from "../pages/main-module/pages/product/buy-bp-modal/buy-bp-modal.component";
import { CreateIdentityModalComponent } from "../pages/main-module/pages/product/create-identity-modal/create-identity-modal.component";
import { FullscreenModalComponent } from "../pages/main-module/pages/product/fullscreen-modal/fullscreen-modal.component";
import { PaymentModalComponent } from "../pages/main-module/pages/product/payment-modal/payment-modal.component";
import { PkpassViewerModalComponent } from "../pages/main-module/pages/product/pkpass-viewer-modal/pkpass-viewer-modal.component";
import { PropertyModalComponent } from "../pages/main-module/pages/product/property-modal/property-modal.component";
import { ReceivePaymentModalComponent } from "../pages/main-module/pages/product/receive-payment-modal/receive-payment-modal.component";
import { SaleModalComponent } from "../pages/main-module/pages/product/sale-modal/sale-modal.component";
import { SubpartsModalComponent } from "../pages/main-module/pages/product/subparts-modal/subparts-modal.component";
import { VisitedBpModalComponent } from "../pages/main-module/pages/product/visited-bp-modal/visited-bp-modal.component";

export const ModalOptionsEntries = ['sale', 'buy', 'receive', 'pay', 'editBp', 'visitedBp', 'fullscreen', 'bitpassQR', 'bitpassClaimConfirm', 'bitpassClaimNotification', 'pkpassViewer', 'bpEditor', 'createIdentity', 'bpSubparts', 'bpProperty', 'bpHistory'] as const;
export type ModalOptions = typeof ModalOptionsEntries[number];

export const ModalMap: {
  [key in ModalOptions]: object
} = {
  sale: SaleModalComponent,
  buy: BuyBpModalComponent,
  receive: ReceivePaymentModalComponent,
  pay: PaymentModalComponent,
  editBp: BpEditorModalComponent,
  visitedBp: VisitedBpModalComponent,
  fullscreen: FullscreenModalComponent,
  bitpassQR: BitpassQrModalComponent,
  bitpassClaimConfirm: BitpassClaimConfirmModalComponent,
  bitpassClaimNotification: BitpassClaimNotificationModalComponent,
  pkpassViewer: PkpassViewerModalComponent,
  bpEditor: BpEditorModalComponent,
  createIdentity: CreateIdentityModalComponent,
  bpSubparts: SubpartsModalComponent,
  bpProperty: PropertyModalComponent,
  bpHistory: BpHistoryComponent
};
