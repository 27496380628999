import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CreateIdentityDto } from 'src/app/services/api/models';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { identityInfo: { pubKey: string, title: string, aux?: string, description?: string, signingPrivKey: string } }

@Component({
  selector: 'app-create-identity-modal',
  templateUrl: './create-identity-modal.component.html',
  styleUrls: ['./create-identity-modal.component.scss'],
})
export class CreateIdentityModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'createIdentity';

  bitPass: Bitpass<any>;
  page = 1;
  loadingSecondPage = false;
  identityInfo: CreateIdentityDto = {
    pubKey: '', title: '', aux: '', description: '', signingPrivKey: ''
  };
  newBtml: string;

  @Input()
  set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, [other: string]: any }) {
    if (value.formData) {
      this.identityInfo = value.formData.identityInfo;
    } else if (value.bitpass) {
      this.bitPass = value.bitpass;
      this.identityInfo.pubKey = this.core.auth.userStorage.identities.privateID.pubkeyPGP;
      this.identityInfo.signingPrivKey = this.core.auth.userStorage.identities.privateID.privkeyPGP;
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() { }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  createIdentity() {
    this.loadingSecondPage = true;
    this.core.api.agram.createIdentity({
      worker: 'general_worker_server',
      body: this.identityInfo,
    }).subscribe((res) => {
      if (res) {
        this.newBtml = res.value.slice(0, 10);
        this.page = 2;
        this.loadingSecondPage = false;
      }
    });
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps.fetchBitpass(btml, Bitpass).then((bp) => {
        console.warn('URL ' + btml + ' loaded', bp);
        this.bpCache[btml].next(bp.product);
      }).catch((err) => {
        console.error('Cannot fetch minipass for url ' + btml);
      });
    }
    return this.bpCache[btml];
  }

  autoAssign() {
    this.core.auth.userStorage.identities.publicID.bp_id = this.newBtml;
    this.core.auth.saveUserStorage();
    this.adviceToast();
  }

  public async adviceToast() {
    await (
      await this.core.toastCtrl.create({
        message: 'Se ha autoasignado el nuevo btml',
        duration: 2000,
        cssClass: 'productToast',
        color: 'success',
        buttons: [
          {
            icon: 'close-outline',
            role: 'cancel',
            handler: () => {
              this.core.toastCtrl.dismiss();
            },
          },
        ],
      })
    ).present();
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      identityInfo: this.identityInfo
    };
    dataStore.identityInfo = this.identityInfo;
    this.formDataStore = dataStore;
  }
}
