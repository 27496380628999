import { BtmlMap, ParentSpecific, Specific } from "../../base-specific";
import { Financial, FinancialTypes } from "./financial/financial";



export type ProductTypes = Financial<FinancialTypes>;
export const ProductBtmlMap: BtmlMap = {
    1: Financial,
}

export interface IProduct<T extends ProductTypes> extends ParentSpecific<T> {
    productName: string;
    productDescription: string;
}

export class Product<T extends ProductTypes> extends ParentSpecific<T> implements IProduct<T>  {
    readonly _proto?: string = 'Product';
    readonly btmlPart: number = 1;

    productName: string = null;
    productDescription: string = null;

    constructor(data: IProduct<T>) {
        super(data, ProductBtmlMap);

        this.productName = data.productName;
        this.productDescription = data.productDescription;

        // Implement update logic here
    }
}
