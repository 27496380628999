import { Injectable } from '@angular/core';
import { WorkerExecution } from '../lib/agram-worker/worker-execution';
import { IPendingTask, IPendingTaskStore, ITaskProcess } from '../lib/bitpass-types/models/specific/task/task';
import { CoreProvider } from './core';


@Injectable({ providedIn: 'root' })
export class ActionsServiceProvider {

  private core: CoreProvider;
  private localActionsStore: IPendingTaskStore = {
    process: [],
    variables: []
  };

  get savedActions(): IPendingTaskStore|undefined {
    return this.core?.auth.userStorage?.savedActions ?? undefined;
  }
  get localActions(): IPendingTaskStore {
    return this.localActionsStore;
  }


  constructor() {
    const ls = localStorage.getItem('localActionsStore');
    if (ls) this.localActionsStore = JSON.parse(ls);
  }

  initCore(core: CoreProvider) {
    this.core = core;
  }

  addLocalAction(action: ITaskProcess) {
    this.localActionsStore.process.push(action);
    localStorage.setItem('localActionsStore', JSON.stringify(this.localActionsStore));
  }
  
  deleteLocalAction(action: ITaskProcess) {
    this.localActionsStore.process.splice(this.localActions.process.indexOf(action), 1);
    localStorage.setItem('localActionsStore', JSON.stringify(this.localActionsStore));
  }

  deleteSavedAction(action: ITaskProcess) {
    this.savedActions.process.splice(this.localActions.process.indexOf(action), 1);
    this.core.auth.saveUserStorage();
  }

  saveAction(action: ITaskProcess) {
    this.savedActions.process.push(action);
    const localActionIndex = this.localActions.process.indexOf(action);
    if (localActionIndex >= 0) {
      this.localActions.process.splice(localActionIndex, 1);
      localStorage.setItem('localActionsStore', JSON.stringify(this.localActionsStore));
    }
    this.core.auth.saveUserStorage();
  }

  runAction(task: ITaskProcess) {
    const pendingTaskStore: IPendingTaskStore = {
      process: [task],
      variables: []
    };
    (new WorkerExecution()).init({
      workerBackend: 'memory',
      pendingTaskStore
    }).then(
      workerExecution => workerExecution.run().then(
        output => console.log('Worker execution finished successfully. Output: ', output)
      ).catch(console.error)
    ).catch(console.error);
  }

  runActions(store: 'local'|'saved') {
    const pendingTaskStore: IPendingTaskStore = store=='local' ? this.localActions:this.savedActions;

    if (pendingTaskStore?.process?.length) {
      (new WorkerExecution()).init({
        workerBackend: 'memory',
        pendingTaskStore
      }).then(
        workerExecution => workerExecution.run().then(
          output => console.log('Worker execution finished successfully. Output: ', output)
        ).catch(console.error)
      ).catch(console.error);
    }
  }

}
