/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { LnChannelOpResponse } from '../models/ln-channel-op-response';
import { LnChannelsBalanceResponse } from '../models/ln-channels-balance-response';
import { LnCloseChannelRequest } from '../models/ln-close-channel-request';
import { LnInvoiceRequest } from '../models/ln-invoice-request';
import { LnInvoiceResponse } from '../models/ln-invoice-response';
import { LnListChannelResponse } from '../models/ln-list-channel-response';
import { LnOpenChannelRequest } from '../models/ln-open-channel-request';
import { LnPendingChannelResponse } from '../models/ln-pending-channel-response';
import { LnBitsCallRequest } from '../models/ln-bits-call-request';
import { SuccessResponse } from '../models/success-response';


/**
 * `Transer` value data privately, cheaply and quickly, through <b>State-Channels</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer2Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lnLnbitsAccess()` */
  static readonly LnLnbitsAccessPath = '/LN/{chain}/access/custodial';

  /**
   * (LittleUser) Access to custodial LN wallets API for users.
   *
   * Custorial Lightning Network wallets for each users, for any BTC chain, calling LNbits specific API Rest
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `endpoint()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endpoint$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * LN Access token
     */
      token?: string;
      body?: LnBitsCallRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LnLnbitsAccessPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.header('token', params.token, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * (LittleUser) Access to custodial LN wallets API for users.
   *
   * Custorial Lightning Network wallets for each users, for any BTC chain, calling LNbits specific API Rest
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `endpoint$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  endpoint(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * LN Access token
     */
      token?: string;
      body?: LnBitsCallRequest
    },
    context?: HttpContext
  ): Observable<any> {
    return this.endpoint$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetChainAddress()` */
  static readonly LightningBtcControllerGetChainAddressPath = '/LN/{chain}/chain_address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChainAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChainAddress$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetChainAddressPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChainAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChainAddress(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getChainAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetChannels()` */
  static readonly LightningBtcControllerGetChannelsPath = '/LN/{chain}/channels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannels$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnListChannelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetChannelsPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnListChannelResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannels(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<LnListChannelResponse> {
    return this.getChannels$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnListChannelResponse>): LnListChannelResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetChannelsBalance()` */
  static readonly LightningBtcControllerGetChannelsBalancePath = '/LN/{chain}/channels_balance';

  /**
   * Lightning Network related things
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannelsBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelsBalance$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnChannelsBalanceResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetChannelsBalancePath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnChannelsBalanceResponse>;
      })
    );
  }

  /**
   * Lightning Network related things
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getChannelsBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelsBalance(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<LnChannelsBalanceResponse> {
    return this.getChannelsBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnChannelsBalanceResponse>): LnChannelsBalanceResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerCloseChannel()` */
  static readonly LightningBtcControllerCloseChannelPath = '/LN/{chain}/close_channel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeChannel$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnCloseChannelRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnChannelOpResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerCloseChannelPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnChannelOpResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  closeChannel(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnCloseChannelRequest
    },
    context?: HttpContext
  ): Observable<LnChannelOpResponse> {
    return this.closeChannel$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnChannelOpResponse>): LnChannelOpResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetInvoiceInfo()` */
  static readonly LightningBtcControllerGetInvoiceInfoPath = '/LN/{chain}/invoice/{hash}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoiceInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceInfo$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      hash: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SuccessResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetInvoiceInfoPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('hash', params.hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SuccessResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoiceInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInvoiceInfo(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      hash: string;
    },
    context?: HttpContext
  ): Observable<SuccessResponse> {
    return this.getInvoiceInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<SuccessResponse>): SuccessResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetInvoice()` */
  static readonly LightningBtcControllerGetInvoicePath = '/LN/{chain}/invoice';

  /**
   * amount in satoshis
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInvoice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInvoice$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnInvoiceRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnInvoiceResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetInvoicePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnInvoiceResponse>;
      })
    );
  }

  /**
   * amount in satoshis
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInvoice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getInvoice(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnInvoiceRequest
    },
    context?: HttpContext
  ): Observable<LnInvoiceResponse> {
    return this.getInvoice$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnInvoiceResponse>): LnInvoiceResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerOpenChannel()` */
  static readonly LightningBtcControllerOpenChannelPath = '/LN/{chain}/open_channel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openChannel$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnOpenChannelRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnChannelOpResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerOpenChannelPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnChannelOpResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  openChannel(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LnOpenChannelRequest
    },
    context?: HttpContext
  ): Observable<LnChannelOpResponse> {
    return this.openChannel$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnChannelOpResponse>): LnChannelOpResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetPendingChannels()` */
  static readonly LightningBtcControllerGetPendingChannelsPath = '/LN/{chain}/pending_channels';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPendingChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChannels$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LnPendingChannelResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetPendingChannelsPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LnPendingChannelResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPendingChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPendingChannels(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<LnPendingChannelResponse> {
    return this.getPendingChannels$Response(params, context).pipe(
      map((r: StrictHttpResponse<LnPendingChannelResponse>): LnPendingChannelResponse => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerCheckLNaddress()` */
  static readonly LightningBtcControllerCheckLNaddressPath = '/LN/{chain}/user/check/address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkLNaddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLNaddress$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerCheckLNaddressPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkLNaddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkLNaddress(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.checkLNaddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `lightningBtcControllerGetWalletBalance()` */
  static readonly LightningBtcControllerGetWalletBalancePath = '/LN/{chain}/wallet_balance';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWalletBalance()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletBalance$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer2Service.LightningBtcControllerGetWalletBalancePath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWalletBalance$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWalletBalance(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getWalletBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
