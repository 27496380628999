import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, filter, firstValueFrom } from 'rxjs';
import { BitpassApplication } from '../lib/bitpass-types/models/bitpass';
import { Application, IApplicationProps, LangOptions } from '../lib/bitpass-types/models/specific/application/application';
import { BpAppUrlMap } from './bpapp-url-map';
import { CoreProvider } from './core';
import { DefaultTranslations } from './default-lang';


@Injectable({ providedIn: 'root' })
export class PersonalizationServiceProvider {

  private lastLoadedAppBtml: string;
  private lastLoadedLang: LangOptions;
  private computedAppConfig: Application;
  public get appConfig() {
    return this.computedAppConfig;
  }

  get loadedAppBtml() { return this.lastLoadedAppBtml; };

  private core: CoreProvider;
  private fallbackConfig: IApplicationProps = {
    // Customizable by userStorage //
    theme: {
      content: {
        theme: 'dark',
        selected_lang: "es",
      },
      carrusel: {
        media_timer: 10
      },
      fullscreen: {
        background_particles: true,
        media_timer: 10,
      },
      style: {
        color_CTA: '',
        font_family: '',
      },
      wallet: {
        fiat_order: [],
      }
    },

    // Application only part //
    app_version: 1,
    content: {
      app_name: 'dSequence',
      initial_btml: '-1',
      images: {},
      show_menu: true,
      menu_elements: {},
      show_search: true,
      search_elements: [
        // { btml_type: '', name: '', icon: '' },
      ],
      action_elements: {},
    },
    translations: DefaultTranslations
  };
  public onLangChange$ = new BehaviorSubject<boolean>(null);

  constructor(
    private title: Title,
  ) {}

  async initCore(core: CoreProvider) {
    this.core = core;
    this.computedAppConfig = new Application(JSON.parse(JSON.stringify(this.fallbackConfig)));
    this.core.auth.onUser.subscribe(user => {
      this.checkForLangChange();
      this.initAppConfig();
    });
    await this.initAppConfig();
  }

  async initAppConfig() {
    const currentAppBtml = BpAppUrlMap[location.hostname] ?? '9000000000';
    if (this.core.auth.user) {
      await this.loadUserApp(currentAppBtml);
    } else {
      await this.fetchAppBitpass(currentAppBtml);
    }
  }

  fetchAppBitpass(appBtml: string) {
    return new Promise<void>((resolve) => {
      const fetchAppBitpass = (guestToken?: string) => {
        if (guestToken && !this.core.auth.token) {
          this.core.auth.token = guestToken;
        }
        console.error('ERROR trying to fetch app bp without app btml', appBtml);
        this.core.bps.fetchBitpass(appBtml, BitpassApplication).then(bp => {
          let bpAppConfig = bp.product.__data[0].val.specific;
          if (!bpAppConfig.app_version || bpAppConfig.app_version < 1) {
            bpAppConfig = new Application({...this.fallbackConfig, btmlPart: 9});
          }
          // Comment next line to disable bp app loading and use fallback configuration instead
          this.computedAppConfig = bpAppConfig;
          const isNewAppBpLoaded = this.lastLoadedAppBtml != appBtml;
          this.lastLoadedAppBtml = appBtml;
          console.log('APP Personalization BP Loaded', this.computedAppConfig);
          this.patchUserPersonalizationOverrides(appBtml);

          if (isNewAppBpLoaded) {
            this.applyAppPersonalization();
          }

          resolve();
        }).catch(err => {
          console.error('ERROR fetching app bp', err);
          resolve();
        });
      }

      if (!this.core.auth.token) {
        this.core.api.jwt.login({body: {email: this.core.auth.guestLogin.email, password: this.core.auth.guestLogin.pass}}).subscribe({
          next: (res) => {
            fetchAppBitpass(res.accessToken);
          },
          error: (err) => {
            console.error('ERROR guest login for app bitpass', err);
            resolve();
          },
        });
      } else {
        fetchAppBitpass();
      }
    }).then(() => {
      this.checkForLangChange();
    });
  }

  checkForLangChange() {
    if (this.lastLoadedLang != this.computedAppConfig.theme.content.selected_lang) {
      if (!this.lastLoadedLang) this.onLangChange$.next(this.core.auth.user!==null);
      else {
        this.onLangChange$.next(false);
        setTimeout(()=>{this.onLangChange$.next(true);}, 700);
      }
      this.lastLoadedLang = this.computedAppConfig.theme.content.selected_lang;
    }
  }

  async loadUserApp(fallbackAppBtml: string) {
    if (!this.core.auth.user) return;
    const currentUsedAppBtml = this.core.auth.userStorage.apps.list?.[this.core.auth.userStorage.apps.appUsed]?.appBtml ?? fallbackAppBtml;
    await this.fetchAppBitpass(currentUsedAppBtml);
  }

  patchUserPersonalizationOverrides(appBtml?: string) {
    if (!this.core.auth.user) return;
    const currentUsedAppBtml = this.core.auth.userStorage.apps.list?.[this.core.auth.userStorage.apps.appUsed]?.appBtml;
    if ((appBtml ?? this.lastLoadedAppBtml) == currentUsedAppBtml) {
      const userStorageThemeCustomization = this.core.auth.userStorage.apps.list?.[this.core.auth.userStorage.apps.appUsed]?.themeCustomization;
      if (userStorageThemeCustomization) for (const pkg in this.computedAppConfig.theme) {
        if (userStorageThemeCustomization[pkg]) this.computedAppConfig.theme[pkg] = userStorageThemeCustomization[pkg];
      }
    } else {
      console.warn('BitpassAPP and CurrentAPP Mismatch: Prevent user theme patching');
    }
    console.log('APP Personalization with overrides', this.computedAppConfig);
  }

  favIcon: HTMLLinkElement = document.querySelector('head link[rel=icon]');
  applyAppPersonalization() {
    this.title.setTitle(this.computedAppConfig.content.app_name);
    if (this.computedAppConfig.content.images.favicon) {
      firstValueFrom(this.core.resource.resourceURL(this.computedAppConfig.content.images.favicon, {
        loadingValue: 'loading',
      }).pipe(filter(itm => itm.cleanUrl!='loading'))).then(url => {
        this.favIcon.href = url.cleanUrl;
      });
    } else {
      this.favIcon.href = '/assets/Logo_detailorg.svg';
    }
  }

}
