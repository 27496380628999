/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Cid } from '../models/cid';
import { Contract } from '../models/contract';
import { DlcMongo } from '../models/dlc-mongo';
import { OracleSigRequest } from '../models/oracle-sig-request';
import { PgpCypherRequest } from '../models/pgp-cypher-request';
import { PgpCypherResponse } from '../models/pgp-cypher-response';
import { PgpDecypherRequest2 } from '../models/pgp-decypher-request-2';


/**
 * `Restrict` minting and transfer without revealing information with <b>Privacy Proofs</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer3Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dlcControllerAcceptMessage()` */
  static readonly DlcControllerAcceptMessagePath = '/DLC/{chain}/acceptMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptMessage$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerAcceptMessagePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  acceptMessage(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.acceptMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerAddOfferMessage()` */
  static readonly DlcControllerAddOfferMessagePath = '/DLC/{chain}/add/offerMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOfferMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOfferMessage$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: DlcMongo
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerAddOfferMessagePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOfferMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOfferMessage(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: DlcMongo
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.addOfferMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerInitcontract()` */
  static readonly DlcControllerInitcontractPath = '/DLC/{chain}/initContract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `initcontract()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initcontract$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Contract
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerInitcontractPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `initcontract$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  initcontract(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Contract
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.initcontract$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerOfferMessage()` */
  static readonly DlcControllerOfferMessagePath = '/DLC/{chain}/offerMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offerMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  offerMessage$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerOfferMessagePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offerMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  offerMessage(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.offerMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerOracleSign()` */
  static readonly DlcControllerOracleSignPath = '/DLC/{chain}/oracle/sig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `oracleSign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oracleSign$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: OracleSigRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerOracleSignPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `oracleSign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  oracleSign(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: OracleSigRequest
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.oracleSign$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerSignAndBroadcast()` */
  static readonly DlcControllerSignAndBroadcastPath = '/DLC/{chain}/signAndBroadcast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signAndBroadcast()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signAndBroadcast$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: OracleSigRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerSignAndBroadcastPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signAndBroadcast$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signAndBroadcast(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: OracleSigRequest
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.signAndBroadcast$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerSignMessage()` */
  static readonly DlcControllerSignMessagePath = '/DLC/{chain}/signMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signMessage$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Cid>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerSignMessagePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Cid>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signMessage(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: Cid
    },
    context?: HttpContext
  ): Observable<Cid> {
    return this.signMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Cid>): Cid => r.body)
    );
  }

  /** Path part for operation `dlcControllerUpdateDlc()` */
  static readonly DlcControllerUpdateDlcPath = '/DLC/{chain}/update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDlc()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlc$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: DlcMongo
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.DlcControllerUpdateDlcPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDlc$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDlc(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: DlcMongo
    },
    context?: HttpContext
  ): Observable<any> {
    return this.updateDlc$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `pgpControllerCypherMessage()` */
  static readonly PgpControllerCypherMessagePath = '/PGP/cypher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cypherMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cypherMessage$Response(
    params?: {
      body?: PgpCypherRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PgpCypherResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.PgpControllerCypherMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PgpCypherResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cypherMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  cypherMessage(
    params?: {
      body?: PgpCypherRequest
    },
    context?: HttpContext
  ): Observable<PgpCypherResponse> {
    return this.cypherMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PgpCypherResponse>): PgpCypherResponse => r.body)
    );
  }

  /** Path part for operation `pgpControllerDecypherMessage()` */
  static readonly PgpControllerDecypherMessagePath = '/PGP/decypher';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decypherMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  decypherMessage$Response(
    params?: {
      body?: PgpDecypherRequest2
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Blob>> {
    const rb = new RequestBuilder(this.rootUrl, Layer3Service.PgpControllerDecypherMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Blob>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `decypherMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  decypherMessage(
    params?: {
      body?: PgpDecypherRequest2
    },
    context?: HttpContext
  ): Observable<Blob> {
    return this.decypherMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
