/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { getActionExecutor } from '../action-catalog';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class SaveBitpassAction extends RunnableAction {
  static readonly identifier = 'saveBitpass';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const bitpassData = JSON.parse(params[0]);
    const signAuthor = params[1];
    const signKey = params[2];
    const btmlType = params[3];
    const btml = params[4];

    bitpassData[0].val['updated'] = Date.now();
    const bitpassDataCid = (
      await getActionExecutor('pinIpfsJson')!.run(JSON.stringify(bitpassData))
    )[0];

    const pkpassCid = (
      await getActionExecutor('savePkpass')!.run(JSON.stringify(bitpassData))
    )[0];

    const newBitpass = {
      pkpass_cid: pkpassCid,
      data_cid: bitpassDataCid,
      author: signAuthor,
      signature: '',
    };
    newBitpass.signature = (
      await getActionExecutor('pgpSign')!.run(
        JSON.stringify(newBitpass),
        signKey!,
      )
    )[0];
    const bitpassCid = (
      await getActionExecutor('pinIpfsJson')!.run(JSON.stringify(newBitpass))
    )[0];

    let nextKey = '';
    if (btml) {
      const actLastRecord = (
        await getActionExecutor('lastRecord')!.run(
          ...(await getActionExecutor('keyLastTen')!.run(btml)),
        )
      )[0];
      // Generate next revision number for bitpass btml
      const nextRevision = parseInt(JSON.parse(actLastRecord!).key.split('v')[0]) + 1;
      nextKey = `${nextRevision}v${btml}`;
    } else {
      // Fetch new bitpass btml
      nextKey = (await getActionExecutor('nextBtmlKey')!.run(btmlType))[0];
    }
    const record = await getActionExecutor('postRecord')!.run(
      JSON.stringify({
        timestamp: 0,
        key: nextKey,
        value: bitpassCid,
      }),
    );

    let bpOwnershipBtml = '';
    // If it's first revision and is not a ownership bp
    if (nextKey.split('v')[0] === '0' && !nextKey.split('v')[1].startsWith('12130')) {
      bpOwnershipBtml = (await getActionExecutor('ownershipCreate')!.run(
        nextKey.split('v')[1],
        signAuthor.slice(0, 10),
      ))[0];
    }

    return [
      JSON.stringify(record),
      JSON.stringify(newBitpass),
      bitpassCid,
      nextKey.split('v')[1],
      bpOwnershipBtml,
    ];
  }
}
