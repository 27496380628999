/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateTposData } from '../models/create-tpos-data';
import { PayLnurlWData } from '../models/pay-lnurl-w-data';

@Injectable({ providedIn: 'root' })
export class TPoSService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `indexTposGet()` */
  static readonly IndexTposGetPath = '/tpos/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexTposGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexTposGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.IndexTposGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexTposGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexTposGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexTposGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `tposTposTposIdGet()` */
  static readonly TposTposTposIdGetPath = '/tpos/{tpos_id}';

  /**
   * Tpos.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tposTposTposIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  tposTposTposIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.TposTposTposIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Tpos.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `tposTposTposIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  tposTposTposIdGet(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.tposTposTposIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `manifestTposManifestTposIdWebmanifestGet()` */
  static readonly ManifestTposManifestTposIdWebmanifestGetPath = '/tpos/manifest/{tpos_id}.webmanifest';

  /**
   * Manifest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manifestTposManifestTposIdWebmanifestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  manifestTposManifestTposIdWebmanifestGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ManifestTposManifestTposIdWebmanifestGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Manifest.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manifestTposManifestTposIdWebmanifestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manifestTposManifestTposIdWebmanifestGet(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.manifestTposManifestTposIdWebmanifestGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTpossTposApiV1TpossGet()` */
  static readonly ApiTpossTposApiV1TpossGetPath = '/tpos/api/v1/tposs';

  /**
   * Api Tposs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTpossTposApiV1TpossGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTpossTposApiV1TpossGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTpossTposApiV1TpossGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tposs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTpossTposApiV1TpossGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTpossTposApiV1TpossGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTpossTposApiV1TpossGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposCreateTposApiV1TpossPost()` */
  static readonly ApiTposCreateTposApiV1TpossPostPath = '/tpos/api/v1/tposs';

  /**
   * Api Tpos Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposCreateTposApiV1TpossPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTposCreateTposApiV1TpossPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateTposData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposCreateTposApiV1TpossPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposCreateTposApiV1TpossPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTposCreateTposApiV1TpossPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateTposData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposCreateTposApiV1TpossPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposDeleteTposApiV1TpossTposIdDelete()` */
  static readonly ApiTposDeleteTposApiV1TpossTposIdDeletePath = '/tpos/api/v1/tposs/{tpos_id}';

  /**
   * Api Tpos Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposDeleteTposApiV1TpossTposIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposDeleteTposApiV1TpossTposIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposDeleteTposApiV1TpossTposIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposDeleteTposApiV1TpossTposIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposDeleteTposApiV1TpossTposIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposDeleteTposApiV1TpossTposIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet()` */
  static readonly ApiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGetPath = '/tpos/api/v1/tposs/{tpos_id}/invoices';

  /**
   * Api Tpos Get Latest Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Get Latest Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposGetLatestInvoicesTposApiV1TpossTposIdInvoicesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost()` */
  static readonly ApiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPostPath = '/tpos/api/v1/tposs/{tpos_id}/invoices';

  /**
   * Api Tpos Create Invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
      amount: number;
      memo?: string;
      tipAmount?: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
      rb.query('amount', params.amount, {});
      rb.query('memo', params.memo, {});
      rb.query('tipAmount', params.tipAmount, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Create Invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
      amount: number;
      memo?: string;
      tipAmount?: number;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposCreateInvoiceTposApiV1TpossTposIdInvoicesPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost()` */
  static readonly ApiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPostPath = '/tpos/api/v1/tposs/{tpos_id}/invoices/{payment_request}/pay';

  /**
   * Api Tpos Pay Invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      payment_request: string;
      tpos_id: string;
      body: PayLnurlWData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('payment_request', params.payment_request, {});
      rb.path('tpos_id', params.tpos_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Pay Invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost(
    chain: string,
    token: string | undefined,
    params: {
      payment_request: string;
      tpos_id: string;
      body: PayLnurlWData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposPayInvoiceTposApiV1TpossTposIdInvoicesPaymentRequestPayPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet()` */
  static readonly ApiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGetPath = '/tpos/api/v1/tposs/{tpos_id}/invoices/{payment_hash}';

  /**
   * Api Tpos Check Invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
      payment_hash: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, TPoSService.ApiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('tpos_id', params.tpos_id, {});
      rb.path('payment_hash', params.payment_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tpos Check Invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet(
    chain: string,
    token: string | undefined,
    params: {
      tpos_id: string;
      payment_hash: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTposCheckInvoiceTposApiV1TpossTposIdInvoicesPaymentHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
