/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class FetchIpfsJsonAction extends RunnableAction {
  static readonly identifier = 'fetchIpfsJson';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const cid = params[0];

    const resp = await requests.ipfsGet(cid);
    if (!resp.ok)
      throw new Error(
        'Error fetching cid ' + cid + '. Error: ' + JSON.stringify(resp),
      );

    return [await resp.text()];
  }
}
