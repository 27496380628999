import { Component, Input, OnInit } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { firstValueFrom } from 'rxjs';
const { Share } = Plugins;

export interface IFormData { bpBtml: string, claimSecret: string }

@Component({
  selector: 'app-bitpass-claim-confirm-modal',
  templateUrl: './bitpass-claim-confirm-modal.component.html',
  styleUrls: ['./bitpass-claim-confirm-modal.component.scss'],
})
export class BitpassClaimConfirmModalComponent extends BaseModal<IFormData> {
  protected formIdentifier: string = 'bitpassClaimConfirm';

  processStatus: 'idle'|'claim_confirm'|'ownership_confirm'|'success'|'error' = 'idle';

  changingBP: boolean = false;
  btmlType: string;

  @Input() set data(value: {bpBtml: string, bitpass: Bitpass<any>}) {
    console.warn('MODAL PARAMS', value)
    this.readFormToStore();
    if (value.bpBtml) {
      this.updateBTML(value.bpBtml);
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  protected async onCommit(data: IFormData): Promise<any> {
    try {
      if (!this.core.auth.userStorage.identities.publicID.bp_id) {
        throw new Error('User does not have public identity bitpass');
      }

      this.processStatus = 'claim_confirm';
      const res = await firstValueFrom(this.core.api.agram.claimBpConfirm({worker: 'general_worker_server', body: {
        btml: data.bpBtml,
        claimKey: data.claimSecret,
        receiverBtmlID: this.core.auth.userStorage.identities.publicID.bp_id,
      }}));
      if (!res) throw new Error('Invalid response from server');

      this.processStatus = 'ownership_confirm';
      await firstValueFrom(this.core.api.agram.confirmOwnership({ worker: 'general_worker_server', body: {
        btmlBitpass: data.bpBtml,
        btmlIdentity: this.core.auth.userStorage.identities.publicID.bp_id,
        signingPrivKey: this.core.auth.userStorage.identities.privateID.privkeyPGP,
      }}));

      this.core.toastCtrl.create({
        message: 'Bitpass claimed!',
        duration: 3000,
        color: 'success'
      }).then(toast => toast.present());
      this.processStatus = 'success';
    } catch (error) {
      console.error('Last status: '+this.processStatus, error);
      this.core.toastCtrl.create({
        message: 'Error claiming bitpass',
        duration: 3000,
        color: 'danger'
      }).then(toast => toast.present());
      this.processStatus = 'error';
    }
  }

  //#region BP Change
  getBTMLType() {
    return Number(this.btmlType);
  }
  setBTMLType(value: string) {
    this.btmlType = value;
  }
  // Method used by the app-choose-bp (choose Bitpass).
  chooseBP(e) {
    this.changingBP = true;
    this.formDataStore.bpBtml = e;
  }
  async updateBTML(BTMLid = this.formDataStore.bpBtml) {
    this.formDataStore.bpBtml = BTMLid;
    this.changingBP = false;
    this.btmlType = this.formDataStore.bpBtml.substring(0, 5);

    // Maybe check at the future that current bp btml is owned by the user.

    // this.btmlForm.value = BTMLid;
    // this.fetchBitpass(this.urlBPid).subscribe({
    //   next: (res) => {
    //     if (res) {
    //       this.activeSale = res.__data[0].val.soldInfo?.activeSold
    //         ? true
    //         : false;
    //       this.precioVenta = res.__data[0].val.soldInfo?.price;
    //       // this.CIDForm.value = res.data_cid.toString();
    //     }
    //   },
    //   error: (err) => {},
    // });
  }
  //#endregion


  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore: IFormData = this.formDataStore || {
      bpBtml: '',
      claimSecret: '',
    };
    this.formDataStore = dataStore;
  }
}
