import { BtmlMap, ParentSpecific } from "../base-specific";
import { Action } from "./action/action";
import { Certificate } from "./concept/certificate/certificate";
import { Ownership } from "./concept/certificate/ownership/ownership";
import { Concept, ConceptTypes } from "./concept/concept";
import { Identity, IdentityTypes } from "./identity/identity";
import { Person } from "./identity/person/person";
import { Financial } from "./product/financial/financial";
import { Coin } from "./product/financial/monetary/coin/coin";
import { Monetary } from "./product/financial/monetary/monetary";
import { Product, ProductTypes } from "./product/product";

export type ResourceTypes = Product<ProductTypes>|Concept<ConceptTypes>|Identity<IdentityTypes>|Action;
export const ResourceBtmlMap: BtmlMap = {
    1: Product,
    2: Concept,
    3: Identity,
    4: Action,
}

export interface IResource<RT extends ResourceTypes> extends ParentSpecific<RT> {
    fileName: string;
}

export class Resource<RT extends ResourceTypes> extends ParentSpecific<RT> implements IResource<RT> {
    readonly _proto?: string = 'Resource';
    readonly btmlPart: number = 1;

    fileName: string = null;

    constructor(data: IResource<RT>) {
        super(data, ResourceBtmlMap);

        this.fileName = data.fileName;
        // Implement update logic here
    }

}

export class ResourceProduct extends Resource<Product<null>> {};
export class ResourceProductFinancial extends Resource<Product<Financial<null>>> {};
export class ResourceProductFinancialMonetary extends Resource<Product<Financial<Monetary<null>>>> {};
export class ResourceProductFinancialMonetaryCoin extends Resource<Product<Financial<Monetary<Coin>>>> {};
export class ResourceConcept extends Resource<Concept<null>> {};
export class ResourceConceptCertificate extends Resource<Concept<Certificate<null>>> {};
export class ResourceConceptCertificateOwnership extends Resource<Concept<Certificate<Ownership>>> {};
export class ResourceIdentity extends Resource<Identity<null>> {};
export class ResourceIdentityPerson extends Resource<Identity<Person>> {};
export class ResourceAction extends Resource<Action> {};
