import { BtmlMap, ParentSpecific } from "../base-specific";
import { Actions } from "./actions/actions";
import { Certificate } from "./concept/certificate/certificate";
import { Ownership } from "./concept/certificate/ownership/ownership";
import { Concept, ConceptTypes } from "./concept/concept";

export type IndexTypes = Actions|Concept<ConceptTypes>;
export const IndexBtmlMap: BtmlMap = {
    4: Actions,
    2: Concept,
}

export interface IIndex<RT extends IndexTypes> extends ParentSpecific<RT> {
}

export class Index<RT extends IndexTypes> extends ParentSpecific<RT> implements IIndex<RT> {
    readonly _proto?: string = 'Index';
    readonly btmlPart: number = 5;

    constructor(data: IIndex<RT>) {
        super(data, IndexBtmlMap);
        // Implement update logic here
    }

}

export class IndexActions extends Index<Actions> {};
export class IndexConceptCertificateOwnership extends Index<Concept<Certificate<Ownership>>> {};

