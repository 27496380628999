/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreatePayLinkData } from '../models/create-pay-link-data';

@Injectable({ providedIn: 'root' })
export class LnurlpService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet()` */
  static readonly LnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGetPath = '/lnurlp/api/v1/lnurl/cb/lnaddr/{link_id}';

  /**
   * Lnurlp.Api Lnurl Lnaddr Callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      amount: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.LnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.query('amount', params.amount, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurlp.Api Lnurl Lnaddr Callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      amount: number;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlpApiLnurlLnaddrCallbackLnurlpApiV1LnurlCbLnaddrLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet()` */
  static readonly LnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGetPath = '/lnurlp/api/v1/lnurl/cb/{link_id}';

  /**
   * Lnurlp.Api Lnurl Callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      amount: number;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.LnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.query('amount', params.amount, {});
      rb.query('lnaddress', params.lnaddress, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurlp.Api Lnurl Callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      amount: number;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlpApiLnurlCallbackLnurlpApiV1LnurlCbLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `lnurlpApiLnurlResponseLnurlpLinkIdGet()` */
  static readonly LnurlpApiLnurlResponseLnurlpLinkIdGetPath = '/lnurlp/{link_id}';

  /**
   * Lnurlp.Api Lnurl Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlpApiLnurlResponseLnurlpLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlResponseLnurlpLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.LnurlpApiLnurlResponseLnurlpLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.query('lnaddress', params.lnaddress, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurlp.Api Lnurl Response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlpApiLnurlResponseLnurlpLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlResponseLnurlpLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlpApiLnurlResponseLnurlpLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet()` */
  static readonly LnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGetPath = '/lnurlp/api/v1/lnurl/{link_id}';

  /**
   * Lnurlp.Api Lnurl Response.Deprecated.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.LnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.query('lnaddress', params.lnaddress, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurlp.Api Lnurl Response.Deprecated.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      lnaddress?: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlpApiLnurlResponseDeprecatedLnurlpApiV1LnurlLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `indexLnurlpGet()` */
  static readonly IndexLnurlpGetPath = '/lnurlp/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexLnurlpGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexLnurlpGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.IndexLnurlpGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexLnurlpGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexLnurlpGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexLnurlpGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `displayLnurlpLinkLinkIdGet()` */
  static readonly DisplayLnurlpLinkLinkIdGetPath = '/lnurlp/link/{link_id}';

  /**
   * Display.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `displayLnurlpLinkLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayLnurlpLinkLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.DisplayLnurlpLinkLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Display.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `displayLnurlpLinkLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayLnurlpLinkLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.displayLnurlpLinkLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `printQrLnurlpPrintLinkIdGet()` */
  static readonly PrintQrLnurlpPrintLinkIdGetPath = '/lnurlp/print/{link_id}';

  /**
   * Print Qr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printQrLnurlpPrintLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  printQrLnurlpPrintLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.PrintQrLnurlpPrintLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Print Qr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printQrLnurlpPrintLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printQrLnurlpPrintLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.printQrLnurlpPrintLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `lnaddressLnurlpApiV1WellKnownUsernameGet()` */
  static readonly LnaddressLnurlpApiV1WellKnownUsernameGetPath = '/lnurlp/api/v1/well-known/{username}';

  /**
   * Lnaddress.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnaddressLnurlpApiV1WellKnownUsernameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnaddressLnurlpApiV1WellKnownUsernameGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      username: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.LnaddressLnurlpApiV1WellKnownUsernameGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('username', params.username, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnaddress.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnaddressLnurlpApiV1WellKnownUsernameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnaddressLnurlpApiV1WellKnownUsernameGet(
    chain: string,
    token: string | undefined,
    params: {
      username: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnaddressLnurlpApiV1WellKnownUsernameGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet()` */
  static readonly ApiListCurrenciesAvailableLnurlpApiV1CurrenciesGetPath = '/lnurlp/api/v1/currencies';

  /**
   * Api List Currencies Available.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiListCurrenciesAvailableLnurlpApiV1CurrenciesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api List Currencies Available.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiListCurrenciesAvailableLnurlpApiV1CurrenciesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinksLnurlpApiV1LinksGet()` */
  static readonly ApiLinksLnurlpApiV1LinksGetPath = '/lnurlp/api/v1/links';

  /**
   * Api Links.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinksLnurlpApiV1LinksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinksLnurlpApiV1LinksGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiLinksLnurlpApiV1LinksGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Links.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinksLnurlpApiV1LinksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinksLnurlpApiV1LinksGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinksLnurlpApiV1LinksGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkCreateOrUpdateLnurlpApiV1LinksPost()` */
  static readonly ApiLinkCreateOrUpdateLnurlpApiV1LinksPostPath = '/lnurlp/api/v1/links';

  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkCreateOrUpdateLnurlpApiV1LinksPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateLnurlpApiV1LinksPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id?: any;
      body: CreatePayLinkData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiLinkCreateOrUpdateLnurlpApiV1LinksPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('link_id', params.link_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkCreateOrUpdateLnurlpApiV1LinksPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateLnurlpApiV1LinksPost(
    chain: string,
    token: string | undefined,
    params: {
      link_id?: any;
      body: CreatePayLinkData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkCreateOrUpdateLnurlpApiV1LinksPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkRetrieveLnurlpApiV1LinksLinkIdGet()` */
  static readonly ApiLinkRetrieveLnurlpApiV1LinksLinkIdGetPath = '/lnurlp/api/v1/links/{link_id}';

  /**
   * Api Link Retrieve.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkRetrieveLnurlpApiV1LinksLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkRetrieveLnurlpApiV1LinksLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiLinkRetrieveLnurlpApiV1LinksLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Retrieve.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkRetrieveLnurlpApiV1LinksLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkRetrieveLnurlpApiV1LinksLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkRetrieveLnurlpApiV1LinksLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut()` */
  static readonly ApiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPutPath = '/lnurlp/api/v1/links/{link_id}';

  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      body: CreatePayLinkData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
      body: CreatePayLinkData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkCreateOrUpdateLnurlpApiV1LinksLinkIdPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkDeleteLnurlpApiV1LinksLinkIdDelete()` */
  static readonly ApiLinkDeleteLnurlpApiV1LinksLinkIdDeletePath = '/lnurlp/api/v1/links/{link_id}';

  /**
   * Api Link Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkDeleteLnurlpApiV1LinksLinkIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkDeleteLnurlpApiV1LinksLinkIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiLinkDeleteLnurlpApiV1LinksLinkIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkDeleteLnurlpApiV1LinksLinkIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkDeleteLnurlpApiV1LinksLinkIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkDeleteLnurlpApiV1LinksLinkIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiCheckFiatRateLnurlpApiV1RateCurrencyGet()` */
  static readonly ApiCheckFiatRateLnurlpApiV1RateCurrencyGetPath = '/lnurlp/api/v1/rate/{currency}';

  /**
   * Api Check Fiat Rate.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCheckFiatRateLnurlpApiV1RateCurrencyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCheckFiatRateLnurlpApiV1RateCurrencyGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      currency: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiCheckFiatRateLnurlpApiV1RateCurrencyGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('currency', params.currency, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Check Fiat Rate.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCheckFiatRateLnurlpApiV1RateCurrencyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCheckFiatRateLnurlpApiV1RateCurrencyGet(
    chain: string,
    token: string | undefined,
    params: {
      currency: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiCheckFiatRateLnurlpApiV1RateCurrencyGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiStopLnurlpApiV1Delete()` */
  static readonly ApiStopLnurlpApiV1DeletePath = '/lnurlp/api/v1';

  /**
   * Api Stop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStopLnurlpApiV1Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopLnurlpApiV1Delete$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnurlpService.ApiStopLnurlpApiV1DeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Stop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStopLnurlpApiV1Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopLnurlpApiV1Delete(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiStopLnurlpApiV1Delete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
