import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { btmlList: string[] }

@Component({
  selector: 'app-visited-bp-modal',
  templateUrl: './visited-bp-modal.component.html',
  styleUrls: ['./visited-bp-modal.component.scss'],
})
export class VisitedBpModalComponent extends BaseModal<IFormData> implements OnInit {

  protected formIdentifier: string = 'visitedBp';

  @Input() set data(value: any) {
    if (value.formData) {
      this.bpVisitedList = value.formData.btmlList;
    }
  }

  bpVisitedList = [];

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() {
    if (!this.bpVisitedList.length) {
      this.bpVisitedList = this.core.bps.visitedBP;
    }
  }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps.fetchBitpass(btml, Bitpass).then(bp => {
        this.bpCache[btml].next(bp.product);
      }).catch(err => {
        console.error('Cannot fetch minipass for url ' + btml);
      });
    }
    return this.bpCache[btml];
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      btmlList: []
    };
    dataStore.btmlList = this.bpVisitedList;
    this.formDataStore = dataStore;
  }
}
