import { Injectable } from '@angular/core';
import { CoreProvider } from './core';
import { map } from 'rxjs/operators';
import { Record } from './api/models';
import { firstValueFrom, Observable } from 'rxjs';
import { FileImpl, IRequestContext } from '../lib/agram-worker/worker-context';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DataServiceProvider {
  core: CoreProvider = null;

  /**
   * Old direct binding requests
   */
  requests = {
    nextBtmlKey: (type: string) =>
      this.core.api.btml.nextBtmlKey({
        address: 'address_server',
        btml_type: type,
      }),

    keyLastTen: (lastTen: string) =>
      this.core.api.btml.findLastTenByKey({address: 'address_server', btml: lastTen}),
    valueLastTen: (lastTen: string) =>
      this.core.api.btml.findByValue({
        address: 'address_server',
        value: lastTen
      }),
    postRecord: (record: Record) =>
      this.core.api.btml.create({body: record}),
    getIpfs: (cid: string) => new Observable((sub) => {
      this.core.api.ipfs.getfiles({CID: cid}).pipe(
        map(async itm => JSON.parse(await itm.text()))
      ).subscribe({
        next: async (res) => sub.next(await res),
        error: sub.error
      })
    }),
    getIpfsFile: (cid: string) =>
      this.core.api.ipfs.getfiles({CID: cid}),
    putIpfs: (file: File) =>
      this.core.api.ipfs.pinIpfs({cluster: 'server_ipfs_node', body: {file}}),
    pkpassSign: (files: File[]) =>
      this.core.api.jwt.makePkpass({body: {file: files}}),

    pgpSign: (data: string, privateKey: string) =>
      this.core.api.verify.signMessage({body:
        { data, privateKey },
      }),
    pgpVerify: (signedMessage: string, data: string, publicKey: string) =>
      this.core.api.verify.verifyMessage({body:
        { signedMessage, message: data, publicKey }
      }),
    addTaskToWorker: (btml: string, timestamp: number = 0) =>
      this.core.api.agram.addWorkerTask({
        worker: 'general_worker_server',
        body: { btml, timestamp: `${timestamp}` }
      }),
  };


  private get autho() {
    const headers = {
      'x-browser-id': environment.browser_id
    }
    if (environment.auth_token)
      headers['Authorization'] = `Bearer ${environment.auth_token}`;
    return {headers};
  }

  /**
   * Standardized worker requests
   */
  workerRequests: IRequestContext = {
    ipfsGet: (cid: string) => fetch(this.core.relayEndpoint.replace(/\/$/g, '') + '/IPFS/search/' + cid, this.autho),
    addressGet: (address: string, chain: string = 'testnet') => fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/BTC/${chain}/search/balance/${address}`, this.autho),
    newAddressUser: (passphrase: string, chain: string = 'testnet', type: string = 'segwit') => fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/BTC/${chain}/create/address/${type}`, {
      headers: { ...this.autho.headers, 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ passphrase }), // TODO: should use ypub instead of littleuser
    }),
    makeTransfer: (transferCnf: any, chain: string = 'testnet') => fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/BTC/{chain}/send/tx/segwit`, {
      headers: { ...this.autho.headers, 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(transferCnf),
    }),
    ipfsPin: (dataObject: any, cluster: string = 'server_ipfs_node') => {
      const formData = new FormData();
      formData.append('file', this.core.fileFromJson(dataObject));
      return fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/IPFS/${cluster}/pin/file`, {
        headers: this.autho.headers,
        method: 'POST',
        body: formData,
      });
    },
    ipfsPinFile: async (file: FileImpl, cluster: string = 'server_ipfs_node') => {
      const formData = new FormData();
      formData.append('file', new File(file.fileBits, file.fileName));
      return fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/IPFS/${cluster}/pin/file`, {
        headers: this.autho.headers,
        method: 'POST',
        body: formData,
      });
    },
    keyLastTen: (lastTen: string, address = 'address_server') =>
      fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/BTML/${address}/search/${lastTen}`, this.autho),
    nextBtmlKey: (type: string, address = 'address_server') =>
      fetch(this.core.relayEndpoint.replace(/\/$/g, '') + `/BTML/${address}/search/${type}/next`, this.autho),
    postRecord: (record: any) =>
      fetch(this.core.relayEndpoint.replace(/\/$/g, '') + '/BTML', {
        headers: { ...this.autho.headers, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(record),
      }),
    pgpSign: (data: string, privateKey: string) =>
      fetch(this.core.relayEndpoint.replace(/\/$/g, '') + '/PGP/sign', {
        headers: { ...this.autho.headers, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ data, privateKey }),
      }),
    pgpVerify: (signedMessage: string, data: string, publicKey: string) =>
      fetch(this.core.relayEndpoint.replace(/\/$/g, '') + '/PGP/verify', {
        headers: { ...this.autho.headers, 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ signedMessage, message: data, publicKey }),
      }),
    pkpassSign: async (files: FileImpl[]) => {
      const formData = new FormData();
      for (const file of files) {
        formData.append('file', new File(file.fileBits, file.fileName));
      }

      return fetch(this.core.relayEndpoint.replace(/\/$/g, '') + '/NPM/create/pkpass', {
        headers: this.autho.headers,
        method: 'POST',
        body: formData,
      });
    },

  };

  constructor() {}

  initCore(core: CoreProvider) {
    this.core = core;

    // TODO: Subscribe to logout to wipe local data
  }
}
