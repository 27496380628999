/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateWithdrawData } from '../models/create-withdraw-data';

@Injectable({ providedIn: 'root' })
export class WithdrawService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet()` */
  static readonly WithdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGetPath = '/withdraw/api/v1/lnurl/{unique_hash}';

  /**
   * Withdraw.Api Lnurl Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.WithdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('unique_hash', params.unique_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Withdraw.Api Lnurl Response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
    },
    context?: HttpContext
): Observable<void> {
    return this.withdrawApiLnurlResponseWithdrawApiV1LnurlUniqueHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet()` */
  static readonly WithdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGetPath = '/withdraw/api/v1/lnurl/cb/{unique_hash}';

  /**
   * lnurl withdraw callback.
   *
   * This endpoints allows you to put unique_hash, k1
   *         and a payment_request to get your payment_request paid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
      k1: string;
      pr: string;
      id_unique_hash?: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.WithdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('unique_hash', params.unique_hash, {});
      rb.query('k1', params.k1, {});
      rb.query('pr', params.pr, {});
      rb.query('id_unique_hash', params.id_unique_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * lnurl withdraw callback.
   *
   * This endpoints allows you to put unique_hash, k1
   *         and a payment_request to get your payment_request paid.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
      k1: string;
      pr: string;
      id_unique_hash?: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.withdrawApiLnurlCallbackWithdrawApiV1LnurlCbUniqueHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet()` */
  static readonly WithdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGetPath = '/withdraw/api/v1/lnurl/{unique_hash}/{id_unique_hash}';

  /**
   * Withdraw.Api Lnurl Multi Response.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
      id_unique_hash: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.WithdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('unique_hash', params.unique_hash, {});
      rb.path('id_unique_hash', params.id_unique_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Withdraw.Api Lnurl Multi Response.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet(
    chain: string,
    token: string | undefined,
    params: {
      unique_hash: any;
      id_unique_hash: any;
    },
    context?: HttpContext
): Observable<void> {
    return this.withdrawApiLnurlMultiResponseWithdrawApiV1LnurlUniqueHashIdUniqueHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `indexWithdrawGet()` */
  static readonly IndexWithdrawGetPath = '/withdraw/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexWithdrawGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexWithdrawGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.IndexWithdrawGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexWithdrawGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexWithdrawGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexWithdrawGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `displayWithdrawLinkIdGet()` */
  static readonly DisplayWithdrawLinkIdGetPath = '/withdraw/{link_id}';

  /**
   * Display.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `displayWithdrawLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayWithdrawLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.DisplayWithdrawLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Display.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `displayWithdrawLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayWithdrawLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.displayWithdrawLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `imgWithdrawImgLinkIdGet()` */
  static readonly ImgWithdrawImgLinkIdGetPath = '/withdraw/img/{link_id}';

  /**
   * Img.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `imgWithdrawImgLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  imgWithdrawImgLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ImgWithdrawImgLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Img.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `imgWithdrawImgLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  imgWithdrawImgLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<void> {
    return this.imgWithdrawImgLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `printQrWithdrawPrintLinkIdGet()` */
  static readonly PrintQrWithdrawPrintLinkIdGetPath = '/withdraw/print/{link_id}';

  /**
   * Print Qr.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `printQrWithdrawPrintLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  printQrWithdrawPrintLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.PrintQrWithdrawPrintLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Print Qr.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `printQrWithdrawPrintLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  printQrWithdrawPrintLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.printQrWithdrawPrintLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `csvWithdrawCsvLinkIdGet()` */
  static readonly CsvWithdrawCsvLinkIdGetPath = '/withdraw/csv/{link_id}';

  /**
   * Csv.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvWithdrawCsvLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  csvWithdrawCsvLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.CsvWithdrawCsvLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Csv.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvWithdrawCsvLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csvWithdrawCsvLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.csvWithdrawCsvLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `apiLinksWithdrawApiV1LinksGet()` */
  static readonly ApiLinksWithdrawApiV1LinksGetPath = '/withdraw/api/v1/links';

  /**
   * Api Links.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinksWithdrawApiV1LinksGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinksWithdrawApiV1LinksGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiLinksWithdrawApiV1LinksGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Links.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinksWithdrawApiV1LinksGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinksWithdrawApiV1LinksGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinksWithdrawApiV1LinksGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkCreateOrUpdateWithdrawApiV1LinksPost()` */
  static readonly ApiLinkCreateOrUpdateWithdrawApiV1LinksPostPath = '/withdraw/api/v1/links';

  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkCreateOrUpdateWithdrawApiV1LinksPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateWithdrawApiV1LinksPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id?: string;
      body: CreateWithdrawData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiLinkCreateOrUpdateWithdrawApiV1LinksPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('link_id', params.link_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkCreateOrUpdateWithdrawApiV1LinksPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateWithdrawApiV1LinksPost(
    chain: string,
    token: string | undefined,
    params: {
      link_id?: string;
      body: CreateWithdrawData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkCreateOrUpdateWithdrawApiV1LinksPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkRetrieveWithdrawApiV1LinksLinkIdGet()` */
  static readonly ApiLinkRetrieveWithdrawApiV1LinksLinkIdGetPath = '/withdraw/api/v1/links/{link_id}';

  /**
   * Api Link Retrieve.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkRetrieveWithdrawApiV1LinksLinkIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkRetrieveWithdrawApiV1LinksLinkIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiLinkRetrieveWithdrawApiV1LinksLinkIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Retrieve.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkRetrieveWithdrawApiV1LinksLinkIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkRetrieveWithdrawApiV1LinksLinkIdGet(
    chain: string,
    token: string | undefined,
    params: {
      link_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkRetrieveWithdrawApiV1LinksLinkIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut()` */
  static readonly ApiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPutPath = '/withdraw/api/v1/links/{link_id}';

  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: string;
      body: CreateWithdrawData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Create Or Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut(
    chain: string,
    token: string | undefined,
    params: {
      link_id: string;
      body: CreateWithdrawData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkCreateOrUpdateWithdrawApiV1LinksLinkIdPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLinkDeleteWithdrawApiV1LinksLinkIdDelete()` */
  static readonly ApiLinkDeleteWithdrawApiV1LinksLinkIdDeletePath = '/withdraw/api/v1/links/{link_id}';

  /**
   * Api Link Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLinkDeleteWithdrawApiV1LinksLinkIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkDeleteWithdrawApiV1LinksLinkIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiLinkDeleteWithdrawApiV1LinksLinkIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('link_id', params.link_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Link Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLinkDeleteWithdrawApiV1LinksLinkIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiLinkDeleteWithdrawApiV1LinksLinkIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      link_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLinkDeleteWithdrawApiV1LinksLinkIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet()` */
  static readonly ApiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGetPath = '/withdraw/api/v1/links/{the_hash}/{lnurl_id}';

  /**
   * Api Hash Retrieve.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      the_hash: any;
      lnurl_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WithdrawService.ApiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('the_hash', params.the_hash, {});
      rb.path('lnurl_id', params.lnurl_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Hash Retrieve.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet(
    chain: string,
    token: string | undefined,
    params: {
      the_hash: any;
      lnurl_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiHashRetrieveWithdrawApiV1LinksTheHashLnurlIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
