import { Component, Input, OnInit } from '@angular/core';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { pkpassCid: string }

@Component({
  selector: 'app-pkpass-viewer-modal',
  templateUrl: './pkpass-viewer-modal.component.html',
  styleUrls: ['./pkpass-viewer-modal.component.scss'],
})
export class PkpassViewerModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'pkpassViewer';

  cid: string;

  @Input()
  set data(value: any) {
    if (value.formData) {
      this.cid = value.formData.pkpassCid;
    } else { // By default, a bitpass is landed
      this.cid = value.bitpass.pkpass_cid;
    }
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() {}

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      pkpassCid: ''
    };
    dataStore.pkpassCid = this.cid;
    this.formDataStore = dataStore;
  }
}
