import { Specific } from "../base-specific";

export interface ITaskProcessParams {
    input: string[];
    output: string[];
}
export interface ITaskProcess {
    label: string;
    actionSpec: string;
    params: ITaskProcessParams;
}
export interface ITaskResult {
    code: number;
    output: string;
}
export interface ITask extends Specific {
    process: ITaskProcess[];
    variables: string[];
    result: ITaskResult;
}
export type ITaskStore = Omit<ITask, 'btmlPart'|'__proto'>;
export type IPendingTask = Omit<ITask, 'result'> & {result?: ITaskResult};
export type IPendingTaskStore = Omit<IPendingTask, 'btmlPart'|'__proto'>;

export class Task extends Specific implements ITask  {
    readonly _proto?: string = 'Task';
    readonly btmlPart: number = 7;

    process: ITaskProcess[] = null;
    variables: string[] = null;
    result: ITaskResult = null;

    constructor(data: ITask) {
        super(data);

        this.process = data.process;
        this.variables = data.variables;
        this.result = data.result;

        // Implement update logic here
    }
}
