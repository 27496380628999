import { BtmlMap, ParentSpecific } from "../../base-specific";
import { Certificate, CertificateTypes } from "./certificate/certificate";


export type ConceptTypes = Certificate<CertificateTypes>;
export const ConceptBtmlMap: BtmlMap = {
    1: Certificate,
}

export interface IConcept<T extends ConceptTypes> extends ParentSpecific<T> {
    conceptName: string;
    conceptDescription: string;
}

export class Concept<T extends ConceptTypes> extends ParentSpecific<T> implements IConcept<T> {
    readonly _proto?: string = 'Concept';
    readonly btmlPart: number = 2;

    conceptName: string = null;
    conceptDescription: string = null;

    constructor(data: IConcept<T>) {
        super(data, ConceptBtmlMap);

        this.conceptName = data.conceptName;
        this.conceptDescription = data.conceptDescription;

        // Implement update logic here
    }

}
