/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Record } from '../models/record';
import { RecordTime } from '../models/record-time';


/**
 * `Explore` the intentional web of value using <b>Bitcoin Tree Markup Language (Btml)</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer0Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `databaseControllerGetAddressList()` */
  static readonly DatabaseControllerGetAddressListPath = '/BTML/search/addressList';

  /**
   * List of available addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddressList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressList$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerGetAddressListPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * List of available addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddressList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddressList(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.getAddressList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `databaseControllerCheck()` */
  static readonly DatabaseControllerCheckPath = '/BTML/{address}/check/{key}/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `check()` instead.
   *
   * This method doesn't expect any request body.
   */
  check$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      key: string;
      value: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerCheckPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('key', params.key, {});
      rb.path('value', params.value, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `check$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  check(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      key: string;
      value: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.check$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerFindByKey()` */
  static readonly DatabaseControllerFindByKeyPath = '/BTML/{address}/search/key/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByKey$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      key: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerFindByKeyPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findByKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByKey(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      key: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.findByKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerFindByValue()` */
  static readonly DatabaseControllerFindByValuePath = '/BTML/{address}/search/value/{value}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByValue()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByValue$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      value: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerFindByValuePath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('value', params.value, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findByValue$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByValue(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      value: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.findByValue$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerNextBtmlKey()` */
  static readonly DatabaseControllerNextBtmlKeyPath = '/BTML/{address}/search/{btml_type}/next';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nextBtmlKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  nextBtmlKey$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml_type: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerNextBtmlKeyPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml_type', params.btml_type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `nextBtmlKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  nextBtmlKey(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml_type: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.nextBtmlKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerFindLastTenByKey()` */
  static readonly DatabaseControllerFindLastTenByKeyPath = '/BTML/{address}/search/{btml}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findLastTenByKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLastTenByKey$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerFindLastTenByKeyPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml', params.btml, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findLastTenByKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findLastTenByKey(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.findLastTenByKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerLasttenByType()` */
  static readonly DatabaseControllerLasttenByTypePath = '/BTML/{address}/search/{btml_type}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lasttenByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  lasttenByType$Response(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml_type: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerLasttenByTypePath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml_type', params.btml_type, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lasttenByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lasttenByType(
    params: {

    /**
     * Add a valid BTC address
     */
      address: 'address_server';
      btml_type: string;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.lasttenByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerDeleteById()` */
  static readonly DatabaseControllerDeleteByIdPath = '/BTML/{key}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(
    params: {
      key: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerDeleteByIdPath, 'delete');
    if (params) {
      rb.path('key', params.key, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(
    params: {
      key: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.deleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `databaseControllerFind()` */
  static readonly DatabaseControllerFindPath = '/BTML';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(
    params?: {
      filter?: any;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RecordTime>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RecordTime>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(
    params?: {
      filter?: any;
    },
    context?: HttpContext
  ): Observable<RecordTime> {
    return this.find$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordTime>): RecordTime => r.body)
    );
  }

  /** Path part for operation `databaseControllerUpdateById()` */
  static readonly DatabaseControllerUpdateByIdPath = '/BTML';

  /**
   * For more <a href='https://docs.detailorg.com/en/latest/' target='_blank'>information</a>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(
    params?: {
      body?: Record
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerUpdateByIdPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * For more <a href='https://docs.detailorg.com/en/latest/' target='_blank'>information</a>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(
    params?: {
      body?: Record
    },
    context?: HttpContext
  ): Observable<void> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `databaseControllerCreate()` */
  static readonly DatabaseControllerCreatePath = '/BTML';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params?: {
      body?: Record
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Record>> {
    const rb = new RequestBuilder(this.rootUrl, Layer0Service.DatabaseControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Record>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params?: {
      body?: Record
    },
    context?: HttpContext
  ): Observable<Record> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Record>): Record => r.body)
    );
  }

}
