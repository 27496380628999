/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';


@Injectable({ providedIn: 'root' })
export class CoreNonApiWebsiteRoutesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `faviconFaviconIcoGet()` */
  static readonly FaviconFaviconIcoGetPath = '/favicon.ico';

  /**
   * Favicon.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `faviconFaviconIcoGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  faviconFaviconIcoGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.FaviconFaviconIcoGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Favicon.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `faviconFaviconIcoGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  faviconFaviconIcoGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<void> {
    return this.faviconFaviconIcoGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `homeGet()` */
  static readonly HomeGetPath = '/';

  /**
   * Home.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `homeGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  homeGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      lightning?: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.HomeGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('lightning', params.lightning, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Home.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `homeGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  homeGet(
    chain: string,
    token?: string | undefined,
    params?: {
      lightning?: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.homeGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `robotsRobotsTxtGet()` */
  static readonly RobotsRobotsTxtGetPath = '/robots.txt';

  /**
   * Robots.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `robotsRobotsTxtGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  robotsRobotsTxtGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.RobotsRobotsTxtGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Robots.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `robotsRobotsTxtGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  robotsRobotsTxtGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<string> {
    return this.robotsRobotsTxtGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `installExtensionsExtensionsGet()` */
  static readonly InstallExtensionsExtensionsGetPath = '/extensions';

  /**
   * Install.Extensions.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `installExtensionsExtensionsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  installExtensionsExtensionsGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      activate?: string;
      deactivate?: string;
      enable?: string;
      disable?: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.InstallExtensionsExtensionsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('activate', params.activate, {});
      rb.query('deactivate', params.deactivate, {});
      rb.query('enable', params.enable, {});
      rb.query('disable', params.disable, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Install.Extensions.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `installExtensionsExtensionsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  installExtensionsExtensionsGet(
    chain: string,
    token: string | undefined,
    params: {
      activate?: string;
      deactivate?: string;
      enable?: string;
      disable?: string;
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.installExtensionsExtensionsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `walletWalletGet()` */
  static readonly WalletWalletGetPath = '/wallet';

  /**
   * Wallet.
   *
   * Args:
   *
   * just **wallet_name**: create a new user, then create a new wallet for user with wallet_name<br>
   * just **user_id**: return the first user wallet or create one if none found (with default wallet_name)<br>
   * **user_id** and **wallet_name**: create a new wallet for user with wallet_name<br>
   * **user_id** and **wallet_id**: return that wallet if user is the owner<br>
   * nothing: create everything<br>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `walletWalletGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  walletWalletGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      nme?: string;
      usr?: string;
      wal?: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.WalletWalletGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('nme', params.nme, {});
      rb.query('usr', params.usr, {});
      rb.query('wal', params.wal, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Wallet.
   *
   * Args:
   *
   * just **wallet_name**: create a new user, then create a new wallet for user with wallet_name<br>
   * just **user_id**: return the first user wallet or create one if none found (with default wallet_name)<br>
   * **user_id** and **wallet_name**: create a new wallet for user with wallet_name<br>
   * **user_id** and **wallet_id**: return that wallet if user is the owner<br>
   * nothing: create everything<br>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `walletWalletGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  walletWalletGet(
    chain: string,
    token?: string | undefined,
    params?: {
      nme?: string;
      usr?: string;
      wal?: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.walletWalletGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `lnurlFullWithdrawWithdrawGet()` */
  static readonly LnurlFullWithdrawWithdrawGetPath = '/withdraw';

  /**
   * Lnurl Full Withdraw.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlFullWithdrawWithdrawGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlFullWithdrawWithdrawGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.LnurlFullWithdrawWithdrawGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurl Full Withdraw.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlFullWithdrawWithdrawGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlFullWithdrawWithdrawGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlFullWithdrawWithdrawGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `lnurlFullWithdrawCallbackWithdrawCbGet()` */
  static readonly LnurlFullWithdrawCallbackWithdrawCbGetPath = '/withdraw/cb';

  /**
   * Lnurl Full Withdraw Callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlFullWithdrawCallbackWithdrawCbGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlFullWithdrawCallbackWithdrawCbGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.LnurlFullWithdrawCallbackWithdrawCbGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurl Full Withdraw Callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlFullWithdrawCallbackWithdrawCbGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlFullWithdrawCallbackWithdrawCbGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlFullWithdrawCallbackWithdrawCbGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `deletewalletDeletewalletGet()` */
  static readonly DeletewalletDeletewalletGetPath = '/deletewallet';

  /**
   * Deletewallet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletewalletDeletewalletGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletewalletDeletewalletGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      wal: string;
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.DeletewalletDeletewalletGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('wal', params.wal, {});
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Deletewallet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletewalletDeletewalletGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletewalletDeletewalletGet(
    chain: string,
    token: string | undefined,
    params: {
      wal: string;
      usr: string;
    },
    context?: HttpContext
): Observable<void> {
    return this.deletewalletDeletewalletGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `lnurlBalanceNotifyWithdrawNotifyServiceGet()` */
  static readonly LnurlBalanceNotifyWithdrawNotifyServiceGetPath = '/withdraw/notify/{service}';

  /**
   * Lnurl Balance Notify.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnurlBalanceNotifyWithdrawNotifyServiceGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlBalanceNotifyWithdrawNotifyServiceGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      service: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.LnurlBalanceNotifyWithdrawNotifyServiceGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('service', params.service, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnurl Balance Notify.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnurlBalanceNotifyWithdrawNotifyServiceGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnurlBalanceNotifyWithdrawNotifyServiceGet(
    chain: string,
    token: string | undefined,
    params: {
      service: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnurlBalanceNotifyWithdrawNotifyServiceGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `coreLnurlwalletLnurlwalletGet()` */
  static readonly CoreLnurlwalletLnurlwalletGetPath = '/lnurlwallet';

  /**
   * Core.Lnurlwallet.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `coreLnurlwalletLnurlwalletGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  coreLnurlwalletLnurlwalletGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.CoreLnurlwalletLnurlwalletGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Core.Lnurlwallet.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `coreLnurlwalletLnurlwalletGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  coreLnurlwalletLnurlwalletGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<void> {
    return this.coreLnurlwalletLnurlwalletGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `serviceWorkerServiceWorkerJsGet()` */
  static readonly ServiceWorkerServiceWorkerJsGetPath = '/service-worker.js';

  /**
   * Service Worker.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `serviceWorkerServiceWorkerJsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceWorkerServiceWorkerJsGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.ServiceWorkerServiceWorkerJsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
  /**
   * Service Worker.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `serviceWorkerServiceWorkerJsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  serviceWorkerServiceWorkerJsGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<void> {
    return this.serviceWorkerServiceWorkerJsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
  /** Path part for operation `manifestManifestUsrWebmanifestGet()` */
  static readonly ManifestManifestUsrWebmanifestGetPath = '/manifest/{usr}.webmanifest';

  /**
   * Manifest.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manifestManifestUsrWebmanifestGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  manifestManifestUsrWebmanifestGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.ManifestManifestUsrWebmanifestGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Manifest.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `manifestManifestUsrWebmanifestGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manifestManifestUsrWebmanifestGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.manifestManifestUsrWebmanifestGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `indexAdminGet()` */
  static readonly IndexAdminGetPath = '/admin';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexAdminGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.IndexAdminGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexAdminGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexAdminGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `hexToUuid4Uuidv4HexValueGet()` */
  static readonly HexToUuid4Uuidv4HexValueGetPath = '/uuidv4/{hex_value}';

  /**
   * Hex To Uuid4.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `hexToUuid4Uuidv4HexValueGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  hexToUuid4Uuidv4HexValueGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      hex_value: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, CoreNonApiWebsiteRoutesService.HexToUuid4Uuidv4HexValueGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('hex_value', params.hex_value, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Hex To Uuid4.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `hexToUuid4Uuidv4HexValueGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  hexToUuid4Uuidv4HexValueGet(
    chain: string,
    token: string | undefined,
    params: {
      hex_value: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.hexToUuid4Uuidv4HexValueGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
