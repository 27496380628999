/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

export class PgpSignAction extends RunnableAction {
  static readonly identifier = 'pgpSign';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const signableObjRef = JSON.parse(params[0]);
    const privateKey = params[1];

    return [
      await new Promise<string>((ok, ko) => {
        WorkerContext.instance().signSignable(
          signableObjRef,
          privateKey,
          (sign: string) => ok(sign),
          (err: any) => ko(err),
        );
      }),
    ];
  }
}

