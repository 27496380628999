/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';



/**
 * `Combine` everything in applications to have <b>Automated Market Makers (AMM)</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer9Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agramNodeValidation()` */
  static readonly AgramNodeValidationPath = '/AGRAM/{address}/check/node_validation/{btml}';

  /**
   * Check if a Bitpass is validated for node admins.
   *
   * Give a Btml of a Bitpass to receive the state of validation for the node administrators:
   *     - validate: is validated
   *     - non-validate: is not validated yet
   *     - invalidate: is mark like invalid
   *     - wrong-validated: there are a problem with the signatures of validation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `node_validation()` instead.
   *
   * This method doesn't expect any request body.
   */
  node_validation$Response(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, Layer9Service.AgramNodeValidationPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml', params.btml, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Possible responses
         */
        'btml_owner'?: Array<any>;
        }>;
      })
    );
  }

  /**
   * Check if a Bitpass is validated for node admins.
   *
   * Give a Btml of a Bitpass to receive the state of validation for the node administrators:
   *     - validate: is validated
   *     - non-validate: is not validated yet
   *     - invalidate: is mark like invalid
   *     - wrong-validated: there are a problem with the signatures of validation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `node_validation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  node_validation(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}> {
    return this.node_validation$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}>): {

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
} => r.body)
    );
  }

  /** Path part for operation `agramSignStrict()` */
  static readonly AgramSignStrictPath = '/AGRAM/{address}/check/sign_strict/{btml}';

  /**
   * Check if a Bitpass is well signed.
   *
   * Give a Btml of a Bitpass to receive how is signed:
   *     - owned-signed: The owner signed correctly the Bitpass
   *     - signed: The Bitpass is correctly signed but for other that is not the actual owner
   *     - wrong: The Bitpass is not correctly signed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sign_strict()` instead.
   *
   * This method doesn't expect any request body.
   */
  sign_strict$Response(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}>> {
    const rb = new RequestBuilder(this.rootUrl, Layer9Service.AgramSignStrictPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml', params.btml, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * Possible responses
         */
        'btml_owner'?: Array<any>;
        }>;
      })
    );
  }

  /**
   * Check if a Bitpass is well signed.
   *
   * Give a Btml of a Bitpass to receive how is signed:
   *     - owned-signed: The owner signed correctly the Bitpass
   *     - signed: The Bitpass is correctly signed but for other that is not the actual owner
   *     - wrong: The Bitpass is not correctly signed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sign_strict$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sign_strict(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}> {
    return this.sign_strict$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
}>): {

/**
 * Possible responses
 */
'btml_owner'?: Array<any>;
} => r.body)
    );
  }

  /** Path part for operation `importControllerSbParse()` */
  static readonly ImportControllerSbParsePath = '/AGRAM/{worker}/import/parse/{externalDB}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sbParse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sbParse$Response(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';

    /**
     * Select the external data base
     *  Available values : sb
     */
      externalDB: 'sb';
      body?: {
}
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{
}>> {
    const rb = new RequestBuilder(this.rootUrl, Layer9Service.ImportControllerSbParsePath, 'post');
    if (params) {
      rb.path('worker', params.worker, {});
      rb.path('externalDB', params.externalDB, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sbParse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sbParse(
    params: {

    /**
     * Add a valid Agram Worker
     */
      worker: 'general_worker_server';

    /**
     * Select the external data base
     *  Available values : sb
     */
      externalDB: 'sb';
      body?: {
}
    },
    context?: HttpContext
  ): Observable<{
}> {
    return this.sbParse$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

  /** Path part for operation `agramOwnerBtml()` */
  static readonly AgramOwnerBtmlPath = '/AGRAM/{address}/search/owner/{btml}';

  /**
   * Search the owner of a Bitpass.
   *
   * Give a Btml of a Bitpass to receive its owner Bitpass Btml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownerBtml()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerBtml$Response(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<{

/**
 * This is the owner´s BTML of the BitPass
 */
'btml_owner'?: string;
}>> {
    const rb = new RequestBuilder(this.rootUrl, Layer9Service.AgramOwnerBtmlPath, 'get');
    if (params) {
      rb.path('address', params.address, {});
      rb.path('btml', params.btml, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        
        /**
         * This is the owner´s BTML of the BitPass
         */
        'btml_owner'?: string;
        }>;
      })
    );
  }

  /**
   * Search the owner of a Bitpass.
   *
   * Give a Btml of a Bitpass to receive its owner Bitpass Btml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownerBtml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownerBtml(
    params: {

    /**
     * Add a valid Agram Node address
     */
      address: string;

    /**
     * Add a valid Bitpass Btml
     */
      btml: string;
    },
    context?: HttpContext
  ): Observable<{

/**
 * This is the owner´s BTML of the BitPass
 */
'btml_owner'?: string;
}> {
    return this.ownerBtml$Response(params, context).pipe(
      map((r: StrictHttpResponse<{

/**
 * This is the owner´s BTML of the BitPass
 */
'btml_owner'?: string;
}>): {

/**
 * This is the owner´s BTML of the BitPass
 */
'btml_owner'?: string;
} => r.body)
    );
  }

}
