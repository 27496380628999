import { ActionSpec, RunnableAction } from '../runnable-action';

export class ExtractBitpassAction extends RunnableAction {
  static readonly identifier = 'extractBitpass';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const bitpassJson = JSON.parse(params[0]);
    return [
      bitpassJson.data_cid,
      bitpassJson.pkpass_cid,
      bitpassJson.signature,
      bitpassJson.author,
    ];
  }
}
