/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkerContext } from '../worker-context';
import { ActionSpec, RunnableAction } from '../runnable-action';

const requests = WorkerContext.instance().requests;

export class KeyLastTenAction extends RunnableAction {
  static readonly identifier = 'keyLastTen';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const keyLastTen = params[0];

    const resp = await requests.keyLastTen(keyLastTen);
    if (!resp.ok)
      throw new Error(
        'Error fetching keyLastTen ' +
        keyLastTen +
        '. Error: ' +
        (await resp.text()),
      );

    return [await resp.text()];
  }
}
