import { BtmlMap, ParentSpecific } from "../../base-specific";
import { Person } from "./person/person";


export type IdentityTypes = Person;
export const IdentityBtmlMap: BtmlMap = {
    3: Person,
}

export interface IIdentity<T extends IdentityTypes> extends ParentSpecific<T> {
    publicKey: string;
}

export class Identity<T extends IdentityTypes> extends ParentSpecific<T> implements IIdentity<T> {
    readonly _proto?: string = 'Identity';
    readonly btmlPart: number = 3;

    /**
     * PGP Public key for bitpasses signature validation
     */
    publicKey: string = null;

    constructor(data: IIdentity<T>) {
        super(data, IdentityBtmlMap);

        this.publicKey = data.publicKey;
        // Implement update logic here
    }

}
