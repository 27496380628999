import { Specific } from "../../../base-specific";

export interface IPerson extends Specific {
    personName: string;
}

export class Person extends Specific implements IPerson  {
    readonly _proto?: string = 'Person';
    readonly btmlPart: number = 3;

    /**
     * PGP Public key for bitpasses signature validation
     */
    personName: string = null;

    constructor(data: IPerson) {
        super(data);

        this.personName = data.personName;

        // Implement update logic here
    }
}
