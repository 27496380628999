/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyLastTenAction } from './actions/key-last-ten';
import { LastRecordAction } from './actions/last-record';
import { RunnableAction } from './runnable-action';
import { SetVarAction } from './actions/set-var';
import { SumAction } from './actions/sum';
import { ExtractBitpassAction } from './actions/extract-bitpass';
import { FetchIpfsJsonAction } from './actions/fetch-ipfs-json';
import { ExtractRecordAction } from './actions/extract-record';
import { PinIpfsJsonAction } from './actions/pin-ipfs-json';
import { PostRecordAction } from './actions/post-record';
import { NextBtmlKeyAction } from './actions/next-btml';
import { PgpVerifyAction } from './actions/pgp-verify';
import { PgpSignAction } from './actions/pgp-sign';
import { SaveBitpassAction } from './actions/save-bitpass';
import { FetchBitpassAction } from './actions/fetch-bitpass';
import { RunImportAction } from './actions/run-import';
import { SavePkpassAction } from './actions/save-pkpass';
import { BtcDayUserSegwitTx } from './actions/btc-day-user-segwit-tx';
import { OwnershipCreate } from './actions/ownership-create';
import { OwnershipTransfer } from './actions/ownership-transfer';
import { OpenFormAction } from './actions/open-form';

const actionList = [
  SumAction,
  SetVarAction,
  KeyLastTenAction,
  LastRecordAction,
  ExtractRecordAction,
  ExtractBitpassAction,
  FetchIpfsJsonAction,
  PinIpfsJsonAction,
  PostRecordAction,
  NextBtmlKeyAction,
  PgpVerifyAction,
  PgpSignAction,
  SaveBitpassAction,
  FetchBitpassAction,
  RunImportAction,
  SavePkpassAction,
  BtcDayUserSegwitTx,
  OwnershipCreate,
  OwnershipTransfer,
  OpenFormAction,
];

const actionListMap = actionList.reduce((acc, curr) => ({
  ...acc,
  [curr.identifier]: curr,
}), {} as { [key: string]: typeof RunnableAction });

export function getActionExecutor(
  name: string,
  config: any = null,
): RunnableAction | null {
  // Explicitly indicate constructor overlap and then mark as constructable
  const action = actionListMap[name] as {
    new(config: any): RunnableAction
  } & typeof RunnableAction as {
    new(config: any): RunnableAction
  };
  return action ? new action(config) : null;
}
