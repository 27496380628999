/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateAddress } from '../models/create-address';
import { CreateDomain } from '../models/create-domain';

@Injectable({ providedIn: 'root' })
export class LnaddressService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet()` */
  static readonly LnaddressLnurlCallbackLnaddressLnurlCbAddressIdGetPath = '/lnaddress/lnurl/cb/{address_id}';

  /**
   * Lnaddress.Lnurl Callback.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      address_id: any;
      amount: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.LnaddressLnurlCallbackLnaddressLnurlCbAddressIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('address_id', params.address_id, {});
      rb.query('amount', params.amount, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Lnaddress.Lnurl Callback.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet(
    chain: string,
    token: string | undefined,
    params: {
      address_id: any;
      amount: number;
    },
    context?: HttpContext
): Observable<any> {
    return this.lnaddressLnurlCallbackLnaddressLnurlCbAddressIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `indexLnaddressGet()` */
  static readonly IndexLnaddressGetPath = '/lnaddress/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexLnaddressGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexLnaddressGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.IndexLnaddressGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexLnaddressGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexLnaddressGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexLnaddressGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `displayLnaddressDomainIdGet()` */
  static readonly DisplayLnaddressDomainIdGetPath = '/lnaddress/{domain_id}';

  /**
   * Display.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `displayLnaddressDomainIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayLnaddressDomainIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.DisplayLnaddressDomainIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Display.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `displayLnaddressDomainIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  displayLnaddressDomainIdGet(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.displayLnaddressDomainIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `apiDomainsLnaddressApiV1DomainsGet()` */
  static readonly ApiDomainsLnaddressApiV1DomainsGetPath = '/lnaddress/api/v1/domains';

  /**
   * Api Domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDomainsLnaddressApiV1DomainsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDomainsLnaddressApiV1DomainsGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiDomainsLnaddressApiV1DomainsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDomainsLnaddressApiV1DomainsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDomainsLnaddressApiV1DomainsGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDomainsLnaddressApiV1DomainsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDomainCreateLnaddressApiV1DomainsPost()` */
  static readonly ApiDomainCreateLnaddressApiV1DomainsPostPath = '/lnaddress/api/v1/domains';

  /**
   * Api Domain Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDomainCreateLnaddressApiV1DomainsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDomainCreateLnaddressApiV1DomainsPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id?: any;
      body: CreateDomain
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiDomainCreateLnaddressApiV1DomainsPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('domain_id', params.domain_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Domain Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDomainCreateLnaddressApiV1DomainsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDomainCreateLnaddressApiV1DomainsPost(
    chain: string,
    token: string | undefined,
    params: {
      domain_id?: any;
      body: CreateDomain
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDomainCreateLnaddressApiV1DomainsPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDomainCreateLnaddressApiV1DomainsDomainIdPut()` */
  static readonly ApiDomainCreateLnaddressApiV1DomainsDomainIdPutPath = '/lnaddress/api/v1/domains/{domain_id}';

  /**
   * Api Domain Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDomainCreateLnaddressApiV1DomainsDomainIdPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDomainCreateLnaddressApiV1DomainsDomainIdPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      body: CreateDomain
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiDomainCreateLnaddressApiV1DomainsDomainIdPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Domain Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDomainCreateLnaddressApiV1DomainsDomainIdPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiDomainCreateLnaddressApiV1DomainsDomainIdPut(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      body: CreateDomain
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDomainCreateLnaddressApiV1DomainsDomainIdPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete()` */
  static readonly ApiDomainDeleteLnaddressApiV1DomainsDomainIdDeletePath = '/lnaddress/api/v1/domains/{domain_id}';

  /**
   * Api Domain Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiDomainDeleteLnaddressApiV1DomainsDomainIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Domain Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiDomainDeleteLnaddressApiV1DomainsDomainIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiAddressesLnaddressApiV1AddressesGet()` */
  static readonly ApiAddressesLnaddressApiV1AddressesGetPath = '/lnaddress/api/v1/addresses';

  /**
   * Api Addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAddressesLnaddressApiV1AddressesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressesLnaddressApiV1AddressesGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiAddressesLnaddressApiV1AddressesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAddressesLnaddressApiV1AddressesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressesLnaddressApiV1AddressesGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiAddressesLnaddressApiV1AddressesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet()` */
  static readonly ApiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGetPath = '/lnaddress/api/v1/address/availabity/{domain_id}/{username}';

  /**
   * Api Check Available Username.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      username: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
      rb.path('username', params.username, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Check Available Username.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      username: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiCheckAvailableUsernameLnaddressApiV1AddressAvailabityDomainIdUsernameGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet()` */
  static readonly ApiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGetPath = '/lnaddress/api/v1/address/{domain}/{username}/{wallet_key}';

  /**
   * Api Get User Info.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      username: any;
      wallet_key: any;
      domain: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('username', params.username, {});
      rb.path('wallet_key', params.wallet_key, {});
      rb.path('domain', params.domain, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Get User Info.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet(
    chain: string,
    token: string | undefined,
    params: {
      username: any;
      wallet_key: any;
      domain: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiGetUserInfoLnaddressApiV1AddressDomainUsernameWalletKeyGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut()` */
  static readonly ApiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPutPath = '/lnaddress/api/v1/address/{domain_id}/{user}/{wallet_key}';

  /**
   * Api Lnaddress Make Address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      user: any;
      wallet_key: any;
      body: CreateAddress
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
      rb.path('user', params.user, {});
      rb.path('wallet_key', params.wallet_key, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Lnaddress Make Address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      user: any;
      wallet_key: any;
      body: CreateAddress
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdUserWalletKeyPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost()` */
  static readonly ApiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPostPath = '/lnaddress/api/v1/address/{domain_id}';

  /**
   * Api Lnaddress Make Address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      user?: any;
      wallet_key?: any;
      body: CreateAddress
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('domain_id', params.domain_id, {});
      rb.query('user', params.user, {});
      rb.query('wallet_key', params.wallet_key, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Lnaddress Make Address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost(
    chain: string,
    token: string | undefined,
    params: {
      domain_id: any;
      user?: any;
      wallet_key?: any;
      body: CreateAddress
    },
    context?: HttpContext
): Observable<any> {
    return this.apiLnaddressMakeAddressLnaddressApiV1AddressDomainIdPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet()` */
  static readonly ApiAddressSendAddressLnaddressApiV1AddressesPaymentHashGetPath = '/lnaddress/api/v1/addresses/{payment_hash}';

  /**
   * Api Address Send Address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiAddressSendAddressLnaddressApiV1AddressesPaymentHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('payment_hash', params.payment_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Address Send Address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet(
    chain: string,
    token: string | undefined,
    params: {
      payment_hash: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiAddressSendAddressLnaddressApiV1AddressesPaymentHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete()` */
  static readonly ApiAddressDeleteLnaddressApiV1AddressesAddressIdDeletePath = '/lnaddress/api/v1/addresses/{address_id}';

  /**
   * Api Address Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      address_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiAddressDeleteLnaddressApiV1AddressesAddressIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('address_id', params.address_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Address Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      address_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiAddressDeleteLnaddressApiV1AddressesAddressIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiStopLnaddressApiV1Delete()` */
  static readonly ApiStopLnaddressApiV1DeletePath = '/lnaddress/api/v1';

  /**
   * Api Stop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStopLnaddressApiV1Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopLnaddressApiV1Delete$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, LnaddressService.ApiStopLnaddressApiV1DeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Stop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStopLnaddressApiV1Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopLnaddressApiV1Delete(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiStopLnaddressApiV1Delete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
