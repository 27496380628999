import { ActionSpec, RunnableAction } from '../runnable-action';

export class ExtractRecordAction extends RunnableAction {
  static readonly identifier = 'extractRecord';
  public actionSpec: ActionSpec = {
    description: '',
    actionParmDef: {
      input: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ],
      output: [
        { pattern: '.*', label: 'Pending to implement', tooltip: 'TODO' },
      ]
    }
  };
  async run(...params: string[]): Promise<string[]> {
    const recordJson = JSON.parse(params[0]);
    return [recordJson.key, recordJson.value, recordJson.timestamp];
  }
}
