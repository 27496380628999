import { BtmlMap, ParentSpecific } from "../../../base-specific";
import { Monetary, MonetaryTypes } from "./monetary/monetary";


export type FinancialTypes = Monetary<MonetaryTypes>;
export const FinancialBtmlMap: BtmlMap = {
    1: Monetary,
}

export interface IFinancial<T extends FinancialTypes> extends ParentSpecific<T> {
}

export class Financial<T extends FinancialTypes> extends ParentSpecific<T> implements IFinancial<T> {
    readonly _proto?: string = 'Financial';
    readonly btmlPart: number = 1;

    constructor(data: IFinancial<T>) {
        super(data, FinancialBtmlMap);

        // Implement update logic here
    }

}
