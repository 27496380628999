import { Component, Input } from '@angular/core';
import { ToastController, LoadingController, ModalController } from '@ionic/angular';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import Cropper from 'cropperjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'page-addimagecomponent',
  templateUrl: 'addimagecomponent.html',
  styleUrls: ['addimagecomponent.scss']
})
export class AddImageComponentPage {
  imgData: any = null;
  fr = new FileReader();
  cropper: Cropper = null;
  blobData: Blob = null;
  imgWidth: number;
  imgType: string;
  imgName: string;
  conventionalFormat = false;

  pendingUnploadArray = [];
  nextUploadIterationIndex = 0;
  uploadedImageItems = [];

  @Input("initialFiles") set initialFiles(value) {
    if (!value || !value.length) return;
    this.pendingUnploadArray.push(...value);
    this.handleUpload();
  }
  @Input("fixedSize") fixedSize: {width:number, height:number}|null = null;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private _loadingCtrl: LoadingController,
    private _http: HttpClient,
  ) { }

  ngOnInit() {
    this.fr.onload = async () => {
      // let load = await this.core.createLoading(this.lang.transform('Processing')+'...');
      this.imgData = this.fr.result;

      let image = new Image();
      image.src = this.imgData;
      image.onload = (event: any) => {
        let img = event.target || event.path[0];
        if (!this.fixedSize || (this.fixedSize.width==img.width && this.fixedSize.height==img.height)) {
          setTimeout(() => {
            this.saveImage();
          }, 1000);
        } else {
          this.blobData = null;
          if (this.cropper) { this.cropper.destroy(); }

          this.cropper = new Cropper(<HTMLImageElement> document.getElementById('image'), {
            viewMode: 2,
            aspectRatio: this.fixedSize.width / this.fixedSize.height,
            cropBoxResizable: true,

            data: {x: 0, y: 0, width: this.fixedSize.width, height: this.fixedSize.height, rotate: 0, scaleX: 1, scaleY: 1},
            dragMode: <Cropper.DragMode> 'move'
          });
        }
      };

    };
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (!files[0]) { return; }
    this.pendingUnploadArray = [files[0]];
    this.handleUpload();
  }

  public manualUpload(event) {
    if (!event.target.files[0]) { return; }
    this.pendingUnploadArray = [event.target.files[0]];
    this.handleUpload();
  }

  public terminateCrop() {
    let croppedCanvas = this.cropper.getCroppedCanvas();

    if (croppedCanvas) {
      this.imgData = croppedCanvas.toDataURL('image/png');
      this.imgWidth = croppedCanvas.width;
      croppedCanvas.toBlob((blob) => {
        this.blobData = blob;

        // if (this.conventionalFormat)
        this.saveImage();
        if (this.cropper) this.cropper.destroy();
      });
    } else {
      this.cropper.destroy();
    }
  }

  public async saveImage() {
    this.uploadedImageItems.push(new File([this.blobData], this.imgName, {
      type: this.imgType
    }));
    this.handleUpload();
  }

  handleUpload() {
    console.log('handling upload', this.nextUploadIterationIndex, this.pendingUnploadArray);
    this.imgData = null;
    this.cropper = null; // TODO: May be this causes bad performance
    this.blobData = null;
    this.imgType = null;
    this.imgName = null;
    this.imgWidth = 0;

    if (this.pendingUnploadArray[this.nextUploadIterationIndex]) {
      if (this.pendingUnploadArray[this.nextUploadIterationIndex]['fileEntry']) {
        (<FileSystemFileEntry> this.pendingUnploadArray[this.nextUploadIterationIndex].fileEntry).file((file: File) => {
          this.blobData = file;
          this.imgName = file.name;
          this.imgType = file.type;
          this.fr.readAsDataURL(this.blobData);
        });
      } else {
        this.blobData = this.pendingUnploadArray[this.nextUploadIterationIndex];
        this.imgName = this.pendingUnploadArray[this.nextUploadIterationIndex].name;
        this.imgType = this.pendingUnploadArray[this.nextUploadIterationIndex].type;
        this.fr.readAsDataURL(this.blobData);
      }

      this.nextUploadIterationIndex++;
    } else {
      this.modalCtrl.dismiss(this.uploadedImageItems);
    }
  }

  dismiss = () => this.modalCtrl.dismiss();

}
