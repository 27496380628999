import { BtmlMap, ParentSpecific, Specific } from "../base-specific";

export interface IWorkerParams extends Specific {
    timestamp: number;
    btml: string
}

export interface IWorker extends Specific {
    taskPending: IWorkerParams;
    taskFailed: IWorkerParams;
    taskDone: IWorkerParams;
}

export class Worker extends Specific implements IWorker {
    readonly _proto: string = 'Worker';
    readonly btmlPart: number = 8;

    taskPending: IWorkerParams = null;
    taskFailed: IWorkerParams;
    taskDone: IWorkerParams;

    constructor(data: IWorker) {
        super(data);

        this.taskPending = data.taskPending;
        this.taskDone = data.taskDone;
        this.taskFailed = data.taskFailed;
    }

}
