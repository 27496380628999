/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Balance } from '../models/balance';
import { OpReturnsResponse } from '../models/op-returns-response';
import { PostBtmlRequest } from '../models/post-btml-request';
import { PrivateDataRequestNew } from '../models/private-data-request-new';
import { XPubRequest } from '../models/x-pub-request';


/**
 * `Find` the verifiable state of things in your own customizable <b>Block Explorer</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer8Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bitcoinControllerXpubBalance()` */
  static readonly BitcoinControllerXpubBalancePath = '/BTC/{chain}/search/balance/ypub';

  /**
   * Explore wallet balance.
   *
   * Gives you the balance of a:
   *
   * - *<b>LittleUser</b>: The seed storage in the internal space for the user in the node (use 'passphrase' parameter).
   * - From a public master key (use 'ypub' parameter).
   *
   * *<b>LittleUser</b>: The cyphered seed is save in the server,
   * user internal storage is only for 1 seed, if you create another one it will be overwritten. User external storage for manage more than 1 seed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `XpubBalance()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  XpubBalance$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: PrivateDataRequestNew
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Balance>> {
    const rb = new RequestBuilder(this.rootUrl, Layer8Service.BitcoinControllerXpubBalancePath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Balance>;
      })
    );
  }

  /**
   * Explore wallet balance.
   *
   * Gives you the balance of a:
   *
   * - *<b>LittleUser</b>: The seed storage in the internal space for the user in the node (use 'passphrase' parameter).
   * - From a public master key (use 'ypub' parameter).
   *
   * *<b>LittleUser</b>: The cyphered seed is save in the server,
   * user internal storage is only for 1 seed, if you create another one it will be overwritten. User external storage for manage more than 1 seed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `XpubBalance$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  XpubBalance(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: PrivateDataRequestNew
    },
    context?: HttpContext
  ): Observable<Balance> {
    return this.XpubBalance$Response(params, context).pipe(
      map((r: StrictHttpResponse<Balance>): Balance => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerBalanceChain()` */
  static readonly BitcoinControllerBalanceChainPath = '/BTC/{chain}/search/balance/{address}';

  /**
   * Explore address balance.
   *
   * Gives you the balance of a address (use 'address' parameter).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `balanceChain()` instead.
   *
   * This method doesn't expect any request body.
   */
  balanceChain$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid address
     */
      address: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Balance>> {
    const rb = new RequestBuilder(this.rootUrl, Layer8Service.BitcoinControllerBalanceChainPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('address', params.address, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Balance>;
      })
    );
  }

  /**
   * Explore address balance.
   *
   * Gives you the balance of a address (use 'address' parameter).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `balanceChain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  balanceChain(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid address
     */
      address: string;
    },
    context?: HttpContext
  ): Observable<Balance> {
    return this.balanceChain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Balance>): Balance => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerOpReturnByAddress()` */
  static readonly BitcoinControllerOpReturnByAddressPath = '/BTC/{chain}/search/record/address/{address}';

  /**
   * Explore the timestamps of an address.
   *
   * Add the chain and an address to receive the list of timestamps (opreturn) made for it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `OpReturnByAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  OpReturnByAddress$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid BTC address
     */
      address: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OpReturnsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer8Service.BitcoinControllerOpReturnByAddressPath, 'get');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.path('address', params.address, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpReturnsResponse>;
      })
    );
  }

  /**
   * Explore the timestamps of an address.
   *
   * Add the chain and an address to receive the list of timestamps (opreturn) made for it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `OpReturnByAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  OpReturnByAddress(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;

    /**
     * Add a valid BTC address
     */
      address: string;
    },
    context?: HttpContext
  ): Observable<OpReturnsResponse> {
    return this.OpReturnByAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<OpReturnsResponse>): OpReturnsResponse => r.body)
    );
  }

  /** Path part for operation `bitcoinControllerOpReturnByXpub()` */
  static readonly BitcoinControllerOpReturnByXpubPath = '/BTC/{chain}/search/record/ypub';

  /**
   * Explore the timestamps of a wallet.
   *
   * Add the chain and a public master key (ypub) to receive the list of timestamps (opreturn) made for it.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `OpReturnByXpub()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  OpReturnByXpub$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
  
    /**
     * Add a valid BTC public master key (ypub)
     */
    body?: XPubRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OpReturnsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer8Service.BitcoinControllerOpReturnByXpubPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OpReturnsResponse>;
      })
    );
  }

  /**
   * Explore the timestamps of a wallet.
   *
   * Add the chain and a public master key (ypub) to receive the list of timestamps (opreturn) made for it.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `OpReturnByXpub$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  OpReturnByXpub(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
  
    /**
     * Add a valid BTC public master key (ypub)
     */
    body?: XPubRequest
    },
    context?: HttpContext
  ): Observable<OpReturnsResponse> {
    return this.OpReturnByXpub$Response(params, context).pipe(
      map((r: StrictHttpResponse<OpReturnsResponse>): OpReturnsResponse => r.body)
    );
  }

  /** Path part for operation `btmlControllerSearch()` */
  static readonly BtmlControllerSearchPath = '/BTML/search';

  /**
   * List of available workers.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `search()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  search$Response(
    params?: {
      body?: PostBtmlRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, Layer8Service.BtmlControllerSearchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * List of available workers.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `search$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  search(
    params?: {
      body?: PostBtmlRequest
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.search$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

}
