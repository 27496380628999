/* eslint-disable @typescript-eslint/no-explicit-any */
import { getActionExecutor } from './action-catalog';

export interface WorkerInitConfig {
  workerBackend: 'memory' | 'bitpass';
  signAuthor: string;
  signKey: string;
  workerBitpassBtml?: string;
  pendingTaskStore?: any;
}

export const DEFAULT_WORKER_BITPASS_BTML = '8000000000';
export class WorkerExecution {
  private _status: 'pending' | 'working' | 'finished' | 'no_task' = 'pending';
  get status() {
    return this._status;
  }

  config: WorkerInitConfig;

  taskDef: any = null;
  taskProcess: any[];
  workerDef: any = null;
  taskBtml: string;
  sharedParams: { [key: string]: string } = {};

  constructor() { }

  async init(initConfig?: Partial<WorkerInitConfig>) {
    this.config = {
      workerBackend: 'memory',
      signAuthor: process?.env?.WORKER_SIGN_AUTHOR ?? '',
      signKey: process?.env?.WORKER_SIGN_PRIVKEY ?? '',
    };
    for (const key in initConfig) this.config[key as keyof WorkerInitConfig] = initConfig[key as keyof WorkerInitConfig];

    if (this.config.workerBackend == 'bitpass') {
      this.workerDef = await this.fetchBitpass(this.config.workerBitpassBtml ?? DEFAULT_WORKER_BITPASS_BTML);
      // TODO: Check signature of fetched worker bitpass
      console.log('Worker?', this.workerDef[0].val.specific);

      if (this.workerDef[0].val.specific._proto !== 'Worker') {
        throw new Error(`Cannot fetch worker bitpass`);
      }

      if (this.workerDef[0].val.specific.taskPending.length) {
        this.taskBtml = this.workerDef[0].val.specific.taskPending
          .filter((t: { timestamp: number }) => t.timestamp < Date.now())
          .sort(
            (a: { timestamp: number }, b: { timestamp: number }) =>
              b.timestamp - a.timestamp,
          )[0].btml;

        this._status = 'pending';
      } else {
        this._status = 'no_task';
      }
    } else if (this.config.workerBackend == 'memory') {
      if (this.config.pendingTaskStore.process.length) {
        this._status = 'pending';
      } else {
        this._status = 'no_task';
      }
    }

    return this;
  }

  async run() {
    if (this.status !== 'pending') return;
    this._status = 'working';

    // Run process actions
    if (this.config.workerBackend == 'bitpass') {
      const btmlTask = this.taskBtml;
      this.taskDef = await this.fetchBitpass(btmlTask);
      console.log('Task?', this.taskDef[0].val.specific);
      if (this.taskDef[0].val.specific._proto !== 'Task') {
        throw new Error(`BTML ${btmlTask} is not a Task`);
      }
      this.taskProcess = this.taskDef[0].val.specific.process;
    } else if (this.config.workerBackend == 'memory') {
      this.taskProcess = this.config.pendingTaskStore.process;
    }

    let taskResult: { code: number, output: string } = { code: 1, output: '' };

    try {
      for (const proc of this.taskProcess) {
        taskResult.output += `${proc.actionSpec} - ${proc.label}\n`;
        const btmlAction = proc.actionSpec;
        const actionDef = await this.fetchBitpass(btmlAction);
        // console.log('Action?', actionDef[0].val.specific);
        if (
          actionDef[0].val.specific._proto !== 'Resource' ||
          actionDef[0].val.specific.specific._proto !== 'Action'
        ) {
          throw new Error(`BTML ${btmlAction} is not an Action`);
        }
        await this.executeAction(
          actionDef[0].val.specific.specific,
          proc.params.input,
          proc.params.output,
        );
      }

      console.log('Finish values of shared variables: ', this.sharedParams);

      taskResult = {
        code: 0,
        output: JSON.stringify({
          variables: this.sharedParams,
        }),
      };
    } catch (err) {
      taskResult.output += `\nError: ${err}\n`;
    }

    if (this.config.workerBackend == 'bitpass') {
      // Save progress at worker bitpass
      this.workerDef[0].val.specific.taskPending = this.workerDef[0].val.specific.taskPending.filter(
        // eslint-disable-next-line eqeqeq
        (t: any) => t.btml != this.taskBtml,
      );
      this.workerDef[0].val.specific[taskResult.code == 0 ? 'taskDone' : 'taskFailed'].push({
        timestamp: Date.now(),
        btml: this.taskBtml,
      });

      // Propose task change and saveWorkerBitpass
      // TODO: Also do at failed tasks
      let saved = false;
      while (!saved) {
        try {
          this.taskDef[0].val.specific.result = taskResult;
          await this.saveTaskProposal();
          saved = true;
        } catch (err) {
          console.log(
            'Critical error, cannot mark executed action as done, retrying. Error:',
            err,
          );
        }
      }
      saved = false;
      while (!saved) {
        try {
          await this.saveWorkerBitpass();
          saved = true;
        } catch (err) {
          console.log(
            'Critical error, cannot mark executed action as done, retrying. Error:',
            err,
          );
        }
      }
    }

    this._status = 'finished';
    return taskResult;
  }

  private getParams(params: string[]): string[] {
    for (let i = 0; i < params.length; i++) {
      if (params[i].startsWith('var://')) {
        params[i] = this.sharedParams[params[i].replace('var://', '')];
      }
    }
    return params;
  }

  private readOutputParams(result: string[], outputParams: string[]) {
    console.log('readOutputParams');
    for (let i = 0; i < result.length; i++) {
      const pointingVar = outputParams[i];
      console.log('pointingVar', pointingVar);
      if (pointingVar.startsWith('var://')) {
        this.sharedParams[pointingVar.replace('var://', '')] = result[i];
      } else {
        console.warn('Discarded result', result[i]);
      }
    }
  }

  private async executeAction(
    actionDef: any,
    inputParams: string[],
    outputParams: string[],
  ) {
    console.log('Executing action method', actionDef.actionMethod);

    const action = getActionExecutor(
      actionDef.actionMethod,
      actionDef.actionConfig,
    );
    if (action) {
      const result = await action.run(...this.getParams(inputParams));
      // falla
      this.readOutputParams(result, outputParams);

      console.log(' - Result ', result);
    } else {
      throw new Error('Undefined action //TODO:name');
    }
  }

  private async fetchBitpass(btml: string) {
    return JSON.parse(
      (
        await getActionExecutor('fetchBitpass')!.run(
          btml,
        )
      )[1],
    );
  }

  private async saveWorkerBitpass() {
    const bitpassData = this.workerDef;
    const signAuthor = this.config.signAuthor;
    const signKey = this.config.signKey;
    const btml = this.config.workerBitpassBtml ?? DEFAULT_WORKER_BITPASS_BTML;

    console.log(
      'SAVED WORKER BITPASS',
      await getActionExecutor('saveBitpass')!.run(
        JSON.stringify(bitpassData),
        signAuthor,
        signKey,
        btml.substring(0, 5),
        btml,
      ),
    );
  }

  private async saveTaskProposal() {
    const bitpassData = this.taskDef;
    const signAuthor = this.config.signAuthor;
    const signKey = this.config.signKey;

    console.log(
      'PROPOSED TASK BITPASS',
      await getActionExecutor('saveBitpass')!.run(
        JSON.stringify(bitpassData),
        signAuthor,
        signKey,
        '',
        `${this.taskBtml}`,
      ),
    );
  }

  private userStorageToObject(storage: string) {
    return JSON.parse(JSON.parse(JSON.parse(storage).response));
  }
}
