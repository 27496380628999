import { Specific } from "../base-specific";

export const LangOptionsEntries = ['es', 'en'] as const;
export type LangOptions = typeof LangOptionsEntries[number];
export const MenuOptionsEntries = ['wallet', 'criteria', 'comments', 'actions', 'identity', 'history', 'planified', 'statistics', 'applications'] as const;
export type MenuOptions = typeof MenuOptionsEntries[number];
export const ActionOptionsEntries = ['see', 'see-visited_bp', 'see-fullscreen', 'see-qr_code', 'see-pk_pass', 'see-3d', 'create', 'create-edit_actual', 'create-edit_actual-json', 'create-edit_actual-save', 'create-edit_actual-cancel', 'create-edit_actual-defer', 'create-edit_new', 'create-edit_new-json', 'create-edit_new-save', 'create-edit_new-cancel', 'create-edit_new-defer', 'create-edit_form', 'create-edit_form-json', 'create-edit_form-save', 'create-edit_form-cancel', 'create-edit_form-defer', 'create-edit_registry', 'create-edit_registry-save', 'create-edit_registry-cancel', 'create-compilation', 'create-claim_confirm', 'run-claim_notification', 'create-identity', 'condition', 'condition-sale', 'condition-buy', 'condition-pay', 'condition-receive', 'exchange', 'exchange-product_chat', 'run', 'run-confirm_buy', 'run-cancel_sale', 'verify', 'verify-subparts', 'verify-property', 'verify-product_history', 'verify-pending_tasks', 'save', 'save-cart', 'save-download', 'save-fav', 'plan', 'plan-program', 'plan-task', 'value', 'value-rate', 'apps', 'apps-new', 'apps-new-save', 'apps-new-cancel', 'apps-guide'] as const;
export type ActionOptions = typeof ActionOptionsEntries[number];

export interface ITheme {
  content: {
    theme: 'dark' | 'light';
    selected_lang: LangOptions;
  };
  fullscreen: {
    background_particles: boolean;
    media_timer: -1 | 3 | 10 | 25 | 60 | 600 | 3600;
  };
  carrusel: {
    media_timer: -1 | 3 | 10 | 25 | 60 | 600 | 3600;
  };
  style: {
    color_CTA: string;
    font_family: string;
  };
  wallet: {
    fiat_order: string[];
  };
}
export interface SearchElement {
  btml_type: string | null;
  name: string;
  icon: string;
}
export interface IApplicationContent {
  app_name: string;
  initial_btml: string;
  images: {
    welcome?: string;
    favicon?: string;
    loading?: string;
    fallback?: string;
  };
  show_menu: boolean;
  menu_elements: {
    [key in MenuOptions]?: { icon?: string; name?: string; };
  } | null;
  show_search: boolean;
  search_elements: SearchElement[];
  action_elements: {
    [key in ActionOptions]?: { icon?: string; name?: string; };
  } | null;
}
export type TTranslations = { [key in LangOptions]: { [key in TranslationConstants]: string } };
export type OptTTranslations = { [key in LangOptions]?: { [key in TranslationConstants]?: string } };
export interface IApplicationProps {
  theme: ITheme;
  app_version: number;
  content: IApplicationContent;
  translations: OptTTranslations;
}
export type IApplication = IApplicationProps & Specific;

export class Application extends Specific implements IApplication {
  readonly _proto?: string = "Application";
  readonly btmlPart: number = 9;

  theme: ITheme = null as unknown as ITheme;
  app_version: number = null as unknown as number;
  content: IApplicationContent = null as unknown as IApplicationContent;
  translations: OptTTranslations = null as unknown as OptTTranslations;

  constructor(data: IApplication) {
    super(data);

    this.theme = data.theme;
    this.app_version = data.app_version;
    this.content = data.content;
    this.content = data.content;
    this.translations = data.translations;

    // Implement update logic here
  }
}

export const TranslationConstantsOptions = ['title', 'description', 'tooltip_description', 'aux', 'tooltip_aux', 'tooltip_favicon', 'tooltip_url_title', 'tooltip_footer', 'tooltip_spinner', 'tooltip_background', 'tooltip_background_thumbnail', 'start_btn', 'start_visitor', 'agram_DID_title', 'agram_did_description', 'edit_btn', 'save_btn', 'title_product', 'tooltip_product_title', 'explorer', 'title_identity', 'primary_identity', 'bitpass_identity', 'sign_in', 'title_wallet', 'title_criteria', 'title_chat', 'title_actions', 'title_identities', 'title_record', 'title_planned', 'title_statistics', 'title_applications', 'title_configuration', 'title_node_lookup', 'title_BP_resources_advertising', 'title_BP_resources_products', 'title_BP_resources_certificates', 'title_BP_resources_contacts', 'title_BP_resources_actions', 'title_BP_resources_verifications', 'title_BP_resources_regards', 'title_BP_resources_planned', 'title_BP_resources_analysis', 'see', 'product_visited_bp', 'product_fullscreen', 'product_qr_code', 'product_pk_pass', 'product_3d', 'product_create', 'product_edit_actual', 'product_edit_save', 'product_edit_registry', 'product_edit_form', 'product_edit_new', 'product_edit_cancel', 'product_edit_json', 'compilation', 'product_version_app', 'create_identity', 'product_condition', 'sale', 'buy', 'pay', 'receive', 'exchange', 'product_chat', 'run', 'add_to_cart', 'confirm_buy', 'cancell_sale', 'verify', 'subparts', 'property', 'product_history', 'pending_tasks', 'save', 'cart', 'download', 'fav', 'plan', 'program', 'task', 'value', 'rate', 'guide', 'title_BP_resources_applications', 'editBp', 'visitedBp', 'fullscreen', 'bitpassQR', 'pkpassViewer', 'bpEditor', 'createIdentity', 'bpSubparts', 'bpProperty', 'bpHistory', 'bitpassClaimConfirm', 'bitpassClaimNotification'] as const;
export type TranslationConstants = typeof TranslationConstantsOptions[number];
