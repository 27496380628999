import { BitpassData } from "src/app/lib/bitpass-types/models/bitpass-data";
import { ITaskProcess } from "src/app/lib/bitpass-types/models/specific/task/task";

export function createTaskFromTpl(name, process: ITaskProcess[], variables: string[]): BitpassData<any>[] {
  return [
    {
      _proto: 'ICON',
      title: 'Task',
      val: {
        title: name,
        background: '',
        description: 'The Fantastic Guitars Collection',
        aux: 'Javier Arres',
        media: [
          {
            type: 'image',
            url: 'ipfs://QmNjyGQnxnNpXe4yKDAVSfwWKXQKPCtJZBHZbs9RhHzKMr',
            label: 'label',
          },
        ],
        'pass.json': {
          organizationName: 'Javier Arres',
          logoText: 'Botanic Guitar',
          headerFields: [
            {
              key: 'Detailorg_1631960329054',
              label: 'Javier Arres',
              value: 'Botanic Guitar',
            },
          ],
          primaryFields: [],
          secondaryFields: [],
          auxiliaryFields: [],
          backFields: [],
          foregroundColor: 'rgb(0,0,0)',
          backgroundColor: 'rgb(155,155,155)',
        },
        passImages: {
          'logo.png': 'ipfs://QmfRaFGqcqRAqhfEh2BUt2HNKbryxt5MLuTbwwSRJz78GD',
          'logo@2x.png': '',
          'background.png':
            'ipfs://QmdTexQRAS9tg2k5LLvbjR2MfNgvoLZXCYTAxzu1RznrZp',
          'background@2x.png': '',
          'strip.png': 'ipfs://QmXYTnSESXqHRJhPSs3BmJxPw6pqzZZ1fuZSYkzwvdCuaq',
          'strip@2x.png': '',
          'footer.png': '',
          'footer@2x.png': '',
          'thumbnail.png':
            'ipfs://QmSs2keRyfstQmCPwDKgY6aqKtuJJqHDMAHhjzkqFoiuGP',
          'thumbnail@2x.png': '',
          'icon.png': 'ipfs://QmQVm9NG3p12ygPkMqjsCmiS8wVFRpAK3JxX3fA1913HeB',
          'icon@2x.png': '',
        },
        schemaVersion: 3,
        icon: 'ipfs://QmUR5cZLAHRGa1byCsq4NpjZDbZbLwj8LNtkQUxMNW2FCt',
        summary: {
          title: name,
          cards: [],
        },
        updated: 1636326451820,
        claimHash: null,
        soldInfo: null,
        distributionIdentities: [],
        specific: {
          _proto: 'Task',
          btmlPart: 7,

          process: process,
          variables: variables,
          result: {
            code: 0,
            output: '',
          },
        },
      },
    },
  ];
}
