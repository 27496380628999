/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiService } from './services/api.service';
import { CoreNonApiWebsiteRoutesService } from './services/core-non-api-website-routes.service';
import { LnaddressService } from './services/lnaddress.service';
import { TPoSService } from './services/t-po-s.service';
import { UsermanagerService } from './services/usermanager.service';
import { WithdrawService } from './services/withdraw.service';
import { LnurlpService } from './services/lnurlp.service';
import { WatchonlyService } from './services/watchonly.service';
import { InvoicesService } from './services/invoices.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiService,
    CoreNonApiWebsiteRoutesService,
    LnaddressService,
    TPoSService,
    UsermanagerService,
    WithdrawService,
    LnurlpService,
    WatchonlyService,
    InvoicesService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
