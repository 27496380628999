import { Component, Input } from "@angular/core";
import { Bitpass } from "src/app/lib/bitpass-types/models/bitpass";
import { CoreProvider } from "src/app/services/core";
import { environment } from "src/environments/environment";

@Component({ template: 'overriden' })
export default abstract class BaseModal<FormData> {

  protected abstract readonly formIdentifier: string;
  protected formDataStore: FormData;

  public get fds() {return this.formDataStore;};

  @Input()
  set data(value: { bitpass?: Bitpass<any>, formData?: FormData, autoSubmit?: boolean, [other: string]: any }) {
    console.log('Base model data @input of ' + this.formIdentifier, value);
    if (value.formData) this.formDataStore = value.formData;
  }

  constructor(public core: CoreProvider) { }

  /**
   * This method should perform commit action BASED ONLY ON FORM DATA and maybe return feedback of result, in case
   * of error should throw an error as promise result. It can also provide feedback to user interface during the process.
   *
   * @param data FormData
   */
  protected abstract onCommit(data: FormData): Promise<any>;

  /**
   * This is the method that should be called from interface when the user wants to commit from action, it can been overriden
   */
  public onSubmit() {
    this.onCommit(this.formDataStore);
  }

  /**
   * This is a public interface to commit an action without rendering the component, direct binding to commit method.
   *
   * @param data FormData
   */
  public commit(data: FormData): Promise<any> {
    return this.onCommit(data);
  }

  /**
   * Method to be called from interface when user wants to defer form commits at actual form state
   */
  deferModal() {
    this.core.successToast(null, 'Se han guardado los datos del formulario en la página acciones')
      this.core.actions.addLocalAction({
        actionSpec: environment.open_form_action_btml,
        label: 'Open ' + this.formIdentifier + ' form',
        params: {
          input: [this.formIdentifier, JSON.stringify(this.formDataStore)],
          output: [],
        }
      });
  }

}
