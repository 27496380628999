/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CreateUserData } from '../models/create-user-data';
import { CreateUserWallet } from '../models/create-user-wallet';
import { Payment } from '../models/payment';
import { User } from '../models/user';
import { UserDetailed } from '../models/user-detailed';
import { Wallet } from '../models/wallet';

@Injectable({ providedIn: 'root' })
export class UsermanagerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `indexUsermanagerGet()` */
  static readonly IndexUsermanagerGetPath = '/usermanager/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexUsermanagerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUsermanagerGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.IndexUsermanagerGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexUsermanagerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexUsermanagerGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexUsermanagerGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `userListUsermanagerApiV1UsersGet()` */
  static readonly UserListUsermanagerApiV1UsersGetPath = '/usermanager/api/v1/users';

  /**
   * get list of users.
   *
   * Retrieves all users, supporting flexible filtering (LHS Brackets).
   *
   * ### Syntax
   * `field[op]=value`
   *
   * ### Example Query Strings
   * ```
   * email[eq]=test@mail.com
   * name[ex]=dont-want&name[ex]=dont-want-too
   * extra.role[ne]=role-id
   * ```
   * ### Operators
   * - eq, ne
   * - gt, lt
   * - in (include)
   * - ex (exclude)
   *
   * Fitlers are AND-combined
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userListUsermanagerApiV1UsersGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userListUsermanagerApiV1UsersGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      id?: string;

    /**
     * Supports Filtering
     */
      name?: string;

    /**
     * Supports Filtering
     */
      email?: string;

    /**
     * Supports Filtering
     */
      extra?: {
[key: string]: string;
};
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Array<User>>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.UserListUsermanagerApiV1UsersGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('sortby', params.sortby, {});
      rb.query('direction', params.direction, {});
      rb.query('search', params.search, {});
      rb.query('id', params.id, {});
      rb.query('name', params.name, {});
      rb.query('email', params.email, {});
      rb.query('extra', params.extra, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }
  /**
   * get list of users.
   *
   * Retrieves all users, supporting flexible filtering (LHS Brackets).
   *
   * ### Syntax
   * `field[op]=value`
   *
   * ### Example Query Strings
   * ```
   * email[eq]=test@mail.com
   * name[ex]=dont-want&name[ex]=dont-want-too
   * extra.role[ne]=role-id
   * ```
   * ### Operators
   * - eq, ne
   * - gt, lt
   * - in (include)
   * - ex (exclude)
   *
   * Fitlers are AND-combined
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userListUsermanagerApiV1UsersGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userListUsermanagerApiV1UsersGet(
    chain: string,
    token?: string | undefined,
    params?: {
      limit?: number;
      offset?: number;
      sortby?: string;
      direction?: 'asc' | 'desc';

    /**
     * Text based search
     */
      search?: string;

    /**
     * Supports Filtering
     */
      id?: string;

    /**
     * Supports Filtering
     */
      name?: string;

    /**
     * Supports Filtering
     */
      email?: string;

    /**
     * Supports Filtering
     */
      extra?: {
[key: string]: string;
};
    },
    context?: HttpContext
): Observable<Array<User>> {
    return this.userListUsermanagerApiV1UsersGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }
  /** Path part for operation `userCreateUsermanagerApiV1UsersPost()` */
  static readonly UserCreateUsermanagerApiV1UsersPostPath = '/usermanager/api/v1/users';

  /**
   * Create a new user.
   *
   * Create a new user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreateUsermanagerApiV1UsersPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateUsermanagerApiV1UsersPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateUserData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<UserDetailed>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.UserCreateUsermanagerApiV1UsersPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailed>;
      })
    );
  }
  /**
   * Create a new user.
   *
   * Create a new user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCreateUsermanagerApiV1UsersPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreateUsermanagerApiV1UsersPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateUserData
    },
    context?: HttpContext
): Observable<UserDetailed> {
    return this.userCreateUsermanagerApiV1UsersPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<UserDetailed>): UserDetailed => r.body)
    );
  }
  /** Path part for operation `userGetUsermanagerApiV1UsersUserIdGet()` */
  static readonly UserGetUsermanagerApiV1UsersUserIdGetPath = '/usermanager/api/v1/users/{user_id}';

  /**
   * Get a specific user.
   *
   * get user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUsermanagerApiV1UsersUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsermanagerApiV1UsersUserIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<UserDetailed>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.UserGetUsermanagerApiV1UsersUserIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailed>;
      })
    );
  }
  /**
   * Get a specific user.
   *
   * get user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUsermanagerApiV1UsersUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUsermanagerApiV1UsersUserIdGet(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
    },
    context?: HttpContext
): Observable<UserDetailed> {
    return this.userGetUsermanagerApiV1UsersUserIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<UserDetailed>): UserDetailed => r.body)
    );
  }
  /** Path part for operation `userDeleteUsermanagerApiV1UsersUserIdDelete()` */
  static readonly UserDeleteUsermanagerApiV1UsersUserIdDeletePath = '/usermanager/api/v1/users/{user_id}';

  /**
   * Delete a user.
   *
   * Delete a user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userDeleteUsermanagerApiV1UsersUserIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDeleteUsermanagerApiV1UsersUserIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
      delete_core?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.UserDeleteUsermanagerApiV1UsersUserIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('user_id', params.user_id, {});
      rb.query('delete_core', params.delete_core, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Delete a user.
   *
   * Delete a user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userDeleteUsermanagerApiV1UsersUserIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userDeleteUsermanagerApiV1UsersUserIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
      delete_core?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.userDeleteUsermanagerApiV1UsersUserIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `extensionToggleUsermanagerApiV1ExtensionsPost()` */
  static readonly ExtensionToggleUsermanagerApiV1ExtensionsPostPath = '/usermanager/api/v1/extensions';

  /**
   * Extension Toggle.
   *
   * Extension Toggle
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `extensionToggleUsermanagerApiV1ExtensionsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  extensionToggleUsermanagerApiV1ExtensionsPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      extension: string;
      userid: string;
      active: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.ExtensionToggleUsermanagerApiV1ExtensionsPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('extension', params.extension, {});
      rb.query('userid', params.userid, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }
  /**
   * Extension Toggle.
   *
   * Extension Toggle
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `extensionToggleUsermanagerApiV1ExtensionsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  extensionToggleUsermanagerApiV1ExtensionsPost(
    chain: string,
    token: string | undefined,
    params: {
      extension: string;
      userid: string;
      active: boolean;
    },
    context?: HttpContext
): Observable<{
[key: string]: string;
}> {
    return this.extensionToggleUsermanagerApiV1ExtensionsPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }
  /** Path part for operation `getAllUserWalletsUsermanagerApiV1WalletsGet()` */
  static readonly GetAllUserWalletsUsermanagerApiV1WalletsGetPath = '/usermanager/api/v1/wallets';

  /**
   * Get all user wallets.
   *
   * Get all user wallets
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUserWalletsUsermanagerApiV1WalletsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUserWalletsUsermanagerApiV1WalletsGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Array<Wallet>>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.GetAllUserWalletsUsermanagerApiV1WalletsGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Wallet>>;
      })
    );
  }
  /**
   * Get all user wallets.
   *
   * Get all user wallets
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUserWalletsUsermanagerApiV1WalletsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUserWalletsUsermanagerApiV1WalletsGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<Array<Wallet>> {
    return this.getAllUserWalletsUsermanagerApiV1WalletsGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }
  /** Path part for operation `createWalletForUserUsermanagerApiV1WalletsPost()` */
  static readonly CreateWalletForUserUsermanagerApiV1WalletsPostPath = '/usermanager/api/v1/wallets';

  /**
   * Create wallet for user.
   *
   * Create wallet for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createWalletForUserUsermanagerApiV1WalletsPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWalletForUserUsermanagerApiV1WalletsPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateUserWallet
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Wallet>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.CreateWalletForUserUsermanagerApiV1WalletsPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Wallet>;
      })
    );
  }
  /**
   * Create wallet for user.
   *
   * Create wallet for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createWalletForUserUsermanagerApiV1WalletsPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createWalletForUserUsermanagerApiV1WalletsPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateUserWallet
    },
    context?: HttpContext
): Observable<Wallet> {
    return this.createWalletForUserUsermanagerApiV1WalletsPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Wallet>): Wallet => r.body)
    );
  }
  /** Path part for operation `getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet()` */
  static readonly GetAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGetPath = '/usermanager/api/v1/transactions/{wallet_id}';

  /**
   * Get all wallet transactions.
   *
   * Get all wallet transactions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Array<Payment>>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.GetAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Payment>>;
      })
    );
  }
  /**
   * Get all wallet transactions.
   *
   * Get all wallet transactions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<Array<Payment>> {
    return this.getAllWalletTransactionsUsermanagerApiV1TransactionsWalletIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Array<Payment>>): Array<Payment> => r.body)
    );
  }
  /** Path part for operation `getUserWalletUsermanagerApiV1WalletsUserIdGet()` */
  static readonly GetUserWalletUsermanagerApiV1WalletsUserIdGetPath = '/usermanager/api/v1/wallets/{user_id}';

  /**
   * Get user wallet.
   *
   * Get user wallet
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserWalletUsermanagerApiV1WalletsUserIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserWalletUsermanagerApiV1WalletsUserIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<Array<Wallet>>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.GetUserWalletUsermanagerApiV1WalletsUserIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('user_id', params.user_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Wallet>>;
      })
    );
  }
  /**
   * Get user wallet.
   *
   * Get user wallet
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserWalletUsermanagerApiV1WalletsUserIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserWalletUsermanagerApiV1WalletsUserIdGet(
    chain: string,
    token: string | undefined,
    params: {
      user_id: any;
    },
    context?: HttpContext
): Observable<Array<Wallet>> {
    return this.getUserWalletUsermanagerApiV1WalletsUserIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<Array<Wallet>>): Array<Wallet> => r.body)
    );
  }
  /** Path part for operation `deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete()` */
  static readonly DeleteWalletByIdUsermanagerApiV1WalletsWalletIdDeletePath = '/usermanager/api/v1/wallets/{wallet_id}';

  /**
   * Delete wallet by id.
   *
   * Delete wallet by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, UsermanagerService.DeleteWalletByIdUsermanagerApiV1WalletsWalletIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Delete wallet by id.
   *
   * Delete wallet by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<string> {
    return this.deleteWalletByIdUsermanagerApiV1WalletsWalletIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
}
