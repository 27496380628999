/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { UpdateInvoiceData } from '../models/update-invoice-data';
import { LnbitsExtensionsInvoicesModelsCreateInvoiceData } from '../models/lnbits-extensions-invoices-models-create-invoice-data';

@Injectable({ providedIn: 'root' })
export class InvoicesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `indexInvoicesGet()` */
  static readonly IndexInvoicesGetPath = '/invoices/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexInvoicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexInvoicesGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.IndexInvoicesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexInvoicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexInvoicesGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexInvoicesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `payInvoicesPayInvoiceIdGet()` */
  static readonly PayInvoicesPayInvoiceIdGetPath = '/invoices/pay/{invoice_id}';

  /**
   * Pay.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `payInvoicesPayInvoiceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  payInvoicesPayInvoiceIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.PayInvoicesPayInvoiceIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Pay.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `payInvoicesPayInvoiceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  payInvoicesPayInvoiceIdGet(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.payInvoicesPayInvoiceIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `apiInvoicesInvoicesApiV1InvoicesGet()` */
  static readonly ApiInvoicesInvoicesApiV1InvoicesGetPath = '/invoices/api/v1/invoices';

  /**
   * Api Invoices.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoicesInvoicesApiV1InvoicesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesInvoicesApiV1InvoicesGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoicesInvoicesApiV1InvoicesGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('all_wallets', params.all_wallets, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoices.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoicesInvoicesApiV1InvoicesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesInvoicesApiV1InvoicesGet(
    chain: string,
    token?: string | undefined,
    params?: {
      all_wallets?: boolean;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoicesInvoicesApiV1InvoicesGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet()` */
  static readonly ApiInvoiceInvoicesApiV1InvoiceInvoiceIdGetPath = '/invoices/api/v1/invoice/{invoice_id}';

  /**
   * Api Invoice.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoiceInvoicesApiV1InvoiceInvoiceIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoice.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoiceInvoicesApiV1InvoiceInvoiceIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost()` */
  static readonly ApiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPostPath = '/invoices/api/v1/invoice/{invoice_id}';

  /**
   * Api Invoice Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      body: UpdateInvoiceData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoice Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      body: UpdateInvoiceData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoiceUpdateInvoicesApiV1InvoiceInvoiceIdPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete()` */
  static readonly ApiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDeletePath = '/invoices/api/v1/invoice/{invoice_id}';

  /**
   * Api Invoice Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoice Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoiceDeleteInvoicesApiV1InvoiceInvoiceIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoiceCreateInvoicesApiV1InvoicePost()` */
  static readonly ApiInvoiceCreateInvoicesApiV1InvoicePostPath = '/invoices/api/v1/invoice';

  /**
   * Api Invoice Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoiceCreateInvoicesApiV1InvoicePost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInvoiceCreateInvoicesApiV1InvoicePost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: LnbitsExtensionsInvoicesModelsCreateInvoiceData
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoiceCreateInvoicesApiV1InvoicePostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoice Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoiceCreateInvoicesApiV1InvoicePost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiInvoiceCreateInvoicesApiV1InvoicePost(
    chain: string,
    token: string | undefined,
    params: {
      body: LnbitsExtensionsInvoicesModelsCreateInvoiceData
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoiceCreateInvoicesApiV1InvoicePost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost()` */
  static readonly ApiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPostPath = '/invoices/api/v1/invoice/{invoice_id}/payments';

  /**
   * Api Invoices Create Payment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      famount: number;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
      rb.query('famount', params.famount, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoices Create Payment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      famount: number;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoicesCreatePaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet()` */
  static readonly ApiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGetPath = '/invoices/api/v1/invoice/{invoice_id}/payments/{payment_hash}';

  /**
   * Api Invoices Check Payment.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      payment_hash: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('invoice_id', params.invoice_id, {});
      rb.path('payment_hash', params.payment_hash, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Invoices Check Payment.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet(
    chain: string,
    token: string | undefined,
    params: {
      invoice_id: string;
      payment_hash: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiInvoicesCheckPaymentInvoicesApiV1InvoiceInvoiceIdPaymentsPaymentHashGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiStopInvoicesApiV1Delete()` */
  static readonly ApiStopInvoicesApiV1DeletePath = '/invoices/api/v1';

  /**
   * Api Stop.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStopInvoicesApiV1Delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopInvoicesApiV1Delete$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, InvoicesService.ApiStopInvoicesApiV1DeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Stop.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStopInvoicesApiV1Delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiStopInvoicesApiV1Delete(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiStopInvoicesApiV1Delete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
