import { Component, Input, OnInit } from '@angular/core';
import { Bitpass, GenericBitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { RecordTime } from 'src/app/services/api/models';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

export interface IFormData { bitPasses: any }

@Component({
  selector: 'app-bp-history',
  templateUrl: './bp-history.component.html',
  styleUrls: ['./bp-history.component.scss'],
})
export class BpHistoryComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'bpHistory';

  bitPass: Bitpass<any>;
  lastTenVersions: RecordTime;
  loadingResults: boolean = true;

  bitPasses = [
    { title: "Último minuto", list: [], },
    { title: "Última hora", list: [] },
    { title: "Último día", list: [] },
    { title: "Última semana", list: [] },
    { title: "Último mes", list: [] },
    { title: "Último año", list: [] },
    { title: "Anteriores", list: [] }
  ];

  @Input()
  set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, autoSubmit?: boolean, [other: string]: any }) {
    if (value.formData) {
      this.bitPasses = value.formData.bitPasses;
      this.loadingResults = false;


    } else if (value.bitpass) {
      this.loadingResults = true;
      this.bitPass = value.bitpass;

      this.core.api.btml.findLastTenByKey({
        btml: '' + this.bitPass.__selfBtml,
        address: 'address_server',
      }).subscribe((res) => {
        this.lastTenVersions = res;
        this.lastTenVersions.records.forEach((element) => {
          // core.handleLink('app://bp/' + ownerBtml);
          console.log(element)
          this.core.bps.fetchBitpassFromCid(element.value, GenericBitpass).then((res) => {
            // bitpassDict.element

            let time = (new Date().getTime() - element.timestamp * 1000) / 1000;
            let bpVersion = element.key.split('v')[0];
            let currentDate = new Date(element.timestamp * 1000)
            let date = currentDate.getHours() + ':' + currentDate.getMinutes() + ' ' + currentDate.getDate() + '/' + (currentDate.getMonth() + 1) + '/' + currentDate.getFullYear();
            let info = { version: bpVersion, date: date, product: res.product, cid: element.value }

            if (time < 60) {
              this.bitPasses[0].list.push(info);
            } else if (time < 3600 && time > 60) {
              this.bitPasses[1].list.push(info);
            } else if (time < 86400 && time > 3600) {
              this.bitPasses[2].list.push(info);
            } else if (time < 604800 && time > 86400) {
              this.bitPasses[3].list.push(info);
            } else if (time < 2.628 * Math.pow(10, 6) && time > 604800) {
              this.bitPasses[4].list.push(info);
            } else if (time < 3.154 * Math.pow(10, 7) * 1000 && time > 2.628 * Math.pow(10, 6)) {
              this.bitPasses[5].list.push(info);
            } else {
              this.bitPasses[6].list.push(info);
            }
            if (this.loadingResults) this.loadingResults = false;
            // Order lists
            this.bitPasses.forEach(element => {
              if (element.list.length) {
                element.list.sort((x, y) => y.version - x.version)
              }
            })
          });
        });
      });
    }
    console.log(this.bitPasses)
  }

  constructor(public core: CoreProvider) {
    super(core);
  }

  ngOnInit() {

  }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      bitPasses: []
    };
    dataStore.bitPasses = this.bitPasses;
    this.formDataStore = dataStore;
  }
}
