/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { LittelUsersCheckKey } from '../models/littel-users-check-key';
import { PgpKeyPairsResponse } from '../models/pgp-key-pairs-response';
import { PgpSignRequest } from '../models/pgp-sign-request';
import { PgpSignResponse } from '../models/pgp-sign-response';
import { PgpVerifyRequest } from '../models/pgp-verify-request';


/**
 * `Verify` sovereign of properties with <b>Decentralized Identities (DID)</b>
 */
@Injectable({ providedIn: 'root' })
export class Layer5Service extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `pgpControllerMakeKeyPairs()` */
  static readonly PgpControllerMakeKeyPairsPath = '/PGP/create/keyPairs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `makeKeyPairs()` instead.
   *
   * This method doesn't expect any request body.
   */
  makeKeyPairs$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PgpKeyPairsResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer5Service.PgpControllerMakeKeyPairsPath, 'post');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PgpKeyPairsResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `makeKeyPairs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  makeKeyPairs(
    params?: {
    },
    context?: HttpContext
  ): Observable<PgpKeyPairsResponse> {
    return this.makeKeyPairs$Response(params, context).pipe(
      map((r: StrictHttpResponse<PgpKeyPairsResponse>): PgpKeyPairsResponse => r.body)
    );
  }

  /** Path part for operation `pgpControllerSignMessage()` */
  static readonly PgpControllerSignMessagePath = '/PGP/sign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signMessage$Response(
    params?: {
      body?: PgpSignRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PgpSignResponse>> {
    const rb = new RequestBuilder(this.rootUrl, Layer5Service.PgpControllerSignMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PgpSignResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signMessage(
    params?: {
      body?: PgpSignRequest
    },
    context?: HttpContext
  ): Observable<PgpSignResponse> {
    return this.signMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PgpSignResponse>): PgpSignResponse => r.body)
    );
  }

  /** Path part for operation `pgpControllerCheckUserPgp()` */
  static readonly PgpControllerCheckUserPgpPath = '/PGP/user/check/key';

  /**
   * Check if use has PGP keypairs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserPGP()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserPGP$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer5Service.PgpControllerCheckUserPgpPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * Check if use has PGP keypairs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUserPGP$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkUserPGP(
    params?: {
    },
    context?: HttpContext
  ): Observable<any> {
    return this.checkUserPGP$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `pgpControllerVerifyMessage()` */
  static readonly PgpControllerVerifyMessagePath = '/PGP/verify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyMessage$Response(
    params?: {
      body?: PgpVerifyRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, Layer5Service.PgpControllerVerifyMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  verifyMessage(
    params?: {
      body?: PgpVerifyRequest
    },
    context?: HttpContext
  ): Observable<any> {
    return this.verifyMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `layer5CheckUserKeys()` */
  static readonly Layer5CheckUserKeysPath = '/littleUser/{chain}/check/key';

  /**
   * (littleUser) Check if user has concrete chain seed.
   *
   * Give the passphrase (PGP privKey) to receive the list of seeds created for the user and storage cyphered in the server.
   *
   * * Remember that this solution is limited to 1 seed per chain and layer
   *
   * <b>This is for littleUser mode only</b>
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkUserKeys()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkUserKeys$Response(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LittelUsersCheckKey
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<LittelUsersCheckKey>> {
    const rb = new RequestBuilder(this.rootUrl, Layer5Service.Layer5CheckUserKeysPath, 'post');
    if (params) {
      rb.path('chain', params.chain, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LittelUsersCheckKey>;
      })
    );
  }

  /**
   * (littleUser) Check if user has concrete chain seed.
   *
   * Give the passphrase (PGP privKey) to receive the list of seeds created for the user and storage cyphered in the server.
   *
   * * Remember that this solution is limited to 1 seed per chain and layer
   *
   * <b>This is for littleUser mode only</b>
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `checkUserKeys$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  checkUserKeys(
    params: {

    /**
     * Add a valid BTC chain
     */
      chain: string;
      body?: LittelUsersCheckKey
    },
    context?: HttpContext
  ): Observable<LittelUsersCheckKey> {
    return this.checkUserKeys$Response(params, context).pipe(
      map((r: StrictHttpResponse<LittelUsersCheckKey>): LittelUsersCheckKey => r.body)
    );
  }

}
