import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../../pages/product/base-modal';
import { MonacoeditorModalComponent } from '../../pages/product/monacoeditor-modal/monacoeditor-modal.component';

export interface IFormData { bitpass: Bitpass<any>, record: any }

@Component({
  selector: 'app-bp-editor-modal',
  templateUrl: './bp-editor-modal.component.html',
  styleUrls: ['./bp-editor-modal.component.scss'],
})
export class BpEditorModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string;

  bitpass: Bitpass<any>;
  record: any;
  url: string;

  @Input()
  set data(value: { bitpass?: Bitpass<any>, formData?: IFormData, [other: string]: any }) {
    if (value.formData) {
      this.bitpass = value.formData.bitpass;
      this.record = value.formData.record;
    } else if (value.bitpass) {
      this.bitpass = value.bitpass;
    }
  }

  constructor(public core: CoreProvider, private modalCtrl: ModalController,) {
    super(core);
  }

  ngOnInit() { }

  protected onCommit(data: IFormData): Promise<any> {
    return new Promise((ok, ko) => {
      try {
        this.core.createLoading().then(loading => {
          const bpType = this.record.key.split('v')[1].substr(0, 5);
          this.core.bps.saveProduct(this.bitpass!.__data, bpType, this.record.key, loading).then((newRevision: string) => {
            this.core.successToast(loading, '¡Producto guardado!');

            console.log(`Producto guardado con revision ${newRevision}! :D`);
            ok('ok');
          }).catch((err) => {
            ko(err);
            this.core.errorToast(loading, 'Error al guardar el producto. ' + err);
            console.error('Error al guardar el producto', err);
          });
        });
      } catch (error) {
        ko(error);
      }
    })
  }

  async presentAlertMultipleButtons() {
    const alert = await this.core.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: '¿Desea descartar la edición?',
      buttons: [
        {
          text: 'Descartar cambios',
          cssClass: 'other-button',
          id: 'discard-button',
          handler: () => {
            this.dismissModal();
          }
        },
        {
          text: 'Seguir editando',
          role: 'discard',
          cssClass: 'cta-button',
          id: 'continue-button',
          handler: () => {
          }
        },
        {
          text: 'Guardar cambios y cerrar',
          cssClass: 'danger',
          id: 'save-button',
          handler: () => {
            this.dismissSaveModal();
          }
        },
      ]
    });

    await alert.present();
  }

  dismissModal() {
    return this.modalCtrl.dismiss(null, 'cancel');
  }
  
  dismissSaveModal() {
    this.readFormToStore();
    this.onCommit(this.formDataStore);
    this.core.modalCtrl.dismiss(this.bitpass);
  }

  async openMonacoEditor() {
    return this.core.openModal(MonacoeditorModalComponent, { obj: this.bitpass }).then(data => {
      if (data.data) {
        this.bitpass = data.data;
      }
    });
  }
  
  readFormToStore() {
    const dataStore = this.formDataStore || {
      bitpass: this.bitpass,
      record: this.record
    };
    dataStore.bitpass = this.bitpass;
    dataStore.record = this.record;
    this.formDataStore = dataStore;
  }
}
