import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreProvider } from 'src/app/services/core';
import { Bitpass } from 'src/app/lib/bitpass-types/models/bitpass';
import { Plugins } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { ReceivePaymentModalComponent } from '../receive-payment-modal/receive-payment-modal.component';
import BaseModal from '../base-modal';
import { UserStorageModels } from 'src/app/services/user-storage-models';
import { CancelSaleModalComponent } from '../cancel-sale-modal/cancel-sale-modal.component';
import { IonInput } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
const { Share } = Plugins;

export interface IFormData {
  addressSeller: string;
  chain: string;
  price: number;
  btml: string;
  signature: string;

  moneyOptions: { tokenType: string; payType: string };
  wallet: any;
}

@Component({
  selector: 'app-sale-modal',
  templateUrl: './sale-modal.component.html',
  styleUrls: ['./sale-modal.component.scss'],
})
export class SaleModalComponent extends BaseModal<IFormData> implements OnInit {
  changingBP: boolean = false; // Boolean which will allow us to re-order HTML elements, it will be on if we're changing Bitpass
  urlBPid: string; // String which contains parsed URL (app://<btml>) in order to call macro-pass component
  btmlType: string; // BTML Type (First 5 characters of BTML)
  btmlNewId: string; // The New BTML which user will be moved to whenever he clicks "Change the BP"
  precioVenta: number;
  // Variables to access to product property
  ownerBTML: string; // BTML Owner
  registerBook: Bitpass<any>; // Register Book (We will use it to know who is the product owner).
  transferRegister: Bitpass<any>;

  // Observable variables to control the Form Inputs
  @ViewChild('btmlForm') btmlForm: IonInput; // BTML.
  @ViewChild('btmlTypeForm') btmlTypeForm: IonInput; // BTML Type.
  @ViewChild('cidForm') cidForm: IonInput; // CID.

  // Variables to access to the user identity
  bpIDPubKey: string;
  identity: UserStorageModels.Identities;
  iden = new BehaviorSubject(this.core.auth.userStorage?.identities);

  sellFormGroup: FormGroup; // Form variable.

  protected readonly formIdentifier: string = 'sale';
  submitted = false;
  page = 1;
  successSale = 0;
  seconds = 10;
  bitCuantity: number;
  invalidCuantity = false;
  euroCuantity: number;
  qrData = 'esto es una factura';

  saleBitpass: Bitpass<any>;
  bitpass: Bitpass<any>;
  decimals: number;
  wallet: any;
  moneyOptions: { tokenType: string; payType: string };
  chain: string;
  btml: string;
  signedCid: string;
  deferred = false;

  // Example of how to override
  @Input()
  set data(value: {
    bitpass?: Bitpass<any>;
    formData?: IFormData;
    autoSubmit?: boolean;
    [other: string]: any;
  }) {
    console.log(value);

    if (value.formData) {
      this.dataInfo = {
        payType: value.formData.moneyOptions.payType,
        tokenType: value.formData.moneyOptions.tokenType,
        wallet: value.formData.wallet,
      };
      this.chain = value.formData.chain;
      this.moneyOptions = value.formData.moneyOptions;
      this.bitCuantity = value.formData.price;
      this.btml = value.formData.btml;
      this.fetchBitpass(this.btml).subscribe({
        next: (res) => {
          if (res) {
            this.saleBitpass = res;
            this.signedCid = res.__selfCid;
          }
        },
      });
    } else if (value.bitpass) {
      this.bitpass = value.bitpass;
      this.btml = value.bitpass.__selfBtml;
      this.signedCid = value.bitpass.__selfCid;
    }

    if (value.autoSubmit) {
      this.onSubmit();
    }
  }

  dataInfo: {
    tokenType: string;
    payType: string;
    wallet:
      | UserStorageModels.BlockchainWallets
      | UserStorageModels.LightningWallets
      | UserStorageModels.VaultWallets;
  };

  constructor(core: CoreProvider) {
    super(core);
  }

  ngOnInit() {
    // We assign a default value for BTML Type.
    console.log(this.bitpass?.__selfBtml);
    this.btmlType = this.bitpass?.__selfBtml.substring(0, 5);
    // Instance the form
    this.sellFormGroup = new FormGroup({
      btml: new FormControl(this.bitpass?.__selfBtml, [Validators.required]),
      cid: new FormControl(this.bitpass?.__selfCid, [Validators.required]),
      btmlType: new FormControl(this.btmlType, [Validators.required]),
    });
    // Initial values.
    this.urlBPid = 'app://bp/' + this.bitpass?.__selfBtml;
    this.btmlNewId = this.bitpass?.__selfBtml;
    this.updateOwnerBTML();
    this.precioVenta = this.bitpass?.__data[0].val.soldInfo?.price;

    // It will get the value of the identity of the user (identity variable).
    this.iden.subscribe({
      next: (res) => {
        this.identity = res;
        this.fetchBitpass(res.publicID.bp_id?.slice(0, 10)).subscribe({
          next: (res) => {
            if (res) {
              this.bpIDPubKey = res.__data[0].val.specific.specific.publicKey;
            }
          },
          error: (err) => {},
        });
      },
    });
  }

  protected async onCommit(data: IFormData): Promise<any> {
    return new Promise((ok, ko) => {
      try {
        this.core.api.bitcoin
          .segwitAddress({
            chain: this.chain,
            type: 'segwit',
            body: {
              ypub: this.wallet.pubkey,
            },
          })
          .subscribe((res) => {
            if (res) {
              this.core.api.verify
                .signMessage({
                  body: {
                    data:
                      this.btml +
                      this.signedCid +
                      data.chain +
                      res.address +
                      this.bitCuantity,
                    privateKey:
                      this.core.auth.userStorage.identities.privateID
                        .privkeyPGP,
                  },
                })
                .subscribe({
                  next: (res2) => {
                    if (res2) {
                      this.core.api.agram
                        .soldBP({
                          body: {
                            addressSeller: res.address,
                            btml: this.btml,
                            chain: this.chain,
                            price: this.bitCuantity,
                            signature: res2.signedMessage,
                            signedCid: this.signedCid,
                          },
                          worker: 'general_worker_server',
                        })
                        .subscribe({
                          next: (res) => {
                            if (res) {
                              this.successSale = 1;
                              ok(res);
                            } else {
                              this.successSale = 2;
                              ko(res);
                            }
                          },
                          error: (error) => {
                            this.successSale = 2;
                            ko(error);
                          },
                        });
                    }
                  },
                  error: (error) => {
                    this.successSale = 2;
                    ko(error);
                  },
                });
            }
          });
      } catch (error) {
        this.successSale = 2;
        ko(error);
      }
    });
  }

  //Variables used to manage BTML Type variable.
  getBTMLType() {
    return Number(this.btmlType);
  }
  setBTMLType(value: string) {
    this.btmlType = value;
  }

  // Will check if user has edited BTML Field in order to show Update button.
  checkEditedBTML() {
    return this.btmlForm?.value == this.btmlNewId;
  }

  // Will check if the product is for sale or not.
  checkProductIsSelling() {
    return !(this.precioVenta > 0);
  }

  // Will check if the current user is the owner of the product.
  checkProductOwner() {
    this.checkEditedBTML();
    return (
      this.ownerBTML == this.identity?.publicID?.bp_id.slice(0, 10) &&
      this.ownerBTML != undefined
    );
  }

  // If the product is for sale and the current user is the product owner then formValid will be true so sellFormGroup will be able to be submitted.
  formValid() {
    return this.checkProductIsSelling() && this.checkProductOwner();
  }

  async openBitpassSelection() {
    this.changingBP = !this.changingBP;
    await new Promise((f) => setTimeout(f, 100));
    this.btmlTypeForm.value = this.btmlType;
  }

  // Variable called whenever we update the BTML. It will obtain information about the new product owner.
  updateOwnerBTML() {
    this.fetchBitpass('5213000000').subscribe((res) => {
      this.registerBook = res;
      if (this.registerBook) {
        var i = 0;
        // Iterate over bpKeyIndex
        Object.keys(
          this.registerBook.__data[0].val.specific.specific.specific.specific
            .bpKeyIndex
        ).forEach((element) => {
          // If the current iteration element is equals to the current product btml look for this element/product
          if (element == '' + this.btmlNewId) {
            this.fetchBitpass(
              Object.values(
                this.registerBook.__data[0].val.specific.specific.specific
                  .specific.bpKeyIndex
              )[i] + ''
            ).subscribe((result) => {
              this.transferRegister = result;
              if (this.transferRegister) {
                this.ownerBTML =
                  this.transferRegister.__data[0].val.specific.specific.specific
                    .specific.allowedIdentity + '';
              }
            });
          }
          i++;
        });
      }
    });
  }

  // Method used by the app-choose-bp (choose Bitpass).
  chooseBP(e) {
    this.changingBP = true;
    this.btmlNewId = e;
  }

  // Method which will be called everytime we move to another Bitpass.
  async updateBTML(BTMLid = this.btmlNewId) {
    this.ownerBTML = undefined;
    this.btmlNewId = BTMLid;
    this.changingBP = false;
    this.btmlType = this.btmlNewId.substring(0, 5);
    console.log(this.btmlType);

    //We must do this wait in order to let the html refresh so inputs will not be seen as "null" in code.
    await new Promise((f) => setTimeout(f, 500));

    this.urlBPid = 'app://bp/' + BTMLid;

    this.btmlForm.value = BTMLid;
    this.updateOwnerBTML();
    this.fetchBitpass(this.urlBPid).subscribe({
      next: (res) => {
        if (res) {
          this.precioVenta = res.__data[0].val.soldInfo?.price;
          this.cidForm.value = res.data_cid.toString();
        }
      },
      error: (err) => {},
    });
  }

  getBitpass(bp: Bitpass<any>) {
    this.bitpass = bp;
  }

  getDecimals(dec: number) {
    this.decimals = dec;
  }

  getWallet(wallet) {
    this.wallet = wallet;
    this.chain = this.wallet.chain;
  }

  getMoneyOptions(options) {
    this.moneyOptions = options;
  }

  bpCache: { [k: string]: BehaviorSubject<Bitpass<any>> } = {};
  fetchBitpass(btml: string) {
    if (btml.startsWith('app://bp/')) {
      btml = btml.slice(9);
    }

    if (!this.bpCache[btml]) {
      this.bpCache[btml] = new BehaviorSubject(null);
      this.core.bps
        .fetchBitpass(btml, Bitpass)
        .then((bp) => {
          console.warn('URL ' + btml + ' loaded', bp);
          this.bpCache[btml].next(bp.product);
        })
        .catch((err) => {
          console.error('Cannot fetch minipass for url ' + btml);
        });
    }
    return this.bpCache[btml];
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      addressSeller: '',
      chain: '',
      price: 0,
      btml: '',
      signature: '',
      moneyOptions: { tokenType: '', payType: '' },
      wallet: {},
    };
    dataStore.btml = this.btml;
    dataStore.chain = this.chain;
    dataStore.price = this.bitCuantity;
    dataStore.signature = '';
    dataStore.moneyOptions = this.moneyOptions;
    dataStore.wallet = this.wallet;
    this.formDataStore = dataStore;
  }

  onSubmit() {
    this.submitted = true;
    this.readFormToStore();
    this.onCommit(this.formDataStore);
  }

  deferModal() {
    this.deferred = true;
    this.readFormToStore();
    super.deferModal();
  }

  receive() {
    this.core.modalCtrl.dismiss();
    this.core.modalCtrl
      .create({
        component: ReceivePaymentModalComponent,
        cssClass: 'fullscreenModal',
        componentProps: {
          data: {
            bitCuantity: this.bitCuantity,
            moneyOptions: this.moneyOptions,
            wallet: this.wallet,
          },
        },
        backdropDismiss: false,
      })
      .then((modal) => {
        modal.onDidDismiss().then((data: any) => {
          console.log('dismissed', data.data);
        });
        modal.present();
      });
  }

  cancelSale() {
    this.fetchBitpass(this.btml).subscribe({
      next: (res) => {
        if (res) {
          this.core.modalCtrl.dismiss();
          this.core.modalCtrl
            .create({
              component: CancelSaleModalComponent,
              cssClass: 'fullscreenModal',
              componentProps: { data: { bitpass: res } },
              backdropDismiss: false,
            })
            .then((modal) => {
              modal.onDidDismiss().then((data: any) => {
                console.log('dismissed', data.data);
              });
              modal.present();
            });
        }
      },
    });
  }

  changeCuantity(cuantity) {
    this.bitCuantity = cuantity;
  }

  countDown() {
    if (this.seconds > 0) {
      this.seconds -= 1;
      setTimeout('this.countDown', 1000);
    }
  }

  share() {
    Share.share({
      title: '¡Este producto está a la venta!',
      text: '' + window.location,
    });
  }

  bitToEuro() {
    this.euroCuantity = this.bitCuantity * 10;
  }
  euroToBit() {
    this.bitCuantity = this.euroCuantity / 10;
  }

  public async successToast() {
    await (
      await this.core.toastCtrl.create({
        message: 'Texto copiado en el portapapeles',
        duration: 2000,
        color: 'success',
      })
    ).present();
  }
}
