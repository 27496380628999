import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CoreProvider } from 'src/app/services/core';
import BaseModal from '../base-modal';

declare const particlesJS: any;

export interface IFormData { fullscreenData: {} }

@Component({
  selector: 'app-fullscreen-modal',
  templateUrl: './fullscreen-modal.component.html',
  styleUrls: ['./fullscreen-modal.component.scss'],
})
export class FullscreenModalComponent extends BaseModal<IFormData> implements OnInit {
  protected formIdentifier: string = 'fullscreen';

  public fullscreen: boolean = false;

  @ViewChild('content')
  set content(element) {

    var docElm = element.el;
    if (docElm.requestFullscreen) {
      docElm.requestFullscreen();
    }
    else if (docElm.mozRequestFullScreen) {
      docElm.mozRequestFullScreen();
    }
    else if (docElm.webkitRequestFullScreen) {
      docElm.webkitRequestFullScreen();
    }
    else if (docElm.msRequestFullscreen) {
      docElm.msRequestFullscreen();
    }

    docElm.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
      } else {
        console.log('Leaving full-screen mode.');
        this.dismissModal();
      }
    });

    if (this.cnf.particles) particlesJS.load('content', '/assets/particles.json', function () {
      console.log('callback - particles.js config loaded');
    });
  }

  @Input() set data(value: any) {
    if (value.formData) {
      this.fullscreenData = value.formData.fullscreenData;
    } else {
      this.fullscreenData = value;
    }
  }
  
  @Input()
  cnf = {
    particles: false,
    interval: 20 * 60 * 60
  };

  fullscreenData: any = {};

  constructor(public core: CoreProvider) {
    super(core);
  }

  protected onCommit(data: IFormData): Promise<any> {
    throw new Error('Method not implemented.');
  }

  ngOnInit() { }

  dismissModal() {
    this.core.modalCtrl.dismiss();
    this.fullscreen = false
  }

  deferModal() {
    this.readFormToStore();
    super.deferModal();
  }

  readFormToStore() {
    const dataStore = this.formDataStore || {
      fullscreenData: {}
    };
    dataStore.fullscreenData = this.fullscreenData;
    this.formDataStore = dataStore;
  }
}
