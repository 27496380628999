/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { Config } from '../models/config';
import { CreatePsbt } from '../models/create-psbt';
import { CreateWallet } from '../models/create-wallet';
import { ExtractPsbt } from '../models/extract-psbt';
import { ExtractTx } from '../models/extract-tx';
import { SerializedTransaction } from '../models/serialized-transaction';

@Injectable({ providedIn: 'root' })
export class WatchonlyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `indexWatchonlyGet()` */
  static readonly IndexWatchonlyGetPath = '/watchonly/';

  /**
   * Index.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `indexWatchonlyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexWatchonlyGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.IndexWatchonlyGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('usr', params.usr, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/html', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Index.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `indexWatchonlyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  indexWatchonlyGet(
    chain: string,
    token: string | undefined,
    params: {
      usr: string;
    },
    context?: HttpContext
): Observable<string> {
    return this.indexWatchonlyGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }
  /** Path part for operation `apiWalletsRetrieveWatchonlyApiV1WalletGet()` */
  static readonly ApiWalletsRetrieveWatchonlyApiV1WalletGetPath = '/watchonly/api/v1/wallet';

  /**
   * Api Wallets Retrieve.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWalletsRetrieveWatchonlyApiV1WalletGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletsRetrieveWatchonlyApiV1WalletGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
      network?: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiWalletsRetrieveWatchonlyApiV1WalletGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.query('network', params.network, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Wallets Retrieve.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWalletsRetrieveWatchonlyApiV1WalletGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletsRetrieveWatchonlyApiV1WalletGet(
    chain: string,
    token?: string | undefined,
    params?: {
      network?: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiWalletsRetrieveWatchonlyApiV1WalletGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiWalletCreateOrUpdateWatchonlyApiV1WalletPost()` */
  static readonly ApiWalletCreateOrUpdateWatchonlyApiV1WalletPostPath = '/watchonly/api/v1/wallet';

  /**
   * Api Wallet Create Or Update.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWalletCreateOrUpdateWatchonlyApiV1WalletPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWalletCreateOrUpdateWatchonlyApiV1WalletPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateWallet
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiWalletCreateOrUpdateWatchonlyApiV1WalletPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Wallet Create Or Update.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWalletCreateOrUpdateWatchonlyApiV1WalletPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiWalletCreateOrUpdateWatchonlyApiV1WalletPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreateWallet
    },
    context?: HttpContext
): Observable<any> {
    return this.apiWalletCreateOrUpdateWatchonlyApiV1WalletPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet()` */
  static readonly ApiWalletRetrieveWatchonlyApiV1WalletWalletIdGetPath = '/watchonly/api/v1/wallet/{wallet_id}';

  /**
   * Api Wallet Retrieve.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiWalletRetrieveWatchonlyApiV1WalletWalletIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Wallet Retrieve.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiWalletRetrieveWatchonlyApiV1WalletWalletIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete()` */
  static readonly ApiWalletDeleteWatchonlyApiV1WalletWalletIdDeletePath = '/watchonly/api/v1/wallet/{wallet_id}';

  /**
   * Api Wallet Delete.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiWalletDeleteWatchonlyApiV1WalletWalletIdDeletePath, 'delete');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Wallet Delete.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiWalletDeleteWatchonlyApiV1WalletWalletIdDelete$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiFreshAddressWatchonlyApiV1AddressWalletIdGet()` */
  static readonly ApiFreshAddressWatchonlyApiV1AddressWalletIdGetPath = '/watchonly/api/v1/address/{wallet_id}';

  /**
   * Api Fresh Address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiFreshAddressWatchonlyApiV1AddressWalletIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFreshAddressWatchonlyApiV1AddressWalletIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiFreshAddressWatchonlyApiV1AddressWalletIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Fresh Address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiFreshAddressWatchonlyApiV1AddressWalletIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiFreshAddressWatchonlyApiV1AddressWalletIdGet(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiFreshAddressWatchonlyApiV1AddressWalletIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiUpdateAddressWatchonlyApiV1AddressIdPut()` */
  static readonly ApiUpdateAddressWatchonlyApiV1AddressIdPutPath = '/watchonly/api/v1/address/{id}';

  /**
   * Api Update Address.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpdateAddressWatchonlyApiV1AddressIdPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpdateAddressWatchonlyApiV1AddressIdPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      id: string;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiUpdateAddressWatchonlyApiV1AddressIdPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Update Address.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUpdateAddressWatchonlyApiV1AddressIdPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUpdateAddressWatchonlyApiV1AddressIdPut(
    chain: string,
    token: string | undefined,
    params: {
      id: string;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiUpdateAddressWatchonlyApiV1AddressIdPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiGetAddressesWatchonlyApiV1AddressesWalletIdGet()` */
  static readonly ApiGetAddressesWatchonlyApiV1AddressesWalletIdGetPath = '/watchonly/api/v1/addresses/{wallet_id}';

  /**
   * Api Get Addresses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGetAddressesWatchonlyApiV1AddressesWalletIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetAddressesWatchonlyApiV1AddressesWalletIdGet$Response(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiGetAddressesWatchonlyApiV1AddressesWalletIdGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.path('wallet_id', params.wallet_id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Get Addresses.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGetAddressesWatchonlyApiV1AddressesWalletIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetAddressesWatchonlyApiV1AddressesWalletIdGet(
    chain: string,
    token: string | undefined,
    params: {
      wallet_id: any;
    },
    context?: HttpContext
): Observable<any> {
    return this.apiGetAddressesWatchonlyApiV1AddressesWalletIdGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPsbtCreateWatchonlyApiV1PsbtPost()` */
  static readonly ApiPsbtCreateWatchonlyApiV1PsbtPostPath = '/watchonly/api/v1/psbt';

  /**
   * Api Psbt Create.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPsbtCreateWatchonlyApiV1PsbtPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPsbtCreateWatchonlyApiV1PsbtPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: CreatePsbt
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiPsbtCreateWatchonlyApiV1PsbtPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Psbt Create.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPsbtCreateWatchonlyApiV1PsbtPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPsbtCreateWatchonlyApiV1PsbtPost(
    chain: string,
    token: string | undefined,
    params: {
      body: CreatePsbt
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPsbtCreateWatchonlyApiV1PsbtPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut()` */
  static readonly ApiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPutPath = '/watchonly/api/v1/psbt/utxos';

  /**
   * Api Psbt Utxos Tx.
   *
   * Extract previous unspent transaction outputs (tx_id, vout) from PSBT
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Psbt Utxos Tx.
   *
   * Extract previous unspent transaction outputs (tx_id, vout) from PSBT
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPsbtUtxosTxWatchonlyApiV1PsbtUtxosPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut()` */
  static readonly ApiPsbtExtractTxWatchonlyApiV1PsbtExtractPutPath = '/watchonly/api/v1/psbt/extract';

  /**
   * Api Psbt Extract Tx.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: ExtractPsbt
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiPsbtExtractTxWatchonlyApiV1PsbtExtractPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Psbt Extract Tx.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut(
    chain: string,
    token: string | undefined,
    params: {
      body: ExtractPsbt
    },
    context?: HttpContext
): Observable<any> {
    return this.apiPsbtExtractTxWatchonlyApiV1PsbtExtractPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiExtractTxWatchonlyApiV1TxExtractPut()` */
  static readonly ApiExtractTxWatchonlyApiV1TxExtractPutPath = '/watchonly/api/v1/tx/extract';

  /**
   * Api Extract Tx.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiExtractTxWatchonlyApiV1TxExtractPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiExtractTxWatchonlyApiV1TxExtractPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: ExtractTx
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiExtractTxWatchonlyApiV1TxExtractPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Extract Tx.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiExtractTxWatchonlyApiV1TxExtractPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiExtractTxWatchonlyApiV1TxExtractPut(
    chain: string,
    token: string | undefined,
    params: {
      body: ExtractTx
    },
    context?: HttpContext
): Observable<any> {
    return this.apiExtractTxWatchonlyApiV1TxExtractPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiTxBroadcastWatchonlyApiV1TxPost()` */
  static readonly ApiTxBroadcastWatchonlyApiV1TxPostPath = '/watchonly/api/v1/tx';

  /**
   * Api Tx Broadcast.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTxBroadcastWatchonlyApiV1TxPost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTxBroadcastWatchonlyApiV1TxPost$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: SerializedTransaction
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiTxBroadcastWatchonlyApiV1TxPostPath, 'post');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Tx Broadcast.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTxBroadcastWatchonlyApiV1TxPost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiTxBroadcastWatchonlyApiV1TxPost(
    chain: string,
    token: string | undefined,
    params: {
      body: SerializedTransaction
    },
    context?: HttpContext
): Observable<any> {
    return this.apiTxBroadcastWatchonlyApiV1TxPost$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiGetConfigWatchonlyApiV1ConfigGet()` */
  static readonly ApiGetConfigWatchonlyApiV1ConfigGetPath = '/watchonly/api/v1/config';

  /**
   * Api Get Config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiGetConfigWatchonlyApiV1ConfigGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetConfigWatchonlyApiV1ConfigGet$Response(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiGetConfigWatchonlyApiV1ConfigGetPath, 'get');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Get Config.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiGetConfigWatchonlyApiV1ConfigGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiGetConfigWatchonlyApiV1ConfigGet(
    chain: string,
    token?: string | undefined,
    params?: {
    },
    context?: HttpContext
): Observable<any> {
    return this.apiGetConfigWatchonlyApiV1ConfigGet$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
  /** Path part for operation `apiUpdateConfigWatchonlyApiV1ConfigPut()` */
  static readonly ApiUpdateConfigWatchonlyApiV1ConfigPutPath = '/watchonly/api/v1/config';

  /**
   * Api Update Config.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUpdateConfigWatchonlyApiV1ConfigPut()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUpdateConfigWatchonlyApiV1ConfigPut$Response(
    chain: string,
    token: string | undefined,
    params: {
      body: Config
    },
    context?: HttpContext
): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, WatchonlyService.ApiUpdateConfigWatchonlyApiV1ConfigPutPath, 'put');
    rb.header('chain', chain);
    rb.header('token', token);
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }
  /**
   * Api Update Config.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUpdateConfigWatchonlyApiV1ConfigPut$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  apiUpdateConfigWatchonlyApiV1ConfigPut(
    chain: string,
    token: string | undefined,
    params: {
      body: Config
    },
    context?: HttpContext
): Observable<any> {
    return this.apiUpdateConfigWatchonlyApiV1ConfigPut$Response(chain, token, params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }
}
